import axios from 'axios'

export default({
  namespaced: true,

  state: {
    channels: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    },
  },

  getters: {
    CHANNELS (state) {
      return state.channels;
    },
  },

  mutations: {
    SET_CHANNELS (state, data) {
      state.channels = data;
    },

    CLEAR_CHANNELS (state) {
        state.channels = {
            data: [],
            meta: {
                current_page: null,
                from: null,
                last_page: null,
                path: null,
                per_page: null,
                to: null,
                total: null
            }
        }
    },
  },

  actions: {
    GET_CHANNELS: async (context, params) => {
      var locality_id = params.locality_id;
      delete params.locality_id;
      var params_str;
      params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      let {data} = await axios.get('/localities/' + locality_id + '/channels?' + params_str);
      context.commit('SET_CHANNELS', data);
    },

    CLEAR_CHANNELS (context) {
      context.commit('CLEAR_CHANNELS');
    },

    STORE_CHANNEL: async (context, params) => {
      var locality_id = params.locality_id;
      delete params.locality_id;
      await axios.post('/localities/' + locality_id + '/channels', params);
    },

    UPDATE_CHANNEL: async (context, params) => {
      var locality_id = params.locality_id;
      delete params.locality_id;
      await axios.put('/localities/' + locality_id + '/channels/' + params.id, params);
    },

    DELETE_CHANNEL: async (context, params) => {
        await axios.delete('/localities/' + params.locality_id + '/channels/' + params.id);
    }
  }
});
