<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="providers.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="providers.meta.from">
      <TablePagination :items="providers" action="sites_providers/GET_PROVIDERS" :add_params="{site_id: site_id}" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Sites/relations/Providers/components/Top'
  import Actions from '@/components/Sites/relations/Providers/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Провайдер', value: 'name', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
        check: 'mdi-check'
      }
    },
    async mounted () {
      this.clearProviders();
      this.disabledAndLoading();
      await this.getProviders({site_id: this.site_id});
      this.nonDisabledAndLoading();
    },
    destroyed () {
      this.clearProviders();
    },
    computed: {
      ...mapGetters({
        providers: 'sites_providers/PROVIDERS',
        table_loading: 'data_table/TABLE_LOADING',
      }),
      site_id: function () {
        return this.$route.params.id;
      }
    },
    methods: {
      ...mapActions({
        getProviders: 'sites_providers/GET_PROVIDERS',
        clearProviders: 'sites_providers/CLEAR_PROVIDERS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>