<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="addresses.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.hidden`]="{ item }" disabled>
      <div v-if="item.hidden"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="addresses.meta.from">
      <TablePagination :items="addresses" action="locality_addresses/GET_ADDRESSES" :add_params="{locality_id: locality_id}" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Localities/Localities/relations/Addresses/components/Top'
  import Actions from '@/components/Localities/Localities/relations/Addresses/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Улица', value: 'street', sortable: false },
          { text: 'Дом', value: 'house', sortable: false },
          { text: 'Провайдер', value: 'provider.name', sortable: false },
          { text: 'Скрытый', value: 'hidden', sortable: false },
          { text: 'Приоритет', value: 'priority', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
        check: 'mdi-check'
      }
    },
    mounted () {
      this.disabledAndLoading();
      this.getAddresses({locality_id: this.locality_id})
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearAddresses();
    },
    computed: {
      ...mapGetters({
        addresses: 'locality_addresses/ADDRESSES',
        table_loading: 'data_table/TABLE_LOADING',
      }),
      locality_id: function () {
        return this.$route.params.id;
      }
    },
    methods: {
      ...mapActions({
        getAddresses: 'locality_addresses/GET_ADDRESSES',
        clearAddresses: 'locality_addresses/CLEAR_ADDRESSES',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>