import { authGuardFail } from '../guards.js'

import NewsCategories from '../../views/News/Categories.vue'
import NewsTopics from '../../views/News/Topics.vue'
import News from '../../views/News/News/News.vue'
import NewsNewsCategories from '../../views/News/News/Categories.vue'

export default [
  {
    path: '/news-categories',
    name: 'news-categories',
    component: NewsCategories,
    beforeEnter: authGuardFail
  },
  {
    path: '/news-topics',
    name: 'news-topics',
    component: NewsTopics,
    beforeEnter: authGuardFail
  },
  {
      path: '/news',
      name: 'news',
      component: News,
      beforeEnter: authGuardFail
  },
  {
      path: '/news/:id/news-categories',
      name: 'news-news-categories',
      component: NewsNewsCategories,
      beforeEnter: authGuardFail
  },
]
