<template>
  <td colspan="11">
    <div class="custom_title_text_wrap">
      <div class="custom_title">
        Провайдерский бренд:
        <span v-if="item.provider_brand">{{ item.provider_brand }}</span>
        <span v-else style="color: #6a6a6a">Не задано</span>
      </div>
      <div class="custom_title">
        Бренд:
        <span v-if="item.brand">{{ item.brand }}</span>
        <span v-else style="color: #6a6a6a">Не задано</span>
      </div>
    </div>
  </td>
</template>

<script>

  export default {
    props: ["item"]
  }
</script>
