<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="options.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="options.meta.from">
      <TablePagination :items="options" action="options/GET_OPTIONS" :add_params="params" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Tarifs/Options/components/Top'
  import Actions from '@/components/Tarifs/Options/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Название', value: 'name', sortable: false },
          { text: 'Описание', value: 'description', sortable: false },
          { text: 'Провайдер', value: 'provider.name', sortable: false },
          { text: 'Популярность(legacy)', value: 'old_demand', sortable: false },
          { text: 'Популярность', value: 'demand', sortable: false },
          { text: 'Действия', value: 'actions', width: '12%', sortable: false },
        ],
      }
    },
    mounted () {
      this.disabledAndLoading();
      this.getOptions()
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearOptions();
      this.clearProviders();
    },
    computed: {
      ...mapGetters({
        options: 'options/OPTIONS',
        table_loading: 'data_table/TABLE_LOADING',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
      }),
      params: function () {
          let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
          return params;
      }
    },
    methods: {
      ...mapActions({
        getOptions: 'options/GET_OPTIONS',
        clearOptions: 'options/CLEAR_OPTIONS',
        clearProviders: 'providers/CLEAR_PROVIDERS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>
