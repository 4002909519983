<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete v-if="!dialog.item"
                  v-model="item.region_id"
                  item-text="name"
                  item-value="id"
                  :items="regions.data"
                  label="Регион"
                  :error-messages="errors.region_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="item.phone_id"
                  item-text="phone"
                  item-value="id"
                  :items="phones.data"
                  label="Номер телефона"
                  clearable
                  :error-messages="errors.phone_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-checkbox
                  label="Скрытый"
                  v-model="item.hidden"
                  :error-messages="errors.hidden"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getRegions();
      await this.getPhones();
      this.nonDisabledAndLoading();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        sites_regions: 'sites_regions/REGIONS',
        regions: 'regions/REGIONS',
        phones: 'phones/PHONES'
      }),
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            region_id: item.name ? item.name : null,
            phone_id: item.phone ? item.phone.id : null,
            hidden: item.hidden,
          }
        }
        else {
          return {
            region_id: '',
            phone_id: '',
            hidden: '',
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getSitesRegions: 'sites_regions/GET_REGIONS',
        storeRegion: 'sites_regions/STORE_REGION',
        updateRegion: 'sites_regions/UPDATE_REGION',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getRegions: 'regions/GET_ALL_REGIONS',
        getPhones: 'phones/GET_ALL_PHONES'
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.region_id = null;
        this.item.phone_id = null;
        this.item.hidden = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getSitesRegions({ site_id: this.$route.params.id, page: this.sites_regions.meta.current_page, per_page: this.sites_regions.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (this.item.phone_id === undefined) this.item.phone_id = null;
        if (!this.item.hidden) this.item.hidden = null;
        this.item["site_id"] = this.$route.params.id;
        if (this.dialog.item) {
          this.item.region_id  = this.item.id;
          this.updateRegion(this.item)
            .then(() => {
                this.successActions({message: "Регион изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeRegion(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Регион добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>