<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Наименование ТВ-приставки"
                  type="text"
                  autofocus
                  v-model="item.name"
                  :error-messages="errors.name"
                ></v-text-field>
                <v-text-field
                  label="Операционная система"
                  type="text"
                  v-model="item.os"
                  :error-messages="errors.os"
                ></v-text-field>
                <v-text-field
                  label="Цвет"
                  type="text"
                  v-model="item.color"
                  :error-messages="errors.color"
                ></v-text-field>
                <v-text-field
                  label="Размер"
                  type="text"
                  v-model="item.size"
                  :error-messages="errors.size"
                ></v-text-field>
                <v-checkbox
                  label="Поддержка Wi-Fi"
                  v-model="item.wifi"
                  :error-messages="errors.wifi"
                ></v-checkbox>
                <v-checkbox
                  label="Поддержка HDMI"
                  v-model="item.hdmi"
                  :error-messages="errors.hdmi"
                ></v-checkbox>
                <v-checkbox
                  label="Поддержка USB"
                  v-model="item.usb"
                  :error-messages="errors.usb"
                ></v-checkbox>
                <v-checkbox
                  label="Поддержка UHD"
                  v-model="item.uhd"
                  :error-messages="errors.uhd"
                ></v-checkbox>
                <v-text-field
                  label="Бренд"
                  type="text"
                  v-model="item.brand"
                  :error-messages="errors.brand"
                ></v-text-field>
                <v-text-field
                  label="Провайдерский бренд"
                  type="text"
                  v-model="item.provider_brand"
                  :error-messages="errors.provider_brand"
                ></v-text-field>
                <v-autocomplete
                  v-model="item.provider_id"
                  item-text="name"
                  item-value="id"
                  :items="providers.data"
                  label="Провайдер"
                  :error-messages="errors.provider_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getProviders();
      this.nonDisabledAndLoading();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        tv_boxes: 'tv_boxes/TV_BOXES',
        providers: 'providers/PROVIDERS',
      }),
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            name: item.name,
            os: item.os,
            color: item.color,
            size: item.size,
            wifi: item.wifi,
            hdmi: item.hdmi,
            usb: item.usb,
            uhd: item.uhd,
            brand: item.brand,
            provider_brand: item.provider_brand,
            provider_id: item.provider ? item.provider.id : null,
          }
        }
        else {
          return {
            name: '',
            os: '',
            color: '',
            size: '',
            wifi: '',
            hdmi: '',
            usb: '',
            uhd: '',
            brand: '',
            provider_brand: '',
            provider_id: '',
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getTvBoxes: 'tv_boxes/GET_TV_BOXES',
        storeTvBox: 'tv_boxes/STORE_TV_BOX',
        updateTvBox: 'tv_boxes/UPDATE_TV_BOX',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getProviders: 'providers/GET_ALL_PROVIDERS',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.name = null;
        this.item.os = null;
        this.item.color = null;
        this.item.size = null;
        this.item.wifi = null;
        this.item.hdmi = null;
        this.item.usb = null;
        this.item.uhd = null;
        this.item.brand = null;
        this.item.provider_brand = null;
        this.item.provider_id = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getTvBoxes({ page: this.tv_boxes.meta.current_page, per_page: this.tv_boxes.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (!this.item.wifi) this.item.wifi = null;
        if (!this.item.hdmi) this.item.hdmi = null;
        if (!this.item.usb) this.item.usb = null;
        if (!this.item.uhd) this.item.uhd = null;
        if (this.dialog.item) {
          this.updateTvBox(this.item)
            .then(() => {
                this.successActions({message: "ТВ-приставка изменена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeTvBox(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "ТВ-приставка добавлена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>