export default({
  namespaced: true,

  state: {
    error: {
      response: false,
      obj: false
    }
  },

  getters: {
    ERROR (state){
      return state.error;
    }
  },

  mutations: {
    SET_ERROR_CONFLICT (state, params) {
      if (params.response && params.response.status == 409) {
        params.obj.setSnackbar({message: params.response.data.message, color: "red"});
      }
      else {
        params.obj.setSnackbar({message: "Ошибка сервера", color: "red"});
      }
      params.obj.nonDisabledAndLoading();
    }
  },

  actions: {
    SHOW_ERROR_CONFLICT ({ commit }, params) {
      commit('SET_ERROR_CONFLICT', params);
    }
  }
});
