<template>
  <v-main>
    <v-container
        fluid
    >
      <Table />
      <v-snackbar v-model="snackbar.status" top :color="snackbar.color">
        {{snackbar.message}}
      </v-snackbar>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'
import Table from '@/components/Providers/Images/BannersHidpi/Table'

export default {
  components: {
    Table
  },
  computed: {
    ...mapGetters({
      snackbar: 'snackbar/SNACKBAR',
    }),
  }
}
</script>