<template>
  <div>
    <v-icon small class="mr-2" @click="openPrices(item.id)" :disabled="disabled" title="Цены">mdi-cash</v-icon>
    <v-icon small class="mr-2" @click="editItem(item)" :disabled="disabled" title="Редактировать">mdi-pencil</v-icon>
    <v-icon small class="ml-2" @click="deleteItem(item)" :disabled="disabled" title="Удалить">mdi-delete</v-icon>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        rents: 'locality_tarif_tv_box_rents/RENTS',
      })
    },
    methods: {
      ...mapActions({
        deleteRent: 'locality_tarif_tv_box_rents/DELETE_RENT',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getRents: 'locality_tarif_tv_box_rents/GET_RENTS',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      openPrices (id) {
        this.$router.push({
          name: 'locality-provider-tarif-tv-box-rent-prices', params: { locality_id: this.$route.params.locality_id, provider_id: this.$route.params.provider_id, tarif_id: this.$route.params.tarif_id, tv_box_id: this.$route.params.id, id: id }
        })
      },

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        var meta = this.rents.meta;
        this.deleteRent({ id: item.id, locality_id: this.$route.params.locality_id, tarif_id: this.$route.params.tarif_id, tv_box_id: this.$route.params.id })
          .then(() => {
            this.setSnackbar({message: "Аренда удалена"});
            if(meta.from === meta.to) {
              this.getRents({ locality_id: this.$route.params.locality_id, tarif_id: this.$route.params.tarif_id, tv_box_id: this.$route.params.id, page: meta.current_page - 1, per_page: meta.per_page })
                .then(() => {
                  this.nonDisabledAndLoading();
                });
            } else {
              this.getRents({ locality_id: this.$route.params.locality_id, tarif_id: this.$route.params.tarif_id, tv_box_id: this.$route.params.id, page: meta.current_page, per_page: meta.per_page })
                .then(() => {
                  this.nonDisabledAndLoading();
                });
            }
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
      },
    }
  }
</script>
