<template>
  <div>
    <v-icon small class="ml-2" @click="deleteItem(item)" :disabled="disabled" title="Удалить">mdi-delete</v-icon>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        channels: 'channels_package_channels/CHANNELS',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
      }),
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        params.page = (this.channels.meta.from === this.channels.meta.to) ? this.channels.meta.current_page - 1 : this.channels.meta.current_page;
        params.per_page = this.channels.meta.per_page;
        params.channels_package_id = this.$route.params.id;
        return params;
      }
    },
    methods: {
      ...mapActions({
        deleteChannel: 'channels_package_channels/DELETE_CHANNEL',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getChannels: 'channels_package_channels/GET_CHANNELS',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        this.deleteChannel({ channels_package_id: this.$route.params.id, id: item.id })
          .then(() => {
            this.setSnackbar({message: "Канал удален"});
            this.getChannels(this.params)
              .then(() => {
                this.nonDisabledAndLoading();
              });
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
      },
    }
  }
</script>
