<template>
  <v-toolbar
    flat
  >
    <v-toolbar-title v-if="locality&&provider">{{locality.data.name}} -> {{provider.data.name}}: Тарифы</v-toolbar-title>
    <v-divider
        class="mx-4"
        inset
        vertical
    ></v-divider>
    <v-spacer></v-spacer>

    <v-btn
        color="primary"
        class="mb-2"
        :disabled="disabled"
        @click.stop="openDialog()"
    >
      Добавить
    </v-btn>

    <Dialog />

  </v-toolbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Dialog from '@/components/Localities/Localities/relations/Providers/relations/Tarifs/components/Dialog'

export default {
  components: {
    Dialog
  },
  mounted () {
    this.getLocality(this.$route.params.locality_id);
    this.getProvider(this.$route.params.id);
  },
  destroyed () {
    this.clearLocality();
    this.clearProvider();
  },
  computed: {
    ...mapGetters({
      locality: 'localities/LOCALITY',
      provider: 'providers/PROVIDER',
      disabled: 'data_table/DISABLED',
    }),
  },
  methods: {
    ...mapActions({
      setDialog: 'dialog/SET_DIALOG_TRUE',
      getLocality: 'localities/GET_LOCALITY',
      clearLocality: 'localities/CLEAR_LOCALITY',
      getProvider: 'providers/GET_PROVIDER',
      clearProvider: 'providers/CLEAR_PROVIDER',
    }),
    openDialog () {
      this.setDialog();
    }
  }
}
</script>