<template>
  <v-toolbar
    flat
  >
    <v-toolbar-title>Населенные пунты</v-toolbar-title>
    <v-divider
      class="mx-4"
      inset
      vertical
    ></v-divider>
    <v-select
        :value="region_id"
        item-text="name"
        item-value="id"
        :items="regions.data"
        label="Регион"
        clearable
        dense
        solo
        hide-details="true"
        v-on:change="changeRegionThrottle"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Ничего не найдено
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-select>
    <v-divider
        class="mx-4"
        inset
        vertical
    ></v-divider>
    <v-text-field
        label="Название нас. пункта"
        dense
        outlined
        clearable
        hide-details
        v-model="searchField"
    ></v-text-field>
    <v-spacer></v-spacer>

    <v-btn
      color="primary"
      class="mb-2"
      :disabled="disabled"
      @click.stop="openDialog()"
    >
      Добавить
    </v-btn>

    <Dialog />

  </v-toolbar>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Dialog from '@/components/Localities/Localities/components/Dialog'
  import throttle from "@/assets/js/throttle";

  export default {
    components: {
      Dialog
    },
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        regions: 'regions/REGIONS',
        region_id: 'region_id/REGION_ID',
        search: 'search/SEARCH'
      }),
      changeRegionThrottle() {
        return throttle(this.changeRegion,1000);
      },
      searchField: {
        get() {
          return this.$attrs.value;
        },
        set(val) {
          if (val === undefined) {
            this.setSearch(null);
          } else {
            this.setSearch(val);
          }
        },
      },
      params: function () {
        var params = {};
        if (this.region_id) {
          params.region_id = this.region_id;
        }
        if (this.search) {
          params.searchFilter = {
            "name": this.search
          }
        }
        return params;
      }
    },
    watch: {
      search: throttle(function () {
        this.disabledAndLoading();
        this.getLocalities(this.params)
            .then(() => {
              this.nonDisabledAndLoading()
            });
      }, 1000),
    },
    methods: {
      ...mapActions({
        setDialog: 'dialog/SET_DIALOG_TRUE',
        setRegionID: 'region_id/SET_REGION_ID',
        clearRegionID: 'region_id/CLEAR_REGION_ID',
        setSearch: 'search/SET_SEARCH',
        clearSearch: 'search/CLEAR_SEARCH',
        getLocalities: 'localities/GET_LOCALITIES',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      }),
      openDialog() {
        this.setDialog();
      },
      changeRegion (region_id) {
        this.disabledAndLoading();
        if (region_id === undefined) {
          this.setRegionID(null);
        } else {
          this.setRegionID(region_id);
        }
        this.getLocalities(this.params)
            .then(() => {
              this.nonDisabledAndLoading()
            });
      },
    }
  }
</script>
