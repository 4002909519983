<template>
  <v-dialog v-model="logo_dialog" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <div>1</div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        @click="closeDialog"
        :disabled="disabled"
      >
        Отмена
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        type="submit"
        :disabled="disabled"
      >
        Сохранить
      </v-btn>
  </v-card-actions>
  </v-dialog>
</template>

<script>
  export default {
    data () {
      return {
        disabled: false
      }
    },
    props: ["logo_dialog"],
    methods: {
      closeDialog () {
        this.$emit('closeDialog')
      }
    }
  }
</script>