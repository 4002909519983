import axios from 'axios'

export default({
  namespaced: true,

  state: {
    rent_prices: {
      data: [],
      meta: {
        current_price_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    RENT_PRICES (state){
      return state.rent_prices;
    }
  },

  mutations: {
    SET_RENT_PRICES (state, data) {
      state.rent_prices = data;
    },

    CLEAR_RENT_PRICES (state) {
      state.rent_prices = {
        data: [],
        meta: {
          current_price_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_RENT_PRICES: async (context, params) => {
      var locality_id = params.locality_id;
      var tarif_id = params.tarif_id;
      var tv_box_id = params.tv_box_id;
      var rent_id = params.rent_id;
      delete params.locality_id;
      delete params.tarif_id;
      delete params.tv_box_id;
      delete params.rent_id;
      var params_str;
      params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      let {data} = await axios.get('/localities/' + locality_id + '/tarifs/' + tarif_id + '/tv-boxes/' + tv_box_id + '/rents/' + rent_id + '/prices?' + params_str);
      context.commit('SET_RENT_PRICES', data);
    },

    CLEAR_RENT_PRICES (context) {
      context.commit('CLEAR_RENT_PRICES');
    },

    STORE_RENT_PRICE: async (context, params) => {
      var locality_id = params.locality_id;
      var tarif_id = params.tarif_id;
      var tv_box_id = params.tv_box_id;
      var rent_id = params.rent_id;
      delete params.locality_id;
      delete params.tarif_id;
      delete params.tv_box_id;
      delete params.rent_id;
      await axios.post('/localities/' + locality_id + '/tarifs/' + tarif_id + '/tv-boxes/' + tv_box_id + '/rents/' + rent_id + '/prices', params);
    },

    UPDATE_RENT_PRICE: async (context, params) => {
      var locality_id = params.locality_id;
      var tarif_id = params.tarif_id;
      var tv_box_id = params.tv_box_id;
      var rent_id = params.rent_id;
      var id = params.id;
      delete params.locality_id;
      delete params.tarif_id;
      delete params.tv_box_id;
      delete params.rent_id;
      delete params.id;
      await axios.put('/localities/' + locality_id + '/tarifs/' + tarif_id + '/tv-boxes/' + tv_box_id + '/rents/' + rent_id + '/prices/' + id, params);
    },

    DELETE_RENT_PRICE: async (context, params) => {
      await axios.delete('/localities/' + params.locality_id + '/tarifs/' + params.tarif_id + '/tv-boxes/' + params.tv_box_id + '/rents/' + params.rent_id + '/prices/' + params.id);
    }
  }
});
