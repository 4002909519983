<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="tv_boxes.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
    show-expand
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:expanded-item="{ item }">
      <Expanded :item="item"/>
    </template>

    <template v-slot:[`item.wifi`]="{ item }" disabled>
      <div v-if="item.wifi"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.hdmi`]="{ item }" disabled>
      <div v-if="item.hdmi"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.usb`]="{ item }" disabled>
      <div v-if="item.usb"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.uhd`]="{ item }" disabled>
      <div v-if="item.uhd"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="tv_boxes.meta.from">
      <TablePagination :items="tv_boxes" action="tv_boxes/GET_TV_BOXES" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Devices/TvBoxes/components/Top'
  import Actions from '@/components/Devices/TvBoxes/components/Actions'
  import Expanded from '@/components/Devices/TvBoxes/components/Expanded.vue'

  export default {
    components: {
      Expanded,
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Название', value: 'name', sortable: false },
          { text: 'Операционная система', value: 'os', sortable: false },
          { text: 'Цвет', value: 'color', sortable: false },
          { text: 'Размер', value: 'size', sortable: false },
          { text: 'Поддержка WI-FI', value: 'wifi', sortable: false },
          { text: 'Поддержка HDMI', value: 'hdmi', sortable: false },
          { text: 'Поддержка USB', value: 'usb', sortable: false },
          { text: 'Поддержка UHD', value: 'uhd', sortable: false },
          { text: 'Провайдер', value: 'provider.name', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false, align: 'right' },
        ],
        check: 'mdi-check'
      }
    },
    mounted () {
      this.clearTvBoxes();
      this.disabledAndLoading();
      this.getTvBoxes()
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearTvBoxes();
      this.clearProviders();
    },
    computed: {
      ...mapGetters({
        tv_boxes: 'tv_boxes/TV_BOXES',
        table_loading: 'data_table/TABLE_LOADING',
      })
    },
    methods: {
      ...mapActions({
        getTvBoxes: 'tv_boxes/GET_TV_BOXES',
        clearTvBoxes: 'tv_boxes/CLEAR_TV_BOXES',
        clearProviders: 'providers/CLEAR_PROVIDERS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>
