import axios from 'axios'

export default({
  namespaced: true,

  state: {
    actions: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    ACTIONS (state){
      return state.actions;
    }
  },

  mutations: {
    SET_ACTIONS (state, data) {
      state.actions = data;
    },

    CLEAR_ACTIONS (state) {
      state.actions = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_ACTIONS: async (context, params) => {
      if (params) {
        let {data} = await axios.get('/actions?', {params: params});
        context.commit('SET_ACTIONS', data);
      } else {
        let {data} = await axios.get('/actions');
        context.commit('SET_ACTIONS', data);
      }
    },

    GET_ALL_ACTIONS: async (context) => {
      let {data} = await axios.get('/actions?per_page=1000&sort_by=name');
      context.commit('SET_ACTIONS', data);
    },

    GET_ACTIONS_ON_PROVIDER: async (context, params) => {
      var provider_id = params.provider_id;
      delete params.provider_id;
      let {data} = await axios.get('/actions?per_page=1000&provider_id=' + provider_id);
      context.commit('SET_ACTIONS', data);
    },

    CLEAR_ACTIONS (context) {
      context.commit('CLEAR_ACTIONS');
    },

    STORE_ACTION: async (context, params) => {
      await axios.post('/actions', params);
    },

    UPDATE_ACTION: async (context, params) => {
      await axios.put('/actions/' + params.id, params);
    },

    COPY_ACTION: async (context, params) => {
      var action_id = params.id;
      delete params.id;
      await axios.post('/actions/' + action_id + '/copy', params);
    },

    DELETE_ACTION: async (context, params) => {
      await axios.delete('/actions/' + params.id);
    }
  }
});
