import axios from 'axios'

export default({
  namespaced: true,

  state: {
    localities: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    LOCALITIES (state){
      return state.localities;
    }
  },

  mutations: {
    SET_LOCALITIES (state, data) {
      state.localities = data;
    },

    CLEAR_LOCALITIES (state) {
      state.localities = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_LOCALITIES: async (context, params) => {
      var site_id = params.site_id;
      delete params.site_id;
      var params_str;
      params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      let {data} = await axios.get('/sites/' + site_id + '/localities?' + params_str);
      context.commit('SET_LOCALITIES', data);
    },

    CLEAR_LOCALITIES (context) {
      context.commit('CLEAR_LOCALITIES');
    },

    STORE_LOCALITY: async (context, params) => {
      var site_id = params.site_id;
      delete params.site_id;
      await axios.post('/sites/' + site_id + '/localities', params);
    },

    UPDATE_LOCALITY: async (context, params) => {
      var site_id = params.site_id;
      delete params.site_id;
      await axios.put('/sites/' + site_id + '/localities/' + params.id, params);
    },

    DELETE_LOCALITY: async (context, params) => {
      await axios.delete('/sites/' + params.site_id + '/localities/' + params.id);
    }
  }
});
