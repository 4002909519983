import axios from 'axios'

export default({
  namespaced: true,

  state: {
    locality_types: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    LOCALITY_TYPES (state){
      return state.locality_types;
    }
  },

  mutations: {
    SET_LOCALITY_TYPES (state, data) {
      state.locality_types = data;
    },

    CLEAR_LOCALITY_TYPES (state) {
      state.locality_types = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_LOCALITY_TYPES: async (context, params) => {
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/locality-types?' + params_str);
        context.commit('SET_LOCALITY_TYPES', data);
      } else {
        let {data} = await axios.get('/locality-types');
        context.commit('SET_LOCALITY_TYPES', data);
      }
    },

    GET_ALL_LOCALITY_TYPES: async (context) => {
      let {data} = await axios.get('/locality-types?per_page=1000&sort_by=name');
      context.commit('SET_LOCALITY_TYPES', data);
    },

    CLEAR_LOCALITY_TYPES (context) {
      context.commit('CLEAR_LOCALITY_TYPES');
    },

    STORE_LOCALITY_TYPE: async (context, params) => {
      await axios.post('/locality-types', params);
    },

    UPDATE_LOCALITY_TYPE: async (context, params) => {
      await axios.put('/locality-types/' + params.id, params);
    },

    DELETE_LOCALITY_TYPE: async (context, params) => {
      await axios.delete('/locality-types/' + params.id);
    }
  }
});
