import axios from 'axios'

export default({
    namespaced: true,

    state: {
        news_categories: {
            data: [],
            meta: {
                current_page: null,
                from: null,
                last_page: null,
                path: null,
                per_page: null,
                to: null,
                total: null
            }
        },
        news_category: null
    },

    getters: {
        NEWS_CATEGORIES (state) {
            return state.news_categories;
        },
        NEWS_CATEGORY (state) {
            return state.news_category;
        },
    },

    mutations: {
        SET_NEWS_CATEGORIES (state, data) {
            state.news_categories = data;
        },

        SET_NEWS_CATEGORY (state, data) {
            state.news_category = data;
        },

        CLEAR_NEWS_CATEGORIES (state) {
            state.news_categories = {
                data: [],
                meta: {
                    current_page: null,
                    from: null,
                    last_page: null,
                    path: null,
                    per_page: null,
                    to: null,
                    total: null
                }
            }
        },

        CLEAR_NEWS_CATEGORY (state) {
            state.news_category = null;
        }
    },

    actions: {
        GET_NEWS_CATEGORIES: async (context, params) => {
            var params_str;
            if (params) {
                params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                let {data} = await axios.get('/news-categories?' + params_str);
                context.commit('SET_NEWS_CATEGORIES', data);
            } else {
                let {data} = await axios.get('/news-categories');
                context.commit('SET_NEWS_CATEGORIES', data);
            }
        },

        GET_ALL_NEWS_CATEGORIES: async (context) => {
            let {data} = await axios.get('/news-categories?per_page=1000&sort_by=name');
            context.commit('SET_NEWS_CATEGORIES', data);
        },

        GET_NEWS_CATEGORY: async (context, params) => {
            let {data} = await axios.get('/news-categories/' + params);
            context.commit('SET_NEWS_CATEGORY', data);
        },

        CLEAR_NEWS_CATEGORIES (context) {
            context.commit('CLEAR_NEWS_CATEGORIES');
        },

        CLEAR_NEWS_CATEGORY (context) {
            context.commit('CLEAR_NEWS_CATEGORY');
        },

        STORE_NEWS_CATEGORY: async (context, params) => {
            await axios.post('/news-categories', params);
        },

        UPDATE_NEWS_CATEGORY: async (context, params) => {
            await axios.put('/news-categories/' + params.id, params);
        },

        DELETE_NEWS_CATEGORY: async (context, params) => {
            await axios.delete('/news-categories/' + params.id);
        }
    }
});
