<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  label="Город"
                  type="text"
                  autofocus
                  v-model="item.city"
                  :error-messages="errors.city"
                ></v-text-field>
                <v-text-field
                  label="Улица"
                  type="text"
                  autofocus
                  v-model="item.street"
                  :error-messages="errors.street"
                ></v-text-field>
                <v-text-field
                  label="Дом"
                  type="text"
                  v-model="item.house"
                  :error-messages="errors.house"
                ></v-text-field>
                <v-text-field
                  label="Координаты офиса"
                  type="text"
                  v-model="item.coord"
                  :error-messages="errors.coord"
                ></v-text-field>
                <v-autocomplete
                  v-model="item.provider_id"
                  item-text="name"
                  item-value="id"
                  :items="providers.data"
                  label="Провайдер"
                  :error-messages="errors.provider_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="item.office_phone_id"
                  item-text="phone"
                  item-value="id"
                  clearable
                  :items="office_phones.data"
                  label="Номер телефона офиса"
                  :error-messages="errors.office_phone_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-if="item.schedule"
              >
                <v-text-field
                  label="Дни"
                  type="text"
                  v-model="item.schedule[0].days"
                ></v-text-field>
                <v-text-field
                  label="Время"
                  type="text"
                  v-model="item.schedule[0].time"
                ></v-text-field>
                <v-text-field
                  label="Перерыв"
                  type="text"
                  v-model="item.schedule[0].break"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getProviders();
      await this.getOfficePhones();
      this.nonDisabledAndLoading();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        addresses: 'addresses/ADDRESSES',
        providers: 'providers/PROVIDERS',
        office_phones: 'office_phones/OFFICE_PHONES',
        search: 'search/SEARCH'
      }),
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            city: item.city,
            street: item.street,
            house: item.house,
            coord: item.coord,
            schedule: item.schedule ? JSON.parse(item.schedule) : [{}],
            provider_id: item.provider ? item.provider.id : null,
            office_phone_id: item.office_phone ? item.office_phone.id : null,
          }
        }
        else {
          return {
            city: '',
            street: '',
            house: '',
            coord: '',
            schedule: [{}],
            provider_id: '',
            office_phone_id: '',
          }
        }
      },

      params: function () {
        var params = { page: this.addresses.meta.current_page, per_page: this.addresses.meta.per_page };
        if (this.search) {
          params.searchFilter = {
            "city": this.search
          }
        }
        return params;
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getAddresses: 'addresses/GET_ADDRESSES',
        storeAddress: 'addresses/STORE_ADDRESS',
        updateAddress: 'addresses/UPDATE_ADDRESS',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getProviders: 'providers/GET_ALL_PROVIDERS',
        getOfficePhones: 'office_phones/GET_ALL_OFFICE_PHONES',
        search: 'search/SEARCH'
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.city = null;
        this.item.street = null;
        this.item.house = null;
        this.item.coord = null;
        if (this.item.schedule[0].days) this.item.schedule[0].days = null;
        if (this.item.schedule[0].time) this.item.schedule[0].time = null;
        if (this.item.schedule[0].break) this.item.schedule[0].break = null;
        this.item.provider_id = null;
        this.item.office_phone_id = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getAddresses(this.params)
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (!this.item.schedule[0].days) delete this.item.schedule[0].days;
        if (!this.item.schedule[0].time) delete this.item.schedule[0].time;
        if (!this.item.schedule[0].break) delete this.item.schedule[0].break;
        this.item.schedule = JSON.stringify(this.item.schedule);
        if (this.item.schedule == "[{}]") this.item.schedule = null;
        if (this.item.office_phone_id === undefined) this.item.office_phone_id = null;
        if (this.dialog.item) {
          this.updateAddress(this.item)
            .then(() => {
                this.successActions({message: "Адрес изменен"});
            })
            .catch(e => {
              if (this.item.schedule == null) {
                this.item.schedule = [{}];
              } else {
                this.item.schedule = JSON.parse(this.item.schedule);
              }
              
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeAddress(this.item)
            .then(() => {
              if (this.item.schedule == null) {
                this.item.schedule = [{}];
              } else {
                this.item.schedule = JSON.parse(this.item.schedule);
              }
              this.clearItem();
              this.successActions({message: "Адрес добавлен"});
            })
            .catch(e => {
              if (this.item.schedule == null) {
                this.item.schedule = [{}];
              } else {
                this.item.schedule = JSON.parse(this.item.schedule);
              }

              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>