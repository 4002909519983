import axios from 'axios'

export default({
  namespaced: true,

  state: {
    socials: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    SOCIALS (state){
      return state.socials;
    }
  },

  mutations: {
    SET_SOCIALS (state, data) {
      state.socials = data;
    },

    CLEAR_SOCIALS (state) {
      state.socials = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_SOCIALS: async (context, params) => {
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/socials?' + params_str);
        context.commit('SET_SOCIALS', data);
      } else {
        let {data} = await axios.get('/socials');
        context.commit('SET_SOCIALS', data);
      }
    },

    GET_ALL_SOCIALS: async (context) => {
      let {data} = await axios.get('/socials?per_page=1000&sort_by=name');
      context.commit('SET_SOCIALS', data);
    },

    CLEAR_SOCIALS (context) {
      context.commit('CLEAR_SOCIALS');
    },

    STORE_SOCIAL: async (context, params) => {
      await axios.post('/socials', params);
    },

    UPDATE_SOCIAL: async (context, params) => {
      await axios.put('/socials/' + params.id, params);
    },

    DELETE_SOCIAL: async (context, params) => {
      await axios.delete('/socials/' + params.id);
    }
  }
});
