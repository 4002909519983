<template>
  <div>
<!--    <v-btn :disabled="disabled" link :to="{name: 'site-localities-providers', params: { site_id: this.$route.params.id, id: item.id }}" icon color="gray" small rounded class="mr-2" title="Провайдеры"><v-icon>mdi-ip-network</v-icon></v-btn>-->
<!--    <v-btn :disabled="disabled" link :to="{name: 'site-localities-tarifs', params: { site_id: this.$route.params.id, id: item.id }}" icon color="gray" small rounded class="mr-2" title="Тарифы"><v-icon>mdi-alpha-t</v-icon></v-btn>-->
    <v-btn :disabled="disabled" link :to="{name: 'site-locality-questions-answers', params: { site_id: this.$route.params.id, id: item.id }}" icon color="gray" small rounded class="mr-2" title="Вопросы-ответы"><v-icon>mdi-forum-outline</v-icon></v-btn>
    <v-icon small class="mr-2" @click="editItem(item)" :disabled="disabled" title="Редактировать">mdi-pencil</v-icon>
    <v-icon small class="mr-2" @click="deleteItem(item)" :disabled="disabled">mdi-delete</v-icon>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        localities: 'sites_localities/LOCALITIES',
        region_id: 'region_id/REGION_ID',
      })
    },
    methods: {
      ...mapActions({
        deleteLocality: 'sites_localities/DELETE_LOCALITY',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getLocalities: 'sites_localities/GET_LOCALITIES',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        var meta = this.localities.meta;
        this.deleteLocality({ id: item.id, site_id: this.$route.params.id })
          .then(() => {
            this.setSnackbar({message: "Нас. пункт удален"});
            let current_page;
            if (meta.from === meta.to) {
              current_page = meta.current_page - 1;
            } else {
              current_page = meta.current_page;
            }
            let params = { site_id: this.$route.params.id, page: current_page, per_page: meta.per_page };
            if (this.region_id) {
              params.region_id = this.region_id;
            }
            this.getLocalities(params)
              .then(() => {
                this.nonDisabledAndLoading();
              });
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
      },
    }
  }
</script>
