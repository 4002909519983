<template>
  <div>
    <v-icon small class="ml-2" @click="deleteItem(item)" :disabled="disabled" title="Удалить">mdi-delete</v-icon>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        channels: 'locality_channels/CHANNELS',
      })
    },
    methods: {
      ...mapActions({
        deleteChannel: 'locality_channels/DELETE_CHANNEL',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getChannels: 'locality_channels/GET_CHANNELS',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        var meta = this.channels.meta;
        this.deleteChannel({ locality_id: this.$route.params.id, id: item.id })
          .then(() => {
            this.setSnackbar({message: "Канал удален"});
            if(meta.from === meta.to) {
              this.getChannels({ locality_id: this.$route.params.id, page: meta.current_page - 1, per_page: meta.per_page })
                .then(() => {
                  this.nonDisabledAndLoading();
                });
            } else {
              this.getChannels({ locality_id: this.$route.params.id, page: meta.current_page, per_page: meta.per_page })
                .then(() => {
                  this.nonDisabledAndLoading();
                });
            }
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
      },
    }
  }
</script>
