<template>
  <v-toolbar
    flat
  >
    <v-toolbar-title v-if="site&&locality">{{site.data.url}} -> {{locality.data.name}}: Тарифы</v-toolbar-title>
    <v-divider
      class="mx-4"
      inset
      vertical
    ></v-divider>
    <v-select
      :value="provider_id"
      item-text="name"
      item-value="id"
      :items="providers.data"
      label="Провайдер"
      clearable
      dense
      solo
      hide-details="true"
      v-on:change="changeProvider"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Ничего не найдено
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-select>
    <v-spacer></v-spacer>
    <v-select
      :value="category_id"
      item-text="name"
      item-value="id"
      :items="categories.data"
      label="Категория"
      clearable
      dense
      solo
      hide-details="true"
      v-on:change="changeCategory"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Ничего не найдено
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-select>
    <v-spacer></v-spacer>

    <v-btn
      color="primary"
      class="mb-2"
      :disabled="disabled"
      @click.stop="openDialog()"
    >
      Добавить
    </v-btn>

    <Dialog />

  </v-toolbar>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Dialog from '@/components/Sites/relations/Localities/relations/Tarifs/components/Dialog'

  export default {
    components: {
      Dialog
    },
    mounted () {
      this.getSite(this.site_id);
      this.getLocality(this.locality_id);
      this.getProviders({site_id: this.site_id, locality_id: this.locality_id});
      this.getCategories({site_id: this.site_id, locality_id: this.locality_id});
    },
    destroyed () {
      this.clearSite();
      this.clearLocality();
      this.clearProviderID();
      this.clearCategoryID();
    },
    computed: {
      ...mapGetters({
        site: 'sites/SITE',
        locality: 'localities/LOCALITY',
        providers: 'sites_localities_providers/PROVIDERS',
        categories: 'sites_localities_providers_categories/CATEGORIES',
        disabled: 'data_table/DISABLED',
        provider_id: 'provider_id/PROVIDER_ID',
        category_id: 'category_id/CATEGORY_ID'
      }),
      site_id: function () {
        return this.$route.params.site_id;
      },
      locality_id: function () {
        return this.$route.params.id;
      }
    },
    methods: {
      ...mapActions({
        setDialog: 'dialog/SET_DIALOG_TRUE',
        getSite: 'sites/GET_SITE',
        clearSite: 'sites/CLEAR_SITE',
        getLocality: 'localities/GET_LOCALITY',
        clearLocality: 'localities/CLEAR_LOCALITY',
        getProviders: 'sites_localities_providers/GET_ALL_PROVIDERS',
        getTarifs: 'sites_localities_tarifs/GET_TARIFS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setProviderID: 'provider_id/SET_PROVIDER_ID',
        clearProviderID: 'provider_id/CLEAR_PROVIDER_ID',
        getCategories: 'sites_localities_providers_categories/GET_CATEGORIES_ON_LOCALITY',
        setCategoryID: 'category_id/SET_CATEGORY_ID',
        clearCategoryID: 'category_id/CLEAR_CATEGORY_ID',
      }),
      openDialog () {
        this.setDialog();
      },
      changeProvider (provider_id) {
        this.disabledAndLoading();
        let params = {site_id: this.site_id, locality_id: this.locality_id};
        if (provider_id === undefined) {
          this.setProviderID(null);
        } else {
          this.setProviderID(provider_id);
          params.provider_id = provider_id;
        }
        if (this.category_id) {
          params.category_id = this.category_id;
        }
        this.getTarifs(params)
          .then(() => {
            this.nonDisabledAndLoading()
          });
      },
      changeCategory (category_id) {
        this.disabledAndLoading();
        let params = {site_id: this.site_id, locality_id: this.locality_id};
        if (category_id === undefined) {
          this.setCategoryID(null);
        } else {
          this.setCategoryID(category_id);
          params.category_id = category_id;
        }
        if (this.provider_id) {
          params.provider_id = this.provider_id;
        }
        this.getTarifs(params)
          .then(() => {
            this.nonDisabledAndLoading()
          });
      },
    }
  }
</script>