<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Первый платеж"
                  type="text"
                  v-model="item.start_pay"
                  :error-messages="errors.start_pay"
                ></v-text-field>
                <v-text-field
                  label="Плата за установку"
                  type="text"
                  v-model="item.install_pay"
                  :error-messages="errors.install_pay"
                ></v-text-field>
                <v-checkbox
                  label="Скрытый"
                  v-model="item.hidden"
                  :error-messages="errors.hidden"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    async mounted () {
      this.disabledAndLoading();
      this.nonDisabledAndLoading();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        locality_tarif_router_rents: 'locality_tarif_router_rents/RENTS',
      }),
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            start_pay: item.start_pay ? item.start_pay : null,
            install_pay: item.install_pay ? item.install_pay : null,
            hidden: item.hidden ? item.hidden : null,
          }
        }
        else {
          return {
            start_pay: '',
            install_pay: '',
            hidden: '',
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getLocalityTarifRouterRents: 'locality_tarif_router_rents/GET_RENTS',
        storeRent: 'locality_tarif_router_rents/STORE_RENT',
        updateRent: 'locality_tarif_router_rents/UPDATE_RENT',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.start_pay = null;
        this.item.install_pay = null;
        this.item.hidden = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getLocalityTarifRouterRents({ locality_id: this.$route.params.locality_id, tarif_id: this.$route.params.tarif_id, router_id: this.$route.params.id, page: this.locality_tarif_router_rents.meta.current_page, per_page: this.locality_tarif_router_rents.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (!this.item.hidden) this.item.hidden = null;
        this.item["locality_id"] = this.$route.params.locality_id;
        this.item["tarif_id"] = this.$route.params.tarif_id;
        this.item["router_id"] = this.$route.params.id;
        if (this.dialog.item) {
          this.item.rent_id  = this.item.id;
          this.updateRent(this.item)
            .then(() => {
                this.successActions({message: "Аренда изменена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeRent(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Аренда добавлена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>