<template>
  <div>
    <v-icon small class="ml-2" @click="deleteItem(item)" :disabled="disabled" title="Удалить">mdi-delete</v-icon>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        news_categories: 'news_categories_news/NEWS_CATEGORIES',
      }),
      params: function () {
        return {
            news_id: this.$route.params.id,
            page: (this.news_categories.meta.from === this.news_categories.meta.to) ? this.news_categories.meta.current_page - 1 : this.news_categories.meta.current_page,
            per_page: this.news_categories.meta.per_page
        }
      }
    },
    methods: {
      ...mapActions({
        deleteNewsCategory: 'news_categories_news/DELETE_NEWS_CATEGORY',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getNewsCategories: 'news_categories_news/GET_NEWS_CATEGORIES',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        this.deleteNewsCategory({ id: item.id, news_id: this.$route.params.id })
          .then(() => {
            this.setSnackbar({message: "Связь новости с категорией удалена"});
            this.getNewsCategories(this.params)
              .then(() => {
                this.nonDisabledAndLoading();
              });
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
      },
    }
  }
</script>
