<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="companies.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
    show-expand
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:expanded-item="{ item }">
      <Expanded :item="item"/>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="companies.meta.from">
      <TablePagination :items="companies" action="companies/GET_COMPANIES" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Companies/Companies/components/Top'
  import Expanded from '@/components/Companies/Companies/components/Expanded/Expanded'
  import Actions from '@/components/Companies/Companies/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Expanded,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Название', value: 'name', sortable: false },
          { text: 'Номер', value: 'phone', sortable: false },
          { text: 'Email', value: 'email', sortable: false },
          { text: 'Номер для мес-ов', value: 'messengers_phone', sortable: false },
          { text: 'Адрес', value: 'address', sortable: false },
          { text: 'Координаты', value: 'coord', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
      }
    },
    mounted () {
      this.clearCompanies();
      this.disabledAndLoading();
      this.getCompanies()
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearCompanies();
    },
    computed: {
      ...mapGetters({
        companies: 'companies/COMPANIES',
        table_loading: 'data_table/TABLE_LOADING',
      })
    },
    methods: {
      ...mapActions({
        getCompanies: 'companies/GET_COMPANIES',
        clearCompanies: 'companies/CLEAR_COMPANIES',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>