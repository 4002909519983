<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="tarifs.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="tarifs.meta.from">
      <TablePagination :items="tarifs" action="tv_box_tarifs/GET_TARIFS" :add_params="{tv_box_id: tv_box_id}"/>
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Devices/TvBoxes/relations/Tarifs/components/Top'
  import Actions from '@/components/Devices/TvBoxes/relations/Tarifs/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Провайдер', value: 'provider.name', sortable: false },
          { text: 'Тариф', value: 'name', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ]
      }
    },
    mounted () {
      this.disabledAndLoading();
      this.getTarifs({tv_box_id: this.tv_box_id})
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearTarifs();
    },
    computed: {
      ...mapGetters({
        tarifs: 'tv_box_tarifs/TARIFS',
        table_loading: 'data_table/TABLE_LOADING',
      }),
      tv_box_id() {
        return this.$route.params.id;
      }
    },
    methods: {
      ...mapActions({
        getTarifs: 'tv_box_tarifs/GET_TARIFS',
        clearTarifs: 'tv_box_tarifs/CLEAR_TARIFS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>
