import { authGuardFail } from '../guards.js'

import Supports from '../../views/Supports/Supports.vue'

export default [
  {
    path: '/supports',
    name: 'supports',
    component: Supports,
    beforeEnter: authGuardFail
  },
]