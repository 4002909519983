<template>
  <v-dialog v-model="filter_dialog.status" @click:outside="closeFilterDialog" @keydown.esc="closeFilterDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Фильтры</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-row align="center">
                  <v-autocomplete
                      v-model="item.region_id"
                      item-text="name"
                      item-value="id"
                      :items="regions.data"
                      label="Регион"
                      clearable
                      :error-messages="errors.region_id"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Ничего не найдено
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeFilterDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Применить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    async mounted() {
        this.loading = true;
        await this.getRegions()
            .then(() => {
                this.loading = false;
            });
    },
    destroyed() {
      this.clearFilterDialog();
    },
    computed: {
      ...mapGetters({
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
        localities: 'router_localities/LOCALITIES',
        regions: 'regions/REGIONS',
      }),

      item: function () {
        var item = this.filter_dialog.item;
        if (item) {
          return {
            region_id: item.region_id ? item.region_id : '',
          }
        }
        else {
          return {
            region_id: '',
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetFilterDialog: 'filter_dialog/SET_FILTER_DIALOG_FALSE',
        clearFilterDialog: 'filter_dialog/CLEAR_FILTER_DIALOG',
        closeFilterDialog: 'filter_dialog/CLOSE_FILTER_DIALOG',
        saveFilterDialog: 'filter_dialog/SET_FILTER_DIALOG_ITEM',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getRegions: 'regions/GET_ALL_REGIONS',
        clearProviders: 'regions/CLEAR_REGIONS',
        getLocalities: 'router_localities/GET_LOCALITIES',
      }),

      submitForm () {
        this.setSnackbar({message: "Фильтр применён"});
        this.errors = false;
        let params = Object.assign({}, this.item);
        if (params.region_id === undefined) params.region_id = '';
        params.router_id = this.$route.params.id;
        this.saveFilterDialog(Object.assign({}, params));
        this.closeFilterDialog();
        this.disabledAndLoading();
        params.per_page = this.localities.meta.per_page;
        this.getLocalities(params)
            .then(() => {
              this.nonDisabledAndLoading();
            });
      }
    }
}
</script>
