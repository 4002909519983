import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../views/SignIn.vue'
import PageNotFound from '../views/PageNotFound.vue'
import CopyLocality from '../views/CopyLocality.vue'
import Reviews from '../views/Reviews.vue'
import Seo from '../views/Seo.vue'

import { authGuardSuccess, authGuardFail } from './guards.js'

import CompaniesRoutes from './routes/companies.js'
import DevicesRoutes from './routes/devices.js'
import GeneralServiceRoutes from './routes/general_services.js'
import ProvidersRoutes from './routes/providers.js'
import LocalitiesRoutes from './routes/localities.js'
import SitesRoutes from './routes/sites.js'
import RegionsRoutes from './routes/regions.js'
import TarifsRoutes from './routes/tarifs.js'
import SupportsRoutes from './routes/supports.js'
import SeoServicesRoutes from './routes/seo_services.js'
import NewsRoutes from './routes/news.js'
import ChannelsRoutes from './routes/channels.js'

Vue.use(VueRouter)

const routes = [
  ...CompaniesRoutes,
  ...DevicesRoutes,
  ...GeneralServiceRoutes,
  ...ProvidersRoutes,
  ...LocalitiesRoutes,
  ...SitesRoutes,
  ...RegionsRoutes,
  ...TarifsRoutes,
  ...SupportsRoutes,
  ...SeoServicesRoutes,
  ...NewsRoutes,
  ...ChannelsRoutes,
  {
    path: '/signin',
    name: 'signin',
    component: SignIn,
    beforeEnter: authGuardSuccess
  },
  {
    path: '/copy-locality',
    name: 'copy_locality',
    component: CopyLocality,
    beforeEnter: authGuardFail
  },
  {
    path: '/seo',
    name: 'seo',
    component: Seo,
    beforeEnter: authGuardFail
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: Reviews,
    beforeEnter: authGuardFail
  },
  {
    path: '*',
    name: 'page-not-found',
    component: PageNotFound,
    beforeEnter: authGuardFail
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
