<template>
  <div style="white-space: nowrap">
    <v-icon small class="mr-2" @click="editItem(item)" :disabled="disabled" title="Редактировать">mdi-pencil</v-icon>
    <v-icon small class="mr-2" @click="deleteItem(item)" :disabled="disabled">mdi-delete</v-icon>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        localities: 'tarif_localities/LOCALITIES',
      })
    },
    methods: {
      ...mapActions({
        deleteLocality: 'tarif_localities/DELETE_LOCALITY',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getLocalities: 'tarif_localities/GET_LOCALITIES',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        var meta = this.localities.meta;
        this.deleteLocality({ id: item.id, tarif_id: this.$route.params.id })
            .then(() => {
              this.setSnackbar({message: "Населённый пункт удален"});
              let current_page;
              if (meta.from === meta.to) {
                current_page = meta.current_page - 1;
              } else {
                current_page = meta.current_page;
              }
              let params = { tarif_id: this.$route.params.id, page: current_page, per_page: meta.per_page };
              this.getLocalities(params)
                  .then(() => {
                    this.nonDisabledAndLoading();
                  });
            })
            .catch( e => {
              this.showErrorConflict({response: e.response, obj: this});
            });
      },
    }
  }
</script>
