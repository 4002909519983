<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete
                  v-model="item.tarif_ids"
                  :items="tarifs.data"
                  item-value="id"
                  item-text="name"
                  label="Тариф"
                  :error-messages="errors.tarif_ids"
                  :disabled="loading || tarif_loading"
                  :loading="tarif_loading"
                  :search-input.sync="search"
                  ref="tarif_autocomplete"
                  multiple
                  clearable
                  chips
                  deletable-chips
                  cache-items
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-text="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-text="data.item.provider.name"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import throttle from "@/assets/js/throttle";
export default {
  data () {
    return {
      disabled: false,
      loading: false,
      search: null,
      tarif_loading: false,
      errors: false,
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog/DIALOG',
      tv_box_tarifs: 'tv_box_tarifs/TARIFS',
      tarifs: 'tarifs/TARIFS',
    }),

    item: function () {
      return {
        tarif_ids: [],
      }
    }
  },
  methods: {
    ...mapActions({
      unsetDialog: 'dialog/SET_DIALOG_FALSE',
      disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
      nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      getTvBoxTarifs: 'tv_box_tarifs/GET_TARIFS',
      storeTarif: 'tv_box_tarifs/STORE_TARIF',
      setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
      getTarifs: 'tarifs/GET_TARIFS',
    }),

    closeDialog () {
      this.unsetDialog();
      this.errors = false;
    },

    clearItem () {
      this.item.tarif_ids = null;
    },

    successActions (snackbar) {
      this.setSnackbar(snackbar);
      this.closeDialog();
      this.errors = false;
      this.disabledAndLoading();
      this.getTvBoxTarifs({ tv_box_id: this.$route.params.id, page: this.tv_box_tarifs.meta.current_page, per_page: this.tv_box_tarifs.meta.per_page })
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },

    submitForm () {
      this.disabled = this.loading = true;
      this.item["tv_box_id"] = this.$route.params.id;

      this.storeTarif(this.item)
        .then(() => {
          this.clearItem();
          this.successActions({message: "Тариф(ы) добавлен(ы)"});
        })
        .catch(e => {
          if (e.response) {
            this.errors = e.response.data.errors;
          } else {
            this.setSnackbar({message: "Ошибка сервера", color: "red"});
          }
        })
        .finally(() => {
          this.disabled = this.loading = false;
        });

    }
  },
  watch: {
    search: throttle(function (val) {
      if (val === null || val.length < 3) return;

      this.tarif_loading = true;

      this.getTarifs({
        searchFilter: {
            name: val
        },
        per_page: 100
      })
        .finally(() => {
            this.tarif_loading = false;
        })
    }, 1000)
  }
}
</script>
