<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Приоритет"
                  type="text"
                  v-model="item.rent_priority"
                  :error-messages="errors.rent_priority"
                ></v-text-field>
                <v-text-field
                  label="Кол-во месяцев"
                  type="text"
                  v-model="item.number_of_months"
                  :error-messages="errors.number_of_months"
                ></v-text-field>
                <v-text-field
                  label="Цена"
                  type="text"
                  v-model="item.price"
                  :error-messages="errors.price"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    async mounted () {
      this.disabledAndLoading();
      this.nonDisabledAndLoading();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        locality_tarif_router_rent_prices: 'locality_tarif_router_rent_prices/RENT_PRICES',
      }),
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            rent_priority: item.rent_priority ? item.rent_priority : null,
            number_of_months: item.number_of_months ? item.number_of_months : null,
            price: item.price ? item.price : null,
          }
        }
        else {
          return {
            rent_priority: '',
            number_of_months: '',
            price: '',
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getLocalityTarifRouterRentPrices: 'locality_tarif_router_rent_prices/GET_RENT_PRICES',
        storeRentPrice: 'locality_tarif_router_rent_prices/STORE_RENT_PRICE',
        updateRentPrice: 'locality_tarif_router_rent_prices/UPDATE_RENT_PRICE',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.rent_priority = null;
        this.item.number_of_months = null;
        this.item.price = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getLocalityTarifRouterRentPrices({ locality_id: this.$route.params.locality_id, tarif_id: this.$route.params.tarif_id, router_id: this.$route.params.router_id, rent_id: this.$route.params.id, page: this.locality_tarif_router_rent_prices.meta.current_price_page, per_page: this.locality_tarif_router_rent_prices.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        this.item["locality_id"] = this.$route.params.locality_id;
        this.item["tarif_id"] = this.$route.params.tarif_id;
        this.item["router_id"] = this.$route.params.router_id;
        this.item["rent_id"] = this.$route.params.id;
        if (this.dialog.item) {
          this.item.rent_price_id  = this.item.id;
          this.updateRentPrice(this.item)
            .then(() => {
                this.successActions({message: "Цена на аренду изменена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeRentPrice(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Цена на аренду добавлена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>