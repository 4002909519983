import axios from 'axios'

export default({
    namespaced: true,

    state: {
        seo_services: {
            data: [],
            meta: {
                current_page: null,
                from: null,
                last_page: null,
                path: null,
                per_page: null,
                to: null,
                total: null
            }
        },
        seo_service: null
    },

    getters: {
        SEO_SERVICES (state) {
            return state.seo_services;
        },
        SEO_SERVICE (state) {
            return state.seo_service;
        },
    },

    mutations: {
        SET_SEO_SERVICES (state, data) {
            state.seo_services = data;
        },

        SET_SEO_SERVICE (state, data) {
            state.seo_service = data;
        },

        CLEAR_SEO_SERVICES (state) {
            state.seo_services = {
                data: [],
                meta: {
                    current_page: null,
                    from: null,
                    last_page: null,
                    path: null,
                    per_page: null,
                    to: null,
                    total: null
                }
            }
        },

        CLEAR_SEO_SERVICE (state) {
            state.seo_service = null;
        }
    },

    actions: {
        GET_SEO_SERVICES: async (context, params) => {
            var params_str;
            if (params) {
                params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                let {data} = await axios.get('/seo-services?' + params_str);
                context.commit('SET_SEO_SERVICES', data);
            } else {
                let {data} = await axios.get('/seo-services');
                context.commit('SET_SEO_SERVICES', data);
            }
        },

        GET_ALL_SEO_SERVICES: async (context) => {
            let {data} = await axios.get('/seo-services?per_page=1000&sort_by=name');
            context.commit('SET_SEO_SERVICES', data);
        },

        GET_SEO_SERVICE: async (context, params) => {
            let {data} = await axios.get('/seo-services/' + params);
            context.commit('SET_SEO_SERVICE', data);
        },

        CLEAR_SEO_SERVICES (context) {
            context.commit('CLEAR_SEO_SERVICES');
        },

        CLEAR_SEO_SERVICE (context) {
            context.commit('CLEAR_SEO_SERVICE');
        },

        STORE_SEO_SERVICE: async (context, params) => {
            await axios.post('/seo-services', params);
        },

        UPDATE_SEO_SERVICE: async (context, params) => {
            await axios.put('/seo-services/' + params.id, params);
        },

        DELETE_SEO_SERVICE: async (context, params) => {
            await axios.delete('/seo-services/' + params.id);
        }
    }
});
