export default({
  namespaced: true,

  state: {
    dialog: {
      status: false,
      item: false,
      mode: ''
    }
  },

  getters: {
    DIALOG (state){
      return state.dialog;
    }
  },

  mutations: {
    SET_DIALOG_TRUE (state) {
      state.dialog.status = true;
    },

    SET_DIALOG_FALSE (state) {
      state.dialog.status = false;
    },

    SET_DIALOG_ITEM (state, item) {
      state.dialog.item = item;
    },

    RESET_DIALOG_ITEM (state) {
      state.dialog.item = false;
    },

    SET_DIALOG_MODE (state, mode) {
      state.dialog.mode = mode;
    },

    RESET_DIALOG_MODE(state) {
      state.dialog.mode = '';
    },
  },

  actions: {
    SET_DIALOG_TRUE ({ commit }, item) {
      commit('SET_DIALOG_TRUE')
      if (item) {
        commit('SET_DIALOG_ITEM', item)
      }
    },

    SET_DIALOG_MODE ({ commit }, mode) {
      commit('SET_DIALOG_MODE', mode)
    },

    SET_DIALOG_FALSE ({ commit }) {
      commit('SET_DIALOG_FALSE')
      commit('RESET_DIALOG_ITEM')
      commit('RESET_DIALOG_MODE')
    }
  }
});
