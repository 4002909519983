<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete v-if="!dialog.item"
                  v-model="item.address_id"
                  :item-text="item => `${item.city}, ${item.street}, ${item.house}`"
                  item-value="id"
                  :items="addresses.data"
                  label="Адрес"
                  :error-messages="errors.address_id"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-html="[data.item.city, data.item.street, data.item.house].join(', ')"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.provider.name"></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-chip>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="[data.item.city, data.item.street, data.item.house].join(', ')"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.provider.name"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-text-field
                  label="Приоритет"
                  type="text"
                  autofocus
                  v-model="item.priority"
                  :error-messages="errors.priority"
                ></v-text-field>
                <v-checkbox
                  label="Скрытый"
                  v-model="item.hidden"
                  :error-messages="errors.hidden"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getAddresses();
      this.nonDisabledAndLoading();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        locality_addresses: 'locality_addresses/ADDRESSES',
        addresses: 'addresses/ADDRESSES',
      }),
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            address_id: item.name ? item.name : null,
            hidden: item.hidden,
            priority: item.priority,
          }
        }
        else {
          return {
            address_id: '',
            hidden: '',
            priority: '',
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getLocalityAddresses: 'locality_addresses/GET_ADDRESSES',
        storeAddress: 'locality_addresses/STORE_ADDRESS',
        updateAddress: 'locality_addresses/UPDATE_ADDRESS',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getAddresses: 'addresses/GET_ALL_ADDRESSES',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.address_id = null;
        this.item.hidden = null;
        this.item.priority = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getLocalityAddresses({ locality_id: this.$route.params.id, page: this.locality_addresses.meta.current_page, per_page: this.locality_addresses.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (!this.item.hidden) this.item.hidden = null;
        this.item["locality_id"] = this.$route.params.id;
        if (this.dialog.item) {
          this.item.address_id  = this.item.id;
          this.updateAddress(this.item)
            .then(() => {
                this.successActions({message: "Адрес изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeAddress(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Адрес добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>