import axios from 'axios'

export default({
  namespaced: true,

  state: {
    services: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    SERVICES (state){
      return state.services;
    }
  },

  mutations: {
    SET_SERVICES (state, data) {
      state.services = data;
    },

    CLEAR_SERVICES (state) {
      state.services = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_SERVICES: async (context, params) => {
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/services?' + params_str);
        context.commit('SET_SERVICES', data);
      } else {
        let {data} = await axios.get('/services');
        context.commit('SET_SERVICES', data);
      }
    },

    GET_ALL_SERVICES: async (context) => {
      let {data} = await axios.get('/services?per_page=1000&sort_by=name');
      context.commit('SET_SERVICES', data);
    },

    CLEAR_SERVICES (context) {
      context.commit('CLEAR_SERVICES');
    },

    STORE_SERVICE: async (context, params) => {
      await axios.post('/services', params);
    },

    UPDATE_SERVICE: async (context, params) => {
      await axios.put('/services/' + params.id, params);
    },

    DELETE_SERVICE: async (context, params) => {
      await axios.delete('/services/' + params.id);
    }
  }
});
