<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="supports.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.is_hidden`]="{ item }" disabled>
      <div v-if="item.is_hidden"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.is_link`]="{ item }" disabled>
      <div v-if="item.is_link"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="supports.meta.from">
      <TablePagination :items="supports" action="supports/GET_SUPPORTS" :add_params="params" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Supports/components/Top'
  import Actions from '@/components/Supports/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Сайт', value: 'site.url', sortable: false },
          { text: 'Родитель', value: 'parent.title', sortable: false },
          { text: 'Заголовок', value: 'title', sortable: false },
          { text: 'URL', value: 'url', sortable: false },
          { text: 'Скрытый', value: 'is_hidden', sortable: false },
          { text: 'Тип ссылка', value: 'is_link', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
        check: 'mdi-check',
      }
    },
    mounted () {
      this.disabledAndLoading();
      this.getSupports()
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearSupports();
      this.clearFilterDialog();
    },
    computed: {
      ...mapGetters({
        supports: 'supports/SUPPORTS',
        table_loading: 'data_table/TABLE_LOADING',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
      }),
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        return params;
      }
    },
    methods: {
      ...mapActions({
        getSupports: 'supports/GET_SUPPORTS',
        clearSupports: 'supports/CLEAR_SUPPORTS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        clearFilterDialog: 'filter_dialog/CLEAR_FILTER_DIALOG'
      })
    }
  }
</script>
