<template>
  <v-toolbar
    flat
  >
    <v-toolbar-title v-if="site">{{site.data.url}}: Нас. пункты</v-toolbar-title>
    <v-divider
      class="mx-4"
      inset
      vertical
    ></v-divider>
    <v-select
      :value="region_id"
      item-text="name"
      item-value="id"
      :items="regions.data"
      label="Регион"
      clearable
      dense
      solo
      hide-details="true"

      v-on:change="changeRegionThrottle"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Ничего не найдено
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-select>
    <v-spacer></v-spacer>

    <v-btn
      color="primary"
      class="mb-2"
      :disabled="disabled"
      @click.stop="openDialog()"
    >
      Добавить
    </v-btn>

    <Dialog />

  </v-toolbar>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Dialog from '@/components/Sites/relations/Localities/components/Dialog'
  import throttle from "@/assets/js/throttle";

  export default {
    components: {
      Dialog
    },
    mounted () {
      this.getSite(this.$route.params.id);
      this.getRegions({site_id: this.site_id, per_page: 1000});
    },
    destroyed () {
      this.clearSite();
      this.clearRegionID();
    },
    computed: {
      ...mapGetters({
        site: 'sites/SITE',
        disabled: 'data_table/DISABLED',
        regions: 'sites_regions/REGIONS',
        region_id: 'region_id/REGION_ID',
      }),
      site_id: function () {
        return this.$route.params.id;
      },
      changeRegionThrottle() {
        return throttle(this.changeRegion,1000);
      }
    },
    methods: {
      ...mapActions({
        setDialog: 'dialog/SET_DIALOG_TRUE',
        getSite: 'sites/GET_SITE',
        clearSite: 'sites/CLEAR_SITE',
        getRegions: 'sites_regions/GET_REGIONS',
        setRegionID: 'region_id/SET_REGION_ID',
        clearRegionID: 'region_id/CLEAR_REGION_ID',
        getLocalities: 'sites_localities/GET_LOCALITIES',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      }),
      openDialog() {
        this.setDialog();
      },
      changeRegion (region_id) {
        this.disabledAndLoading();
        let params = {site_id: this.site_id};
        if (region_id === undefined) {
          this.setRegionID(null);
        } else {
          this.setRegionID(region_id);
          params.region_id = region_id;
        }
        this.getLocalities(params)
          .then(() => {
            this.nonDisabledAndLoading()
          });
      },
    }
  }
</script>