import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/sass/main.sass"

import axios from 'axios'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

require('@/store/auth/subscriber')
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL + 'api/'

Vue.config.productionTip = false

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})
