<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete
                  v-model="item.tv_box_ids"
                  :items="tv_boxes.data"
                  item-value="id"
                  item-text="name"
                  label="ТВ-приставки"
                  :error-messages="errors.tv_box_ids"
                  :disabled="loading"
                  multiple
                  clearable
                  chips
                  deletable-chips
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-text="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-text="data.item.provider.name"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
      disabled: false,
      loading: false,
      search: null,
      tarif_loading: false,
      errors: false,
    }
  },
  async mounted () {
    this.disabledAndLoading();
    await this.getTvBoxes();
    this.nonDisabledAndLoading();
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog/DIALOG',
      tarif_tv_boxes: 'tarif_tv_boxes/TV_BOXES',
      tv_boxes: 'tv_boxes/TV_BOXES',
    }),

    item: function () {
      return {
        tv_box_ids: [],
      }
    }
  },
  methods: {
    ...mapActions({
      unsetDialog: 'dialog/SET_DIALOG_FALSE',
      disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
      nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      getTarifTvBoxes: 'tarif_tv_boxes/GET_TV_BOXES',
      storeTvBox: 'tarif_tv_boxes/STORE_TV_BOX',
      setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
      getTvBoxes: 'tv_boxes/GET_ALL_TV_BOXES',
    }),

    closeDialog () {
      this.unsetDialog();
      this.errors = false;
    },

    clearItem () {
      this.item.tv_box_ids = null;
    },

    successActions (snackbar) {
      this.setSnackbar(snackbar);
      this.closeDialog();
      this.errors = false;
      this.disabledAndLoading();
      this.getTarifTvBoxes({ tarif_id: this.$route.params.id, page: this.tarif_tv_boxes.meta.current_page, per_page: this.tarif_tv_boxes.meta.per_page })
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },

    submitForm () {
      this.disabled = this.loading = true;
      this.item["tarif_id"] = this.$route.params.id;

      this.storeTvBox(this.item)
        .then(() => {
          this.clearItem();
          this.successActions({message: "ТВ-приставка(и) добавлена(ы)"});
        })
        .catch(e => {
          if (e.response) {
            this.errors = e.response.data.errors;
          } else {
            this.setSnackbar({message: "Ошибка сервера", color: "red"});
          }
        })
        .finally(() => {
          this.disabled = this.loading = false;
        });

    }
  }
}
</script>
