<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="routers.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
    show-expand
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:expanded-item="{ item }">
      <Expanded :item="item"/>
    </template>

    <template v-slot:[`item.support_4g`]="{ item }" disabled>
      <div v-if="item.support_4g"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="routers.meta.from">
      <TablePagination :items="routers" action="routers/GET_ROUTERS" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Devices/Routers/components/Top'
  import Actions from '@/components/Devices/Routers/components/Actions'
  import Expanded from '@/components/Devices/Routers/components/Expanded.vue'

  export default {
    components: {
      Expanded,
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Название', value: 'name', sortable: false },
          { text: 'Технология', value: 'technology', sortable: false },
          { text: 'Диапазон частот', value: 'frequency_range', sortable: false },
          { text: 'Площадь покрытия', value: 'area', sortable: false },
          { text: 'Кол-во антенн', value: 'antennas', sortable: false },
          { text: 'Стандарт Wi-Fi', value: 'standard', sortable: false },
          { text: 'Цвет', value: 'color', sortable: false },
          { text: 'Размер', value: 'size', sortable: false },
          { text: 'Количество LAN', value: 'number_of_lan', sortable: false },
          { text: 'Поддержка 4g', value: 'support_4g', sortable: false },
          { text: 'Провайдер', value: 'provider.name', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false, align: 'right' },
        ],
        check: 'mdi-check'
      }
    },
    mounted () {
      this.disabledAndLoading();
      this.getRouters()
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearRouters();
      this.clearProviders();
    },
    computed: {
      ...mapGetters({
        routers: 'routers/ROUTERS',
        table_loading: 'data_table/TABLE_LOADING',
      })
    },
    methods: {
      ...mapActions({
        getRouters: 'routers/GET_ROUTERS',
        clearRouters: 'routers/CLEAR_ROUTERS',
        clearProviders: 'providers/CLEAR_PROVIDERS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>
