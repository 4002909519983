import axios from 'axios'

export default({
    namespaced: true,

    state: {
        channels_packages: {
            data: [],
            meta: {
                current_page: null,
                from: null,
                last_page: null,
                path: null,
                per_page: null,
                to: null,
                total: null
            }
        },
        channels_package: null
    },

    getters: {
        CHANNELS_PACKAGES (state) {
            return state.channels_packages;
        },
        CHANNELS_PACKAGE (state) {
            return state.channels_package;
        },
    },

    mutations: {
        SET_CHANNELS_PACKAGES (state, data) {
            state.channels_packages = data;
        },

        SET_CHANNELS_PACKAGE (state, data) {
            state.channels_package = data;
        },

        CLEAR_CHANNELS_PACKAGES (state) {
            state.channels_packages = {
                data: [],
                meta: {
                    current_page: null,
                    from: null,
                    last_page: null,
                    path: null,
                    per_page: null,
                    to: null,
                    total: null
                }
            }
        },

        CLEAR_CHANNELS_PACKAGE (state) {
            state.channels_package = null;
        }
    },

    actions: {
        GET_CHANNELS_PACKAGES: async (context, params) => {
            var params_str;
            if (params) {
                params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                let {data} = await axios.get('/channels-packages?' + params_str);
                context.commit('SET_CHANNELS_PACKAGES', data);
            } else {
                let {data} = await axios.get('/channels-packages');
                context.commit('SET_CHANNELS_PACKAGES', data);
            }
        },

        GET_ALL_CHANNELS_PACKAGES: async (context) => {
            let {data} = await axios.get('/channels-packages?per_page=1000&sort_by=name');
            context.commit('SET_CHANNELS_PACKAGES', data);
        },

        GET_CHANNELS_PACKAGE: async (context, params) => {
            let {data} = await axios.get('/channels-packages/' + params);
            context.commit('SET_CHANNELS_PACKAGE', data);
        },

        CLEAR_CHANNELS_PACKAGES (context) {
            context.commit('CLEAR_CHANNELS_PACKAGES');
        },

        CLEAR_CHANNELS_PACKAGE (context) {
            context.commit('CLEAR_CHANNELS_PACKAGE');
        },

        STORE_CHANNELS_PACKAGE: async (context, params) => {
            await axios.post('/channels-packages', params);
        },

        UPDATE_CHANNELS_PACKAGE: async (context, params) => {
            await axios.put('/channels-packages/' + params.id, params);
        },

        COPY_CHANNELS_PACKAGE: async (context, params) => {
            var channels_package_id = params.id;
            delete params.id;
            await axios.post('/channels-packages/' + channels_package_id + '/copy', params);
        },

        DELETE_CHANNELS_PACKAGE: async (context, params) => {
            await axios.delete('/channels-packages/' + params.id);
        }
    }
});
