import axios from 'axios'

export default({
  namespaced: true,

  state: {
    techs: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    TECHS (state){
      return state.techs;
    }
  },

  mutations: {
    SET_TECHS (state, data) {
      state.techs = data;
    },

    CLEAR_TECHS (state) {
      state.techs = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_TECHS: async (context, params) => {
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/techs?' + params_str);
        context.commit('SET_TECHS', data);
      } else {
        let {data} = await axios.get('/techs');
        context.commit('SET_TECHS', data);
      }
    },

    GET_ALL_TECHS: async (context) => {
      let {data} = await axios.get('/techs?per_page=1000&sort_by=name');
      context.commit('SET_TECHS', data);
    },

    CLEAR_TECHS (context) {
      context.commit('CLEAR_TECHS');
    },

    STORE_TECH: async (context, params) => {
      await axios.post('/techs', params);
    },

    UPDATE_TECH: async (context, params) => {
      await axios.put('/techs/' + params.id, params);
    },

    DELETE_TECH: async (context, params) => {
      await axios.delete('/techs/' + params.id);
    }
  }
});
