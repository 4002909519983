import axios from 'axios'

export default({
  namespaced: true,

  state: {
    providers: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    PROVIDERS (state){
      return state.providers;
    }
  },

  mutations: {
    SET_PROVIDERS (state, data) {
      state.providers = data;
    },

    CLEAR_PROVIDERS (state) {
      state.providers = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_PROVIDERS: async (context, params) => {
      var locality_id = params.locality_id;
      delete params.locality_id;
      var params_str;
      params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      let {data} = await axios.get('/localities/' + locality_id + '/providers?' + params_str);
      context.commit('SET_PROVIDERS', data);
    },

    GET_ALL_PROVIDERS: async (context, params) => {
      var locality_id = params.locality_id;
      delete params.locality_id;
      let {data} = await axios.get('/localities/' + locality_id + '/providers?per_page=1000');
      context.commit('SET_PROVIDERS', data);
    },

    GET_PROVIDERS_ON_LOCALITY: async (context, params) => {
      var locality_id = params.locality_id;
      delete params.locality_id;
      let {data} = await axios.get('/localities/' + locality_id + '/providers?per_page=1000');
      context.commit('SET_PROVIDERS', data);
    },

    CLEAR_PROVIDERS (context) {
      context.commit('CLEAR_PROVIDERS');
    },

    STORE_PROVIDER: async (context, params) => {
      var locality_id = params.locality_id;
      delete params.locality_id;
      await axios.post('/localities/' + locality_id + '/providers', params);
    },

    UPDATE_PROVIDER: async (context, params) => {
      var locality_id = params.locality_id;
      var id = params.id;
      delete params.locality_id;
      delete params.id;
      await axios.put('/localities/' + locality_id + '/providers/' + id, params);
    },

    DELETE_PROVIDER: async (context, params) => {
      await axios.delete('/localities/' + params.locality_id + '/providers/' + params.id);
    }
  }
});
