import axios from 'axios'

export default({
    namespaced: true,

    state: {
        channels: {
            data: [],
            meta: {
                current_page: null,
                from: null,
                last_page: null,
                path: null,
                per_page: null,
                to: null,
                total: null
            }
        },
        channel: null
    },

    getters: {
        CHANNELS (state) {
            return state.channels;
        },
        CHANNEL (state) {
            return state.channel;
        },
    },

    mutations: {
        SET_CHANNELS (state, data) {
            state.channels = data;
        },

        SET_CHANNEL (state, data) {
            state.channel = data;
        },

        CLEAR_CHANNELS (state) {
            state.channels = {
                data: [],
                meta: {
                    current_page: null,
                    from: null,
                    last_page: null,
                    path: null,
                    per_page: null,
                    to: null,
                    total: null
                }
            }
        },

        CLEAR_CHANNEL (state) {
            state.channel = null;
        }
    },

    actions: {
        GET_CHANNELS: async (context, params) => {
            var params_str;
            if (params) {
                params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                let {data} = await axios.get('/channels?' + params_str);
                context.commit('SET_CHANNELS', data);
            } else {
                let {data} = await axios.get('/channels');
                context.commit('SET_CHANNELS', data);
            }
        },

        GET_ALL_CHANNELS: async (context) => {
            let {data} = await axios.get('/channels?per_page=1000&sort_by=name');
            context.commit('SET_CHANNELS', data);
        },

        GET_CHANNEL: async (context, params) => {
            let {data} = await axios.get('/channels/' + params);
            context.commit('SET_CHANNEL', data);
        },

        CLEAR_CHANNELS (context) {
            context.commit('CLEAR_CHANNELS');
        },

        CLEAR_CHANNEL (context) {
            context.commit('CLEAR_CHANNEL');
        },

        STORE_CHANNEL: async (context, params) => {
            await axios.post('/channels', params);
        },

        UPDATE_CHANNEL: async (context, params) => {
            await axios.put('/channels/' + params.id, params);
        },

        DELETE_CHANNEL: async (context, params) => {
            await axios.delete('/channels/' + params.id);
        }
    }
});
