import axios from 'axios'

export default({
  namespaced: true,

  state: {
    addresses: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    ADDRESSES (state){
      return state.addresses;
    }
  },

  mutations: {
    SET_ADDRESSES (state, data) {
      state.addresses = data;
    },

    CLEAR_ADDRESSES (state) {
      state.addresses = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_ADDRESSES: async (context, params) => {
      var locality_id = params.locality_id;
      delete params.locality_id;
      var params_str;
      params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      let {data} = await axios.get('/localities/' + locality_id + '/addresses?' + params_str);
      context.commit('SET_ADDRESSES', data);
    },

    CLEAR_ADDRESSES (context) {
      context.commit('CLEAR_ADDRESSES');
    },

    STORE_ADDRESS: async (context, params) => {
      var locality_id = params.locality_id;
      delete params.locality_id;
      await axios.post('/localities/' + locality_id + '/addresses', params);
    },

    UPDATE_ADDRESS: async (context, params) => {
      var locality_id = params.locality_id;
      delete params.locality_id;
      await axios.put('/localities/' + locality_id + '/addresses/' + params.id, params);
    },

    DELETE_ADDRESS: async (context, params) => {
      await axios.delete('/localities/' + params.locality_id + '/addresses/' + params.id);
    }
  }
});
