import { authGuardFail } from '../guards.js'

import Companies from '../../views/Companies/Companies.vue'
import Socials from '../../views/Companies/Socials.vue'

export default [
  {
    path: '/companies',
    name: 'companies',
    component: Companies,
    beforeEnter: authGuardFail
  },
  {
    path: '/socials',
    name: 'socials',
    component: Socials,
    beforeEnter: authGuardFail
  },
]