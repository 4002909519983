import axios from 'axios'

export default({
  namespaced: true,

  state: {
    seo_service_blocks: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    SEO_SERVICE_BLOCKS (state) {
      return state.seo_service_blocks;
    },
  },

  mutations: {
    SET_SEO_SERVICE_BLOCKS (state, data) {
      state.seo_service_blocks = data;
    },

    CLEAR_SEO_SERVICE_BLOCKS (state) {
      state.seo_service_blocks = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    },
  },

  actions: {
    GET_SEO_SERVICE_BLOCKS: async (context, params) => {
      var seo_service_id = params.seo_service_id;
      delete params.seo_service_id;
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/seo-services/' + seo_service_id + '/blocks?' + params_str);
        context.commit('SET_SEO_SERVICE_BLOCKS', data);
      } else {
        let {data} = await axios.get('/seo-services/' + seo_service_id + '/blocks');
        context.commit('SET_SEO_SERVICE_BLOCKS', data);
      }
    },

    CLEAR_SEO_SERVICE_BLOCKS (context) {
      context.commit('CLEAR_SEO_SERVICE_BLOCKS');
    },

    STORE_SEO_SERVICE_BLOCK: async (context, params) => {
      await axios.post('/seo-services/' + params.seo_service_id + '/blocks', params);
    },

    UPDATE_SEO_SERVICE_BLOCK: async (context, params) => {
      await axios.put('/seo-services/' + params.seo_service_id + '/blocks/' + params.id, params);
    },

    DELETE_SEO_SERVICE_BLOCK: async (context, params) => {
      await axios.delete('/seo-services/' + params.seo_service_id + '/blocks/' + params.id);
    }
  }
});
