import axios from 'axios'

export default({
  namespaced: true,

  state: {
    companies: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    COMPANIES (state){
      return state.companies;
    }
  },

  mutations: {
    SET_COMPANIES (state, data) {
      state.companies = data;
    },

    CLEAR_COMPANIES (state) {
      state.companies = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_COMPANIES: async (context, params) => {
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/companies?' + params_str);
        context.commit('SET_COMPANIES', data);
      } else {
        let {data} = await axios.get('/companies');
        context.commit('SET_COMPANIES', data);
      }
    },

    GET_ALL_COMPANIES: async (context) => {
      let {data} = await axios.get('/companies?per_page=1000&sort_by=name');
      context.commit('SET_COMPANIES', data);
    },

    CLEAR_COMPANIES (context) {
      context.commit('CLEAR_COMPANIES');
    },

    STORE_COMPANY: async (context, params) => {
      await axios.post('/companies', params);
    },

    UPDATE_COMPANY: async (context, params) => {
      await axios.put('/companies/' + params.id, params);
    },

    DELETE_COMPANY: async (context, params) => {
      await axios.delete('/companies/' + params.id);
    }
  }
});
