import axios from 'axios'

export default({
  namespaced: true,

  state: {
    services: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    SERVICES (state){
      return state.services;
    }
  },

  mutations: {
    SET_SERVICES (state, data) {
      state.services = data;
    },

    CLEAR_SERVICES (state) {
      state.services = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_SERVICES: async (context, params) => {
      var general_service_id = params.general_service_id;
      delete params.general_service_id;
      var params_str;
      params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      let {data} = await axios.get('/general-services/' + general_service_id + '/services?' + params_str);
      context.commit('SET_SERVICES', data);
    },

    CLEAR_SERVICES (context) {
      context.commit('CLEAR_SERVICES');
    },

    STORE_SERVICE: async (context, params) => {
      var general_service_id = params.general_service_id;
      delete params.general_service_id;
      await axios.post('/general-services/' + general_service_id + '/services', params);
    },

    UPDATE_SERVICE: async (context, params) => {
      var general_service_id = params.general_service_id;
      delete params.general_service_id;
      await axios.put('/general-services/' + general_service_id + '/services/' + params.id, params);
    },

    DELETE_SERVICE: async (context, params) => {
      await axios.delete('/general-services/' + params.general_service_id + '/services/' + params.id);
    }
  }
});
