<template>
  <td colspan="11">
    <div class="expanded">
      <div class="title__black">Логотип: </div>
      <div v-if="logo" class="ml-2">
        <!-- <div class="custom_text">{{ logo }}</div> -->

        <!-- title -->
        <div class="title__wrap">
          <span class="title__grey">title: </span>
          <div v-if="logo.title">{{ logo.title }}</div>
          <div v-else>title не задан😔</div>
        </div>

        <!-- alt -->
        <div class="title__wrap">
          <span class="title__grey">alt: </span>
          <div v-if="logo.alt">{{ logo.alt }}</div>
          <div v-else>alt не задан😔</div>
        </div>

        <!-- svg -->
        <div class="title_image">
          <span class="title__grey">svg: </span>
          <div class="title_image__wrap">
            <v-img
              class="title_image__svg"
              v-if="logo.svg"
              contain
              :src="sites_api + logo.svg"
            ></v-img>
            <div v-else>svg не задан😔</div>
          </div>
        </div>
        <!-- <div class="custom_text">regular.xs: {{ logo.regular.xs }}</div>
        <div class="custom_text">regular.sm: {{ logo.regular.sm }}</div>
        <div class="custom_text">regular.md: {{ logo.regular.md }}</div> -->
        <div class="custom_text">rastr: {{ logo.regular.lg }}</div>
        <!-- <div class="custom_text">hidpi.xs: {{ logo.hidpi.xs }}</div>
        <div class="custom_text">hidpi.sm: {{ logo.hidpi.sm }}</div>
        <div class="custom_text">hidpi.md: {{ logo.hidpi.md }}</div>
        <div class="custom_text">hidpi.lg: {{ logo.hidpi.lg }}</div> -->
      </div>
      <div v-else>Логотип не задан😔</div>
      <!-- <v-icon small class="mr-2" @click="editLogo(item)" :disabled="disabled" title="Редактировать">mdi-pencil</v-icon> -->
    </div>
    <!-- <div class="custom_title_text_wrap">
      <div class="custom_title">Баннер:</div>
      <div class="custom_text">{{ item.banner }}</div>
    </div> -->
  <LogoDialog :logo_dialog="logo_dialog" @closeDialog="closeDialog"/>
  </td>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import LogoDialog from '@/components/Providers/Providers/components/Expanded/LogoDialog'

  export default {
    components: {
      LogoDialog
    },
    props: ["item"],
    data () {
      return {
        logo_dialog: false
      }
    },
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
      }),
      sites_api: function () {
        return process.env.VUE_APP_SITES_API_URL;
      },
      logo: function () {
        if (this.item.logo) {
          let logo = JSON.parse(this.item.logo);
          return logo;
        }
          return null;
      }
    },
    methods: {
      ...mapActions({
        setDialog: 'dialog/SET_DIALOG_TRUE',
      }),
      editLogo () {
        this.logo_dialog = true
      },
      closeDialog () {
        this.logo_dialog = false
      }
    }
  }
</script>