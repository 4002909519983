import { authGuardFail } from '../guards.js'

import Providers from '../../views/Providers/Providers/Providers.vue'
import Logos from '../../views/Providers/Images/Logos.vue'
import Banners from '../../views/Providers/Images/Banners.vue'
import BannersHidpi from '../../views/Providers/Images/BannersHidpi.vue'
import Services from '../../views/Providers/Services.vue'

import GeneralServices from '../../views/Providers/Providers/GeneralServices.vue'
import ProviderBanners from '../../views/Providers/Providers/Banners.vue'

export default [
  {
    path: '/providers',
    name: 'providers',
    component: Providers,
    beforeEnter: authGuardFail
  },
  {
    path: '/services',
    name: 'services',
    component: Services,
    beforeEnter: authGuardFail
  },
  {
    path: '/providers/:id/general-services',
    name: 'provider-general-services',
    component: GeneralServices,
    beforeEnter: authGuardFail
  },
  {
    path: '/providers/:id/banners',
    name: 'provider-banners',
    component: ProviderBanners,
    beforeEnter: authGuardFail
  },
  {
    path: '/logos',
    name: 'logos',
    component: Logos,
    beforeEnter: authGuardFail
  },
  {
    path: '/banners',
    name: 'banners',
    component: Banners,
    beforeEnter: authGuardFail
  },
  {
    path: '/banners-hidpi',
    name: 'banners-hidpi',
    component: BannersHidpi,
    beforeEnter: authGuardFail
  },
];