<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-8" min-width="180px">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>MultiNet</v-toolbar-title>
          </v-toolbar>
          <v-form ref="form" v-model="valid">
            <v-card-text>
              <v-text-field
                id="email"
                label="Email"
                name="email"
                prepend-icon="mdi-email"
                type="text"
                v-model="email"
                :rules="email_rules"
                :disabled="input_disable"
                required
              ></v-text-field>

              <v-text-field
                :type="show_password ? 'text' : 'password'"
                id="password"
                label="Пароль"
                name="password"
                prepend-icon="mdi-lock"
                :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show_password = !show_password"
                v-model="password"
                :rules="password_rules"
                :disabled="input_disable"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn
                color="primary"
                :disabled="!valid"
                type="submit"
                @click="submitLogin"
              >
                Вход
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
        <v-snackbar v-model="snackbar" top>
          {{error_message}}
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  
  data: () => ({
    snackbar: false,
    valid: true,
    email: "",
    email_rules: [
      v => !!v || "Необходимо указать E-mail",
      v => /.+@.+\..+/.test(v) || "Поле E-mail должно быть действительным электронным адресом"
    ],
    password: "",
    password_rules: [
      v => !!v || "Необходимо указать Пароль",
      v => (v && v.length >= 6) || "Пароль должен содержать не менее 6 символов"
    ],
    error_message: "",
    input_disable: false,
    show_password: false,
  }),

  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),

    submitLogin() {
      var app = this;
      app.valid = false;
      app.input_disable = true;
      app.signIn({ email: app.email, password: app.password })
        .then(() => {
          app.$router.replace({
            name: 'sites'
          })
          .catch(e => {
            console.log(e);
          });
        }).catch(e => {
          if (e.response && e.response.status === 401) {
            app.error_message = "Неправильный Email или Пароль";
          } else {
            app.error_message = "Ошибка сервера";
          }
          app.valid = true;
          app.input_disable = false;
          app.snackbar = true;
        })
    }
  },
};
</script>