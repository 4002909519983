<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  label="Название компании"
                  hint="Например: Мультинет"
                  type="text"
                  autofocus
                  v-model="item.name"
                  :error-messages="errors.name"
                ></v-text-field>
                <v-text-field
                  label="Номер телефона"
                  hint="Например: 88001002030"
                  type="text"
                  v-model="item.phone"
                  :error-messages="errors.phone"
                ></v-text-field>
                <v-text-field
                  label="Email"
                  hint="Например: multinet@multinet.su"
                  type="text"
                  v-model="item.email"
                  :error-messages="errors.email"
                ></v-text-field>
                <v-textarea
                  label="О компании"
                  v-model="item.about"
                  :error-messages="errors.about"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  label="Номер для мессенджеров"
                  hint="Например: 88001002030"
                  type="text"
                  v-model="item.messengers_phone"
                  :error-messages="errors.messengers_phone"
                ></v-text-field>
                <v-text-field
                  label="Адрес"
                  hint="Например: г. Хабаровск ул. Тихоокеанская д. 73 ..."
                  type="text"
                  v-model="item.address"
                  :error-messages="errors.address"
                ></v-text-field>
                <v-text-field
                  label="Координаты"
                  hint="Например: 48.503821, 135.037152"
                  type="text"
                  v-model="item.coord"
                  :error-messages="errors.coord"
                ></v-text-field>
                <v-textarea
                  label="Расписание"
                  v-model="item.schedule"
                  :error-messages="errors.schedule"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        companies: 'companies/COMPANIES',
      }),
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            name: item.name,
            phone: item.phone,
            email: item.email,
            messengers_phone: item.messengers_phone,
            address: item.address,
            coord: item.coord,
            about: item.about,
            schedule: item.schedule
          }
        }
        else {
          return {
            name: '',
            phone: '',
            email: '',
            messengers_phone: '',
            address: '',
            coord: '',
            about: '',
            schedule: ''
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getCompanies: 'companies/GET_COMPANIES',
        storeCompany: 'companies/STORE_COMPANY',
        updateCompany: 'companies/UPDATE_COMPANY',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE'
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.name = null;
        this.item.phone = null;
        this.item.email = null;
        this.item.messengers_phone = null;
        this.item.address = null;
        this.item.coord = null;
        this.item.about = null;
        this.item.schedule = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getCompanies({ page: this.companies.meta.current_page, per_page: this.companies.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (this.dialog.item) {
          this.updateCompany(this.item)
            .then(() => {
                this.successActions({message: "Компания изменена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeCompany(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Компания добавлена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>