<template>
  <div class="custom_data_table_footer">
    <div class="pag_select_pages_wrap">
      <v-select
        :disabled="disabled"
        class="custom_v_select"
        :items= rows_counts
        v-model="items.meta.per_page"
        @change="onRowsCountChange"
      >
      </v-select>
      <span class="custom_data_table_footer__pages">{{ items.meta.from }} - {{ items.meta.to }} из {{ items.meta.total }}</span>
    </div>
    <v-pagination
      :disabled="disabled"
      v-model="items.meta.current_page"
      :length="items.meta.last_page"
      :total-visible="8"
      next-icon="mdi-menu-right"
      prev-icon="mdi-menu-left"
      @input="onPageChange"
    ></v-pagination>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    data () {
      return {
        rows_counts: [
          5, 15, 50, 100, 500
        ],
      }
    },
    props: ['items', 'action', 'add_params'],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        table_loading: 'data_table/TABLE_LOADING',
      }),
    },
    methods: {
      ...mapActions({
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      }),

      onPageChange () {
        this.disabledAndLoading();
        var params = {
          page: this.items.meta.current_page,
          per_page: this.items.meta.per_page
        }

        if (this.add_params) {
          for (var key in this.add_params) {
            params[key] = this.add_params[key];
          }
        }

        this.$store.dispatch(this.action, params)
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },
      onRowsCountChange (value) {
        if (value === this.items.meta.per_page) {
          this.disabledAndLoading();
          var params = {
            page: 1,
            per_page: this.items.meta.per_page
          }

          if (this.add_params) {
            for (var key in this.add_params) {
              params[key] = this.add_params[key];
            }
          }
          
          this.$store.dispatch(this.action, params)
            .then(() => {
              this.nonDisabledAndLoading();
            });
        }
      },
    }
  }
</script>