import axios from 'axios'

export default({
  namespaced: true,

  state: {
    categories: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    CATEGORIES (state){
      return state.categories;
    }
  },

  mutations: {
    SET_CATEGORIES (state, data) {
      state.categories = data;
    },

    CLEAR_CATEGORIES (state) {
      state.categories = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_CATEGORIES: async (context, params) => {
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/categories?' + params_str);
        context.commit('SET_CATEGORIES', data);
      } else {
        let {data} = await axios.get('/categories');
        context.commit('SET_CATEGORIES', data);
      }
    },

    GET_ALL_CATEGORIES: async (context) => {
      let {data} = await axios.get('/categories?per_page=1000&sort_by=name');
      context.commit('SET_CATEGORIES', data);
    },

    CLEAR_CATEGORIES (context) {
      context.commit('CLEAR_CATEGORIES');
    },

    STORE_CATEGORY: async (context, params) => {
      await axios.post('/categories', params);
    },

    UPDATE_CATEGORY: async (context, params) => {
      await axios.put('/categories/' + params.id, params);
    },

    DELETE_CATEGORY: async (context, params) => {
      await axios.delete('/categories/' + params.id);
    }
  }
});
