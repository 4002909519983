import axios from 'axios'

export default({
  namespaced: true,

  state: {
    general_services: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    },
    general_service: null
  },

  getters: {
    GENERAL_SERVICES (state) {
      return state.general_services;
    },
    GENERAL_SERVICE (state) {
      return state.general_service;
    }
  },

  mutations: {
    SET_GENERAL_SERVICES (state, data) {
      state.general_services = data;
    },

    SET_GENERAL_SERVICE (state, data) {
      state.general_service = data;
    },

    CLEAR_GENERAL_SERVICES (state) {
      state.general_services = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    },

    CLEAR_GENERAL_SERVICE (state) {
      state.general_service = null
    }
  },

  actions: {
    GET_GENERAL_SERVICES: async (context, params) => {
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/general-services?' + params_str);
        context.commit('SET_GENERAL_SERVICES', data);
      } else {
        let {data} = await axios.get('/general-services');
        context.commit('SET_GENERAL_SERVICES', data);
      }
    },

    GET_ALL_GENERAL_SERVICES: async (context) => {
      let {data} = await axios.get('/general-services?per_page=1000&sort_by=name');
      context.commit('SET_GENERAL_SERVICES', data);
    },

    GET_GENERAL_SERVICE: async (context, params) => {
      let {data} = await axios.get('/general-services/' + params);
      context.commit('SET_GENERAL_SERVICE', data);
    },

    CLEAR_GENERAL_SERVICE (context) {
      context.commit('CLEAR_GENERAL_SERVICE');
    },

    CLEAR_GENERAL_SERVICES (context) {
      context.commit('CLEAR_GENERAL_SERVICES');
    },

    STORE_GENERAL_SERVICE: async (context, params) => {
      await axios.post('/general-services', params);
    },

    UPDATE_GENERAL_SERVICE: async (context, params) => {
      await axios.put('/general-services/' + params.id, params);
    },

    DELETE_GENERAL_SERVICE: async (context, params) => {
      await axios.delete('/general-services/' + params.id);
    }
  }
});
