<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete
                  v-model="item.site_id"
                  auto-select-first
                  item-text="url"
                  item-value="id"
                  :items="sites.data"
                  label="Сайт"
                  :error-messages="errors.site_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                    v-model="item.locality_id"
                    item-text="name"
                    item-value="id"
                    :items="localities.data"
                    label="Нас. пункт"
                    :error-messages="errors.locality_id"
                    :search-input.sync="search"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.region.name"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="item.provider_id"
                  item-text="name"
                  item-value="id"
                  :items="providers.data"
                  label="Провайдер"
                  :error-messages="errors.provider_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="item.building_type_id"
                  item-text="name"
                  item-value="id"
                  :items="building_types.data"
                  auto-select-first
                  label="Тип строения"
                  :error-messages="errors.building_type_id"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Ничего не найдено
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
                <v-autocomplete
                  v-model="item.general_service_id"
                  item-text="name"
                  item-value="id"
                  :items="general_services.data"
                  label="Общая услуга"
                  :error-messages="errors.general_service_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-text-field
                    label="Имя пользователя"
                    type="text"
                    v-model="item.name"
                    :error-messages="errors.name"
                ></v-text-field>
                <v-text-field
                    label="Заголовок"
                    type="text"
                    v-model="item.title"
                    :error-messages="errors.title"
                ></v-text-field>
                <v-textarea
                    label="Текст"
                    type="text"
                    v-model="item.text"
                    :error-messages="errors.text"
                ></v-textarea>

                <v-radio-group
                    label="Характер отзыва:"
                    row
                    mandatory
                    v-model="item.emotion"
                    :error-messages="errors.emotion"
                >
                  <v-radio
                    label="Положительный"
                    :value="1"
                    ></v-radio>
                  <v-radio
                      label="Нейтральный"
                      :value="2"
                  ></v-radio>
                  <v-radio
                      label="Отрицательный"
                      :value="3"
                  ></v-radio>
                </v-radio-group>
                <v-row>
                  <v-col cols="12" sm="12" md="">
                    <v-text-field
                        label="Улица"
                        type="text"
                        v-model="item.street"
                        :error-messages="errors.street"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                        label="Дом"
                        type="text"
                        v-model="item.house"
                        :error-messages="errors.house"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                        label="Приоритет"
                        type="text"
                        v-model="item.priority"
                        :error-messages="errors.priority"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-checkbox
                        label="Selfmade"
                        v-model="item.selfmade"
                        :error-messages="errors.selfmade"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-checkbox
                        label="Скрытый"
                        v-model="item.hidden"
                        :error-messages="errors.hidden"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-checkbox
                        label="Одобрен"
                        v-model="item.approved"
                        :error-messages="errors.approved"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import throttle from "@/assets/js/throttle";
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
        search: null,
      }
    },
    created() {
      this.clearSites();
      this.clearLocalities();
      this.clearProviders();
      this.clearBuildingTypes();
      this.clearGeneralServices();
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getSites();
      await this.getProviders();
      await this.getBuildingTypes();
      await this.getGeneralServices();
      // await this.getLocalities();
      this.nonDisabledAndLoading();
    },
    destroyed () {
      this.clearSites();
      this.clearLocalities();
      this.clearProviders();
      this.clearBuildingTypes();
      this.clearGeneralServices();
    },
    computed: {
      ...mapGetters({
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
        dialog: 'dialog/DIALOG',
        reviews: 'reviews/REVIEWS',
        sites: 'sites/SITES',
        localities: 'localities/LOCALITIES',
        providers: 'providers/PROVIDERS',
        building_types: 'building_types/BUILDING_TYPES',
        general_services: 'general_services/GENERAL_SERVICES',
      }),

      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            name: item.name,
            title: item.title,
            text: item.text,
            emotion: item.emotion,
            selfmade: item.selfmade,
            street: item.street ? item.street : null,
            house: item.house ? item.house : null,
            priority: item.priority ? item.priority : null,
            hidden: item.hidden ? item.hidden : null,
            approved: item.approved ? item.approved : null,
            site_id: item.site.id,
            locality_id: item.locality.id,
            provider_id: item.provider.id,
            building_type_id: item.building_type.id,
            general_service_id: item.general_service ? item.general_service.id : null,
          }
        }
        else {
          return {
            name: '',
            title: '',
            text: '',
            emotion: 1,
            selfmade: 1,
            street: '',
            house: '',
            priority: '',
            hidden: '',
            approved: 1,
            site_id: 1,
            locality_id: '',
            provider_id: '',
            building_type_id: '',
            general_service_id: '',
          }
        }
      },
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        params.page = this.reviews.meta.current_page;
        params.per_page = this.reviews.meta.per_page;
        return params;
      }
    },
    watch: {
      search (val) {
        if(val != null && val.length > 1) {
          this.searchLocalities(val);
        }
      },
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getReviews: 'reviews/GET_REVIEWS',
        storeReview: 'reviews/STORE_REVIEW',
        updateReview: 'reviews/UPDATE_REVIEW',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getSites: 'sites/GET_ALL_SITES',
        getLocalities: 'localities/GET_LOCALITIES',
        getProviders: 'providers/GET_ALL_PROVIDERS',
        getBuildingTypes: 'building_types/GET_ALL_BUILDING_TYPES',
        getGeneralServices: 'general_services/GET_ALL_GENERAL_SERVICES',
        clearSites: 'sites/CLEAR_SITES',
        clearLocalities: 'localities/CLEAR_LOCALITIES',
        clearProviders: 'providers/CLEAR_PROVIDERS',
        clearBuildingTypes: 'building_types/CLEAR_BUILDING_TYPES',
        clearGeneralServices: 'general_services/CLEAR_GENERAL_SERVICES',
      }),

      searchLocalities: throttle(function (val) {
        this.loading = true;
        this.getLocalities({
          searchFilter: {
            name: val
          },
          per_page: 100
        }).then(() => {
          this.loading = false;
        });
      }, 700),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.name = null;
        this.item.title = null;
        this.item.text = null;
        this.item.emotion = 1;
        this.item.selfmade = 1;
        this.item.street = null;
        this.item.house = null;
        this.item.priority = null;
        this.item.hidden = null;
        this.item.approved = 1;
        this.item.site_id = 1;
        this.item.locality_id = null;
        this.item.provider_id = null;
        this.item.building_type_id = null;
        this.item.general_service_id = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getReviews(this.params)
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (!this.item.hidden) this.item.hidden = null;
        if (!this.item.approved) this.item.approved = false;
        if (!this.item.selfmade) this.item.selfmade = null;
        if (this.dialog.item) {
          this.updateReview(this.item)
            .then(() => {
                this.successActions({message: "Отзыв изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeReview(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Отзыв добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>
