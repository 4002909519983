<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="banners.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="banners.meta.from">
      <TablePagination :items="banners" action="provider_banners/GET_BANNERS" :add_params="{provider_id: provider_id}" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Providers/Providers/relations/Banners/components/Top'
  import Actions from '@/components/Providers/Providers/relations/Banners/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Текстовая информация об изображении', value: 'alt', sortable: false },
          { text: 'Изображение', value: 'img', sortable: false },
          { text: 'Мобильное изображение', value: 'img_mobile', sortable: false },
          { text: 'Всплывающая подсказка', value: 'title', sortable: false },
          { text: 'Внешняя ссылка', value: 'url', sortable: false },
          { text: 'Приоритет', value: 'priority', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ]
      }
    },
    mounted () {
      this.disabledAndLoading();
      this.getBanners({provider_id: this.provider_id})
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearBanners();
    },
    computed: {
      ...mapGetters({
        banners: 'provider_banners/BANNERS',
        table_loading: 'data_table/TABLE_LOADING',
      }),
      provider_id: function () {
        return this.$route.params.id;
      }
    },
    methods: {
      ...mapActions({
        getBanners: 'provider_banners/GET_BANNERS',
        clearBanners: 'provider_banners/CLEAR_BANNERS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>