<template>
  <div v-if= schedules>
    <div v-for="(schedule) in JSON.parse(schedules)" v-bind:key="schedule.id" class="schedule">
      <div>
        Дни: {{ schedule.days }}
      </div>
      <div>
        Время: {{ schedule.time }}
      </div>
      <div>
        Доп. информ: {{ schedule.break }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['schedules']
  }
</script>