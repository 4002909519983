<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="seo.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
    show-expand
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:expanded-item="{ item }">
      <Expanded :item="item"/>
    </template>

    <template v-slot:[`item.site`]="{ item }" disabled>
      <Nullable :value="item.site ? item.site.url : null" />
    </template>

    <template v-slot:[`item.provider`]="{ item }" disabled>
      <Nullable :value="item.provider ? item.provider.name : null" />
    </template>

    <template v-slot:[`item.category`]="{ item }" disabled>
      <Nullable :value="item.category ? item.category.name : null" />
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="seo.meta.from">
      <TablePagination :items="seo" action="seo/GET_SEO" :add_params="params" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Seo/components/Top'
  import Expanded from '@/components/Seo/components/Expanded'
  import Actions from '@/components/Seo/components/Actions'
  import Nullable from '@/components/Common/Nullable.vue'

  export default {
    components: {
      Nullable,
      TablePagination,
      Top,
      Expanded,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Сайт', value: 'site', sortable: false },
          { text: 'Провайдер', value: 'provider', sortable: false },
          { text: 'Категория', value: 'category', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
        unknown: 'mdi-help',
      }
    },
    created() {
      this.clearSeo();
    },
    mounted () {
      this.getSeo();
    },
    destroyed () {
      this.clearSeo();
      this.clearFilterDialog();
    },
    computed: {
      ...mapGetters({
        seo: 'seo/SEO',
        table_loading: 'data_table/TABLE_LOADING',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
      }),
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        return params;
      }
    },
    methods: {
      ...mapActions({
        getSeo: 'seo/GET_SEO',
        clearSeo: 'seo/CLEAR_SEO',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        clearFilterDialog: 'filter_dialog/CLEAR_FILTER_DIALOG'
      })
    }
  }
</script>
