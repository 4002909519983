<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Вопрос"
                  type="text"
                  autofocus
                  v-model="item.question"
                  :error-messages="errors.question"
                ></v-text-field>
                <v-textarea
                  label="Ответ"
                  type="text"
                  v-model="item.answer"
                  :error-messages="errors.answer"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        questions_answers: 'questions_answers/QUESTIONS_ANSWERS',
      }),

      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            question: item.question ? item.question : null,
            answer: item.answer ? item.answer : null,
          }
        }
        else {
          return {
            question: '',
            answer: '',
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getQuestionsAnswers: 'questions_answers/GET_QUESTIONS_ANSWERS',
        storeQuestionAnswer: 'questions_answers/STORE_QUESTION_ANSWER',
        updateQuestionAnswer: 'questions_answers/UPDATE_QUESTION_ANSWER',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.question = null;
        this.item.answer = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getQuestionsAnswers({ site_id: this.$route.params.site_id, locality_id: this.$route.params.id, page: this.questions_answers.meta.current_page, per_page: this.questions_answers.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        this.item["site_id"] = this.$route.params.site_id;
        this.item["locality_id"] = this.$route.params.id;
        if (this.dialog.item) {
          this.updateQuestionAnswer(this.item)
            .then(() => {
                this.successActions({message: "Вопрос-ответ изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeQuestionAnswer(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Вопрос-ответ добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>
