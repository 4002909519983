<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="routers.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.free`]="{ item }" disabled>
      <div v-if="item.free"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.gift`]="{ item }" disabled>
      <div v-if="item.gift"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.hidden`]="{ item }" disabled>
      <div v-if="item.hidden"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="routers.meta.from">
      <TablePagination :items="routers" action="locality_tarif_routers/GET_ROUTERS" :add_params="{locality_id: locality_id, provider_id: provider_id, tarif_id: tarif_id}"/>
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Localities/Localities/relations/Providers/relations/Tarifs/relations/Routers/components/Top'
  import Actions from '@/components/Localities/Localities/relations/Providers/relations/Tarifs/relations/Routers/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Название', value: 'name', sortable: false },
          { text: 'Цена', value: 'price', sortable: false },
          { text: 'Зачеркнутая цена', value: 'old_price', sortable: false },
          { text: 'Плата за установку', value: 'install_pay', sortable: false },
          { text: 'Бесплатный', value: 'free', sortable: false },
          { text: 'В подарок', value: 'gift', sortable: false },
          { text: 'Приоритет', value: 'priority', sortable: false },
          { text: 'Скрытый', value: 'hidden', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
        check: 'mdi-check'
      }
    },
    mounted () {
      this.disabledAndLoading();
      this.getRouters({locality_id: this.locality_id, tarif_id: this.tarif_id})
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearRouters();
      this.clearRoutersList();
    },
    computed: {
      ...mapGetters({
        routers: 'locality_tarif_routers/ROUTERS',
        table_loading: 'data_table/TABLE_LOADING',
      }),
      locality_id: function () {
        return this.$route.params.locality_id;
      },
      provider_id: function () {
        return this.$route.params.provider_id;
      },
      tarif_id: function () {
        return this.$route.params.id;
      }
    },
    methods: {
      ...mapActions({
        getRouters: 'locality_tarif_routers/GET_ROUTERS',
        clearRouters: 'locality_tarif_routers/CLEAR_ROUTERS',
        clearRoutersList: 'routers/CLEAR_ROUTERS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>