import { authGuardFail } from '../guards.js'

import SeoServicesCategories from '../../views/SeoServices/Categories.vue'
import SeoServices from '../../views/SeoServices/SeoServices/Services.vue'
import SeoServicesBlocks from '../../views/SeoServices/SeoServices/Blocks.vue'

export default [
  {
    path: '/seo-services-categories',
    name: 'seo-services-categories',
    component: SeoServicesCategories,
    beforeEnter: authGuardFail
  },
  {
    path: '/seo-services',
    name: 'seo-services',
    component: SeoServices,
    beforeEnter: authGuardFail
  },
  {
    path: '/seo-services/:id/blocks',
    name: 'seo-services-blocks',
    component: SeoServicesBlocks,
    beforeEnter: authGuardFail
  },
]
