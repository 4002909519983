<template>
  <v-data-table
      dense
      :loading="table_loading"
      loading-text="Загрузка данных..."
      item-key="id"
      :headers="headers"
      :items.sync="localities.data"
      disable-pagination
      disable-items-per-page
      hide-default-footer
      class="elevation-1"
      no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.region`]="{ item }" disabled>
      <div v-if="item.region && item.region.name.length > 15">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" style="border-bottom: 1px dashed #0000008a">
              {{ item.region.name.substring(0, 15) + '...' }}
            </span>
          </template>
          {{ item.region.name }}
        </v-tooltip>
      </div>
      <div v-else>{{ item.region.name }}</div>
    </template>

    <template v-slot:[`item.name`]="{ item }" disabled>
      <div v-if="item.name.length > 15">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" style="border-bottom: 1px dashed #0000008a">
              {{ item.name.substring(0, 15) + '...' }}
            </span>
          </template>
          {{ item.name }}
        </v-tooltip>
      </div>
      <div v-else>{{ item.name }}</div>
    </template>

    <template v-slot:[`item.action`]="{ item }" disabled>
      <div v-if="item.action && item.action.name" style="display: flex; justify-content: center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon>{{ description_check }}</v-icon>
            </span>
          </template>
          {{ item.action.name }}
        </v-tooltip>
      </div>
    </template>

    <template v-slot:[`item.option`]="{ item }" disabled>
      <div v-if="item.option && item.option.name" style="display: flex; justify-content: center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon>{{ description_check }}</v-icon>
            </span>
          </template>
          {{ item.option.name }}
        </v-tooltip>
      </div>
    </template>

    <template v-slot:[`item.options`]="{ item }" disabled>
      <div
        v-if="item.options && item.options.length"
        style="display: flex; justify-content: center; flex-direction: column; font-size: 12px; gap: 4px;"
      >
          <div v-for="option in item.options" :key="option.id">
            <div v-if="option.name.length > 25">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" style="border-bottom: 1px dashed #0000008a">
                    {{ option.name.substring(0, 25) + '...' }}
                  </span>
                </template>

                {{ option.name }}
              </v-tooltip>
            </div>

            <div v-else>{{ option.name }}</div>
          </div>
      </div>
    </template>

    <template v-slot:[`item.hidden`]="{ item }" disabled>
      <div v-if="item.hidden"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="localities.meta.from">
      <TablePagination :items="localities" action="tarif_localities/GET_LOCALITIES" :add_params="{tarif_id: tarif_id}" />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TablePagination from '@/components/Common/TablePagination'
import Top from '@/components/Tarifs/Tarifs/relations/Localities/components/Top'
import Actions from '@/components/Tarifs/Tarifs/relations/Localities/components/Actions'

export default {
  components: {
    TablePagination,
    Top,
    Actions
  },
  data () {
    return {
      headers: [
        { text: 'Регион', value: 'region', sortable: false },
        { text: 'тип', value: 'locality_type.name', sortable: false },
        { text: 'Нас. пункт', value: 'name', sortable: false },
        { text: 'Скорость', value: 'speed.speed', sortable: false },
        { text: 'Цена', value: 'price', sortable: false },
        { text: 'Старая цена', value: 'old_price', sortable: false },
        { text: 'Стоимость подключения', value: 'podkl_price', sortable: false },
        { text: 'Пакет каналов', value: 'channels_package.name', sortable: false },
        { text: 'Моб. интернет', value: 'mobile', sortable: false },
        { text: 'Акция', value: 'action', sortable: false },
        { text: 'Опция(legacy)', value: 'option', sortable: false },
        { text: 'Опции', value: 'options', sortable: false },
        { text: 'Технология', value: 'tech.name', sortable: false },
        { text: 'Скрытый', value: 'hidden', sortable: false },
        { text: 'Приоритет', value: 'priority', sortable: false },
        { text: 'Действия', value: 'actions', sortable: false },
      ],
      check: 'mdi-check',
      description_check: 'mdi-eye-check-outline',
    }
  },
  async mounted () {
    this.disabledAndLoading();
    await this.getLocalities({tarif_id: this.tarif_id});
    this.nonDisabledAndLoading();
  },
  destroyed () {
    this.clearLocalities();
  },
  computed: {
    ...mapGetters({
      localities: 'tarif_localities/LOCALITIES',
      table_loading: 'data_table/TABLE_LOADING',
      region_id: 'region_id/REGION_ID',
      search: 'search/SEARCH'
    }),
    tarif_id: function () {
      return this.$route.params.id;
    },
    params: function () {
      var params = {};
      if (this.region_id) {
        params.region_id = this.region_id;
      }
      if (this.search) {
        params.searchFilter = {
          "name": this.search
        }
      }
      return params;
    }
  },
  methods: {
    ...mapActions({
      getLocalities: 'tarif_localities/GET_LOCALITIES',
      clearLocalities: 'tarif_localities/CLEAR_LOCALITIES',
      disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
      nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
    })
  }
}
</script>
