<template>
  <div>
    <v-icon small class="mr-2" @click="editItem(item)" :disabled="disabled" title="Редактировать">mdi-pencil</v-icon>
    <v-icon small class="ml-2" @click="deleteItem(item)" :disabled="disabled" title="Удалить">mdi-delete</v-icon>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        seo: 'seo/SEO',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
      }),
      params: function () {
          let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
          params.page = (this.seo.meta.from === this.seo.meta.to) ? this.seo.meta.current_page - 1 : this.seo.meta.current_page;
          params.per_page = this.seo.meta.per_page;
          return params;
      }
    },
    methods: {
      ...mapActions({
        deleteSeo: 'seo/DELETE_SEO',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getSeo: 'seo/GET_SEO',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        this.deleteSeo({ id: item.id })
          .then(() => {
            this.setSnackbar({message: "Сео данные удалены"});
            this.getSeo(this.params)
              .then(() => {
                  this.nonDisabledAndLoading();
              });
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
      },
    }
  }
</script>
