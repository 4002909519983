<template>
  <v-data-table
      dense
      :loading="table_loading"
      loading-text="Загрузка данных..."
      item-key="id"
      :headers="headers"
      :items.sync="tarifs.data"
      disable-pagination
      disable-items-per-page
      hide-default-footer
      class="elevation-1"
      no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.action`]="{ item }" disabled>
      <div v-if="item.action && item.action.name" style="display: flex; justify-content: center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon>{{ description_check }}</v-icon>
            </span>
          </template>
          {{ item.action.name }}
        </v-tooltip>
      </div>
    </template>

    <template v-slot:[`item.option`]="{ item }" disabled>
      <div v-if="item.option && item.option.name" style="display: flex; justify-content: center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon>{{ description_check }}</v-icon>
            </span>
          </template>
          {{ item.option.name }}
        </v-tooltip>
      </div>
    </template>

    <template v-slot:[`item.options`]="{ item }" disabled>
      <div
          v-if="item.options && item.options.length"
          style="display: flex; justify-content: center; flex-direction: column; font-size: 12px; gap: 4px;"
      >
        <div v-for="option in item.options" :key="option.id">
          <div v-if="option.name.length > 25">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" style="border-bottom: 1px dashed #0000008a">
                  {{ option.name.substring(0, 25) + '...' }}
                </span>
              </template>
                {{ option.name }}
              </v-tooltip>
          </div>
          <div v-else>{{ option.name }}</div>
        </div>
      </div>
    </template>

    <template v-slot:[`item.hidden`]="{ item }" disabled>
      <div v-if="item.hidden"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="tarifs.meta.from">
      <TablePagination :items="tarifs" action="locality_tarifs/GET_TARIFS" :add_params="{locality_id: locality_id, provider_id: provider_id}" />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TablePagination from '@/components/Common/TablePagination'
import Top from '@/components/Localities/Localities/relations/Providers/relations/Tarifs/components/Top'
import Actions from '@/components/Localities/Localities/relations/Providers/relations/Tarifs/components/Actions'

export default {
  components: {
    TablePagination,
    Top,
    Actions
  },
  data () {
    return {
      headers: [
        { text: 'Тариф', value: 'name', sortable: false },
        { text: 'Скорость', value: 'speed.speed', sortable: false },
        { text: 'Цена', value: 'price', sortable: false },
        { text: 'Старая цена', value: 'old_price', sortable: false },
        { text: 'Стоим. подкл.', value: 'podkl_price', sortable: false },
        { text: 'Пакет каналов', value: 'channels_package.name', sortable: false },
        { text: 'Моб. интернет', value: 'mobile', sortable: false },
        { text: 'Акция', value: 'action', sortable: false },
        { text: 'Опция(legacy)', value: 'option', sortable: false },
        { text: 'Опции', value: 'options', sortable: false },
        { text: 'Технология', value: 'tech.name', sortable: false },
        { text: 'Категория', value: 'category.name', sortable: true },
        { text: 'Скрытый', value: 'hidden', sortable: false },
        { text: 'Приоритет', value: 'priority', sortable: false },
        { text: 'Действия', value: 'actions', sortable: false },
      ],
      check: 'mdi-check',
      description_check: 'mdi-eye-check-outline',
    }
  },
  async mounted () {
    this.disabledAndLoading();
    await this.getTarifs({locality_id: this.locality_id, provider_id: this.provider_id});
    this.nonDisabledAndLoading();
  },
  destroyed () {
    this.clearTarifs();
  },
  computed: {
    ...mapGetters({
      tarifs: 'locality_tarifs/TARIFS',
      table_loading: 'data_table/TABLE_LOADING',
    }),
    locality_id: function () {
      return this.$route.params.locality_id;
    },
    provider_id: function () {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions({
      getTarifs: 'locality_tarifs/GET_TARIFS',
      clearTarifs: 'locality_tarifs/CLEAR_TARIFS',
      disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
      nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
    })
  }
}
</script>
