import { authGuardFail } from '../guards.js'

import Categories from '../../views/Tarifs/Categories.vue'
import Tarifs from '../../views/Tarifs/Tarifs/Tarifs.vue'
import Actions from '../../views/Tarifs/Actions.vue'
import Options from '../../views/Tarifs/Options.vue'
import Techs from '../../views/Tarifs/Techs.vue'
import Speeds from '../../views/Tarifs/Speeds.vue'

import TarifLocalities from '../../views/Tarifs/Tarifs/Localities.vue'
import TarifRouters from '../../views/Tarifs/Tarifs/Routers.vue'
import TarifTvBoxes from '../../views/Tarifs/Tarifs/TvBoxes.vue'

export default [
  {
    path: '/categories',
    name: 'categories',
    component: Categories,
    beforeEnter: authGuardFail
  },
  {
    path: '/tarifs',
    name: 'tarifs',
    component: Tarifs,
    beforeEnter: authGuardFail
  },
  {
    path: '/tarifs/:id/localities',
    name: 'tarif-localities',
    component: TarifLocalities,
    beforeEnter: authGuardFail
  },
  {
    path: '/tarifs/:id/routers',
    name: 'tarif-routers',
    component: TarifRouters,
    beforeEnter: authGuardFail
  },
  {
    path: '/tarifs/:id/tv-boxes',
    name: 'tarif-tv-boxes',
    component: TarifTvBoxes,
    beforeEnter: authGuardFail
  },
  {
    path: '/actions',
    name: 'actions',
    component: Actions,
    beforeEnter: authGuardFail
  },
  {
    path: '/options',
    name: 'option',
    component: Options,
    beforeEnter: authGuardFail
  },
  {
    path: '/techs',
    name: 'techs',
    component: Techs,
    beforeEnter: authGuardFail
  },
  {
    path: '/speeds',
    name: 'speeds',
    component: Speeds,
    beforeEnter: authGuardFail
  },
]
