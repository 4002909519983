import { authGuardFail } from '../guards.js'

import Routers from '../../views/Devices/Routers/Routers.vue'
import RouterLocalities from '../../views/Devices/Routers/Localities/Localities.vue'
import RouterTarifs from '../../views/Devices/Routers/Tarifs/Tarifs.vue'
import TvBoxes from '../../views/Devices/TvBoxes/TvBoxes.vue'
import TvBoxLocalities from '../../views/Devices/TvBoxes/Localities/Localities.vue'
import TvBoxTarifs from '../../views/Devices/TvBoxes/Tarifs/Tarifs.vue'

export default [
  {
    path: '/routers',
    name: 'routers',
    component: Routers,
    beforeEnter: authGuardFail
  },
  {
    path: '/routers/:id/localities',
    name: 'router-localities',
    component: RouterLocalities,
    beforeEnter: authGuardFail
  },
  {
    path: '/routers/:id/tarifs',
    name: 'router-tarifs',
    component: RouterTarifs,
    beforeEnter: authGuardFail
  },
  {
    path: '/tv-boxes',
    name: 'tv_boxes',
    component: TvBoxes,
    beforeEnter: authGuardFail
  },
  {
      path: '/tv-boxes/:id/localities',
      name: 'tv-box-localities',
      component: TvBoxLocalities,
      beforeEnter: authGuardFail
  },
  {
    path: '/tv-boxes/:id/tarifs',
    name: 'tv-box-tarifs',
    component: TvBoxTarifs,
    beforeEnter: authGuardFail
  },
]
