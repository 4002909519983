<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="rent_prices.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.hidden`]="{ item }" disabled>
      <div v-if="item.hidden"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="rent_prices.meta.from">
      <TablePagination :items="rent_prices" action="locality_tarif_tv_box_rent_prices/GET_RENT_PRICES" :add_params="{locality_id: locality_id, provider_id: provider_id, tarif_id: tarif_id, tv_box_id: tv_box_id, rent_id: rent_id}"/>
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Localities/Localities/relations/Providers/relations/Tarifs/relations/TvBoxes/relations/RentPrices/components/Top'
  import Actions from '@/components/Localities/Localities/relations/Providers/relations/Tarifs/relations/TvBoxes/relations/RentPrices/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Приоритет', value: 'rent_priority', sortable: false },
          { text: 'Кол-во месяцев', value: 'number_of_months', sortable: false },
          { text: 'Цена', value: 'price', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
        check: 'mdi-check'
      }
    },
    mounted () {
      this.disabledAndLoading();
      this.getRentPrices({locality_id: this.locality_id, tarif_id: this.tarif_id, tv_box_id: this.tv_box_id, rent_id: this.rent_id})
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearRentPrices();
    },
    computed: {
      ...mapGetters({
        rent_prices: 'locality_tarif_tv_box_rent_prices/RENT_PRICES',
        table_loading: 'data_table/TABLE_LOADING',
      }),
      locality_id: function () {
        return this.$route.params.locality_id;
      },
      provider_id: function () {
        return this.$route.params.provider_id;
      },
      tarif_id: function () {
        return this.$route.params.tarif_id;
      },
      tv_box_id: function () {
        return this.$route.params.tv_box_id;
      },
      rent_id: function () {
        return this.$route.params.id;
      }
    },
    methods: {
      ...mapActions({
        getRentPrices: 'locality_tarif_tv_box_rent_prices/GET_RENT_PRICES',
        clearRentPrices: 'locality_tarif_tv_box_rent_prices/CLEAR_RENT_PRICES',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>