import axios from 'axios'

export default({
  namespaced: true,

  state: {
    tv_boxes: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    },
    tv_box: null
  },

  getters: {
    TV_BOXES (state){
      return state.tv_boxes;
    },

    TV_BOX (state){
      return state.tv_box;
    }
  },

  mutations: {
    SET_TV_BOXES (state, data) {
      state.tv_boxes = data;
    },

    SET_TV_BOX (state, data) {
      state.tv_box = data;
    },

    CLEAR_TV_BOXES (state) {
      state.tv_boxes = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    },

    CLEAR_TV_BOX (state) {
      state.tv_box = null;
    }
  },

  actions: {
    GET_TV_BOXES: async (context, params) => {
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/tv-boxes?' + params_str);
        context.commit('SET_TV_BOXES', data);
      } else {
        let {data} = await axios.get('/tv-boxes');
        context.commit('SET_TV_BOXES', data);
      }
    },

    GET_TV_BOX: async (context, params) => {
      let {data} = await axios.get('/tv-boxes/' + params);
      context.commit('SET_TV_BOX', data);
    },

    GET_ALL_TV_BOXES: async (context) => {
      let {data} = await axios.get('/tv-boxes?per_page=1000&sort_by=name');
      context.commit('SET_TV_BOXES', data);
    },

    CLEAR_TV_BOXES (context) {
      context.commit('CLEAR_TV_BOXES');
    },

    CLEAR_TV_BOX (context) {
      context.commit('CLEAR_TV_BOX');
    },

    STORE_TV_BOX: async (context, params) => {
      await axios.post('/tv-boxes', params);
    },

    UPDATE_TV_BOX: async (context, params) => {
      await axios.put('/tv-boxes/' + params.id, params);
    },

    DELETE_TV_BOX: async (context, params) => {
      await axios.delete('/tv-boxes/' + params.id);
    }
  }
});
