<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="localities.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.free`]="{ item }" disabled>
      <div v-if="item.free"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.gift`]="{ item }" disabled>
      <div v-if="item.gift"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.hidden`]="{ item }" disabled>
      <div v-if="item.hidden"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="localities.meta.from">
      <TablePagination :items="localities" action="router_localities/GET_LOCALITIES" :add_params="params"/>
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Devices/Routers/relations/Localities/components/Top'
  import Actions from '@/components/Devices/Routers/relations/Localities/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'тип', value: 'locality.locality_type.name', sortable: false },
          { text: 'Нас. пункт', value: 'locality.name', sortable: false },
          { text: 'Цена', value: 'price', sortable: false },
          { text: 'Зачеркнутая цена', value: 'old_price', sortable: false },
          { text: 'Плата за установку', value: 'install_pay', sortable: false },
          { text: 'Аренда', value: 'rent_price', sortable: false },
          { text: 'Рассрочка', value: 'installment_price', sortable: false },
          { text: 'Бесплатный', value: 'free', sortable: false },
          { text: 'В подарок', value: 'gift', sortable: false },
          { text: 'Приоритет', value: 'priority', sortable: false },
          { text: 'Скрытый', value: 'hidden', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
        check: 'mdi-check'
      }
    },
    mounted () {
      this.disabledAndLoading();
      this.getLocalities({router_id: this.router_id})
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearLocalities();
    },
    computed: {
      ...mapGetters({
        localities: 'router_localities/LOCALITIES',
        table_loading: 'data_table/TABLE_LOADING',
        filter_dialog: 'filter_dialog/FILTER_DIALOG'
      }),
      router_id() {
        return this.$route.params.id;
      },
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        params.router_id = this.router_id;
        return params;
      }
    },
    methods: {
      ...mapActions({
        getLocalities: 'router_localities/GET_LOCALITIES',
        clearLocalities: 'router_localities/CLEAR_LOCALITIES',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>
