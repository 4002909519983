<template>
  <v-dialog v-model="filter_dialog.status" @click:outside="closeFilterDialog" @keydown.esc="closeFilterDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Фильтры</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-row align="center">
                  <v-autocomplete
                    v-model="item.provider_id"
                    item-text="name"
                    item-value="id"
                    :items="providers.data"
                    label="Фильтр по провайдеру"
                    clearable
                    :error-messages="errors.provider_id"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Ничего не найдено
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeFilterDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Применить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
        enabled: {
          provider: false,
        }
      }
    },
    computed: {
      ...mapGetters({
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
        channels_packages: 'channels_packages/CHANNELS_PACKAGES',
        providers: 'providers/PROVIDERS',
      }),

      item: function () {
        var item = this.filter_dialog.item;
        if (item) {
          return {
            provider_id: item.provider_id ? item.provider_id : '',
          }
        }
        else {
          return {
            provider_id: '',
          }
        }
      }
    },
    methods: {
      ...mapActions({
        closeFilterDialog: 'filter_dialog/CLOSE_FILTER_DIALOG',
        saveFilterDialog: 'filter_dialog/SET_FILTER_DIALOG_ITEM',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getChannelsPackages: 'channels_packages/GET_CHANNELS_PACKAGES',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE'
      }),

      submitForm () {
        this.setSnackbar({message: "Фильтр применён"});
        this.errors = false;
        let params = Object.assign({}, this.item);
        if (params.provider_id === undefined) params.provider_id = '';
        this.saveFilterDialog(Object.assign({}, params));
        this.closeFilterDialog();
        this.disabledAndLoading();
        params.per_page = this.channels_packages.meta.per_page;
        this.getChannelsPackages(params)
          .then(() => {
            this.nonDisabledAndLoading();
          });
      }

    }
}
</script>
