<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Название тарифа"
                  type="text"
                  autofocus
                  v-model="item.name"
                  :error-messages="errors.name"
                ></v-text-field>
                <v-text-field
                  label="URL"
                  type="text"
                  v-model="item.url"
                  :error-messages="errors.url"
                ></v-text-field>
                <v-text-field
                  label="Описание"
                  type="text"
                  v-model="item.description"
                  :error-messages="errors.description"
                ></v-text-field>
                <v-checkbox
                  label="Для. юр лиц"
                  v-model="item.business"
                  :error-messages="errors.business"
                ></v-checkbox>
                <v-checkbox
                  label="Игровой"
                  v-model="item.game"
                  :error-messages="errors.game"
                ></v-checkbox>
                <v-checkbox
                  label="Акционный"
                  v-model="item.action"
                  :error-messages="errors.action"
                ></v-checkbox>
                <v-text-field
                  label="Время до кот. действ. тариф"
                  type="text"
                  v-model="item.timer"
                  :error-messages="errors.timer"
                ></v-text-field>
                <v-text-field
                  label="Текст около цены"
                  type="text"
                  v-model="item.text_by_price"
                  :error-messages="errors.text_by_price"
                ></v-text-field>
                <v-autocomplete
                  v-model="item.category_id"
                  item-text="name"
                  item-value="id"
                  :items="categories.data"
                  label="Категория"
                  :error-messages="errors.category_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="item.provider_id"
                  item-text="name"
                  item-value="id"
                  :items="providers.data"
                  label="Провайдер"
                  :error-messages="errors.provider_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="item.general_service_id"
                  item-text="name"
                  item-value="id"
                  :items="general_services.data"
                  label="Общая услуга"
                  :error-messages="errors.general_service_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getCategories();
      await this.getProviders();
      await this.getGeneralServices();
      this.nonDisabledAndLoading();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
        tarifs: 'tarifs/TARIFS',
        categories: 'categories/CATEGORIES',
        providers: 'providers/PROVIDERS',
        general_services: 'general_services/GENERAL_SERVICES',
      }),
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            name: item.name,
            url: item.url,
            description: item.description,
            business: item.business,
            game: item.game,
            action: item.action,
            timer: item.timer,
            text_by_price: item.text_by_price,
            category_id: item.category ? item.category.id : null,
            provider_id: item.provider ? item.provider.id : null,
            general_service_id: item.general_service ? item.general_service.id : null,
          }
        }
        else {
          return {
            name: '',
            url: '',
            description: '',
            business: '',
            game: '',
            action: '',
            timer: '',
            text_by_price: '',
            category_id: '',
            provider_id: '',
            general_service_id: '',
          }
        }
      },

      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        params.page = this.tarifs.meta.current_page;
        params.per_page = this.tarifs.meta.per_page;
        return params;
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getTarifs: 'tarifs/GET_TARIFS',
        storeTarif: 'tarifs/STORE_TARIF',
        updateTarif: 'tarifs/UPDATE_TARIF',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getCategories: 'categories/GET_ALL_CATEGORIES',
        getProviders: 'providers/GET_ALL_PROVIDERS',
        getGeneralServices: 'general_services/GET_ALL_GENERAL_SERVICES',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.name = null;
        this.item.url = null;
        this.item.description = null;
        this.item.business = null;
        this.item.game = null;
        this.item.action = null;
        this.item.timer = null;
        this.item.text_by_price = null;
        this.item.category_id = null;
        this.item.provider_id = null;
        this.item.general_service_id = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getTarifs(this.params)
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (!this.item.business) this.item.business = null;
        if (!this.item.game) this.item.game = null;
        if (!this.item.action) this.item.action = null;
        if (this.dialog.item) {
          this.updateTarif(this.item)
            .then(() => {
                this.successActions({message: "Тариф изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeTarif(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Тариф добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>