<template>
  <td colspan="11">
    <div class="custom_title_text_wrap">
      <div class="custom_title">Заголовок: {{ item.title }}</div>
    </div>
    <div class="custom_title_text_wrap">
      <div class="custom_title">Текст: {{ item.text }}</div>
    </div>
  </td>
</template>

<script>
  export default {
    props: ["item"]
  }
</script>