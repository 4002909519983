import axios from 'axios'

export default({
    namespaced: true,

    state: {
        news_categories: {
            data: [],
            meta: {
                current_page: null,
                from: null,
                last_page: null,
                path: null,
                per_page: null,
                to: null,
                total: null
            }
        }
    },

    getters: {
        NEWS_CATEGORIES (state) {
            return state.news_categories;
        }
    },

    mutations: {
        SET_NEWS_CATEGORIES (state, data) {
            state.news_categories = data;
        },

        CLEAR_NEWS_CATEGORIES (state) {
            state.news_categories = {
                data: [],
                meta: {
                    current_page: null,
                    from: null,
                    last_page: null,
                    path: null,
                    per_page: null,
                    to: null,
                    total: null
                }
            }
        }
    },

    actions: {
        GET_NEWS_CATEGORIES: async (context, params) => {
            let news_id = params.news_id;
            delete params.news_id;
            var params_str;
            params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
            let {data} = await axios.get('/news/'+ news_id +'/categories?' + params_str);
            context.commit('SET_NEWS_CATEGORIES', data);
        },

        CLEAR_NEWS_CATEGORIES (context) {
            context.commit('CLEAR_NEWS_CATEGORIES');
        },

        STORE_NEWS_CATEGORY: async (context, params) => {
            let news_id = params.news_id;
            delete params.news_id;
            await axios.post('/news/'+ news_id +'/categories', params);
        },

        UPDATE_NEWS_CATEGORY: async (context, params) => {
            let news_id = params.news_id;
            delete params.news_id;
            await axios.put('/news/'+ news_id +'/categories/' + params.id, params);
        },

        DELETE_NEWS_CATEGORY: async (context, params) => {
            await axios.delete('/news/'+ params.news_id +'/categories/' + params.id);
        }
    }
});
