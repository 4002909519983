import store from '@/store'

function authGuardSuccess (to, from, next) {
  if (store.getters['auth/authenticated']) {
    return next({
      name: 'sites'
    })
  }
  next()
}

function authGuardFail (to, from, next) {
  if (!store.getters['auth/authenticated']) {
    return next({
      name: 'signin'
    })
  }
  next()
}

export { authGuardSuccess, authGuardFail };