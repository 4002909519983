import axios from 'axios'

export default({
    namespaced: true,

    state: {
        resolutions: {
            data: [],
            meta: {
                current_page: null,
                from: null,
                last_page: null,
                path: null,
                per_page: null,
                to: null,
                total: null
            }
        },
        resolution: null
    },

    getters: {
        RESOLUTIONS (state) {
            return state.resolutions;
        },
        RESOLUTION (state) {
            return state.resolution;
        },
    },

    mutations: {
        SET_RESOLUTIONS (state, data) {
            state.resolutions = data;
        },

        SET_RESOLUTION (state, data) {
            state.resolution = data;
        },

        CLEAR_RESOLUTIONS (state) {
            state.resolutions = {
                data: [],
                meta: {
                    current_page: null,
                    from: null,
                    last_page: null,
                    path: null,
                    per_page: null,
                    to: null,
                    total: null
                }
            }
        },

        CLEAR_RESOLUTION (state) {
            state.resolution = null;
        }
    },

    actions: {
        GET_RESOLUTIONS: async (context, params) => {
            var params_str;
            if (params) {
                params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                let {data} = await axios.get('/resolutions?' + params_str);
                context.commit('SET_RESOLUTIONS', data);
            } else {
                let {data} = await axios.get('/resolutions');
                context.commit('SET_RESOLUTIONS', data);
            }
        },

        GET_ALL_RESOLUTIONS: async (context) => {
            let {data} = await axios.get('/resolutions?per_page=1000&sort_by=name');
            context.commit('SET_RESOLUTIONS', data);
        },

        GET_RESOLUTION: async (context, params) => {
            let {data} = await axios.get('/resolutions/' + params);
            context.commit('SET_RESOLUTION', data);
        },

        CLEAR_RESOLUTIONS (context) {
            context.commit('CLEAR_RESOLUTIONS');
        },

        CLEAR_RESOLUTION (context) {
            context.commit('CLEAR_RESOLUTION');
        },

        STORE_RESOLUTION: async (context, params) => {
            await axios.post('/resolutions', params);
        },

        UPDATE_RESOLUTION: async (context, params) => {
            await axios.put('/resolutions/' + params.id, params);
        },

        DELETE_RESOLUTION: async (context, params) => {
            await axios.delete('/resolutions/' + params.id);
        }
    }
});
