<template>
  <td colspan="11">
    <div class="custom_title_text_wrap">
      <div class="custom_title">О компании:</div>
      <div class="custom_text">{{ item.about }}</div>
    </div>
    <div class="custom_title_text_wrap">
      <div class="custom_title">Расписание:</div>
      <Schedule :schedules="item.schedule"/>
    </div>
  </td>
</template>

<script>
  import Schedule from '@/components/Companies/Companies/components/Expanded/components/Schedule'

  export default {
    components: {
      Schedule
    },
    props: ["item"]
  }
</script>