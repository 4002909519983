<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete v-if="!dialog.item"
                  v-model="item.locality_ids"
                  :items="localities.data"
                  item-value="id"
                  item-text="name"
                  label="Нас. пункт"
                  :error-messages="errors.locality_ids"
                  :loading="locality_loading"
                  :disabled="loading || locality_loading"
                  :search-input.sync="search"
                  ref="locality_autocomplete"
                  multiple
                  clearable
                  chips
                  deletable-chips
                  cache-items
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="[data.item.locality_type.name, data.item.name].join(' ')"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-autocomplete v-else
                  v-model="item.locality_id"
                  :items="localities.data"
                  item-value="id"
                  item-text="name"
                  label="Нас. пункт"
                  :error-messages="errors.locality_id"
                  :loading="locality_loading"
                  :disabled="loading || locality_loading"
                  :search-input.sync="search"
                  ref="locality_autocomplete"
                  clearable
                  cache-items
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="[data.item.locality_type.name, data.item.name].join(' ')"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-text-field
                  label="Цена"
                  type="text"
                  v-model="item.price"
                  :error-messages="errors.price"
                ></v-text-field>
                <v-text-field
                  label="Зачеркнутая цена"
                  type="text"
                  v-model="item.old_price"
                  :error-messages="errors.old_price"
                ></v-text-field>
                <v-text-field
                  label="Плата за установку"
                  type="text"
                  v-model="item.install_pay"
                  :error-messages="errors.install_pay"
                ></v-text-field>
                <v-text-field
                  label="Стоимость аренды"
                  type="number"
                  suffix="₽"
                  v-model="item.rent_price"
                  :error-messages="errors.rent_price"
                ></v-text-field>
                <v-textarea
                  rows="2"
                  auto-grow
                  label="Описание к аренде"
                  v-model="item.rent_description"
                  :error-messages="errors.rent_description"
                ></v-textarea>
                <v-text-field
                  label="Стоимость аренды"
                  type="number"
                  suffix="₽"
                  v-model="item.installment_price"
                  :error-messages="errors.installment_price"
                ></v-text-field>
                <v-textarea
                  rows="2"
                  auto-grow
                  label="Описание к аренде"
                  v-model="item.installment_description"
                  :error-messages="errors.installment_description"
                ></v-textarea>
                <v-checkbox
                  label="Бесплатный"
                  v-model="item.free"
                  :error-messages="errors.free"
                ></v-checkbox>
                <v-checkbox
                  label="В подарок"
                  v-model="item.gift"
                  :error-messages="errors.gift"
                ></v-checkbox>
                <v-text-field
                  label="Приоритет"
                  type="text"
                  v-model="item.priority"
                  :error-messages="errors.priority"
                ></v-text-field>
                <v-checkbox
                  label="Скрытый"
                  v-model="item.hidden"
                  :error-messages="errors.hidden"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import throttle from "@/assets/js/throttle";
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        search: null,
        locality_loading: false,
        errors: false,
      }
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
        tv_box_localities: 'tv_box_localities/LOCALITIES',
        localities: 'localities/LOCALITIES',
      }),

      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            locality_id: item.locality.id ? item.locality.id : null,
            price: item.price ? item.price : null,
            old_price: item.old_price ? item.old_price : null,
            install_pay: item.install_pay ? item.install_pay : null,
            rent_price: item.rent_price ? item.rent_price : null,
            rent_description: item.rent_description ? item.rent_description : null,
            installment_price: item.installment_price ? item.installment_price : null,
            installment_description: item.installment_description ? item.installment_description : null,
            free: item.free ? item.free : null,
            gift: item.gift ? item.gift : null,
            priority: item.priority ? item.priority : null,
            hidden: item.hidden ? item.hidden : null,
          }
        }
        else {
          return {
            locality_ids: [],
            price: '',
            old_price: '',
            install_pay: '',
            rent_price: '',
            rent_description: '',
            installment_price: '',
            installment_description: '',
            free: '',
            gift: '',
            priority: '',
            hidden: '',
          }
        }
      },
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        params.tv_box_id = this.$route.params.id;
        params.page = this.tv_box_localities.meta.current_page;
        params.per_page = this.tv_box_localities.meta.per_page;
        return params;
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getTvBoxLocalities: 'tv_box_localities/GET_LOCALITIES',
        storeLocality: 'tv_box_localities/STORE_LOCALITY',
        updateLocality: 'tv_box_localities/UPDATE_LOCALITY',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getLocalities: 'localities/GET_LOCALITIES',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.locality_id = null;
        this.item.price = null;
        this.item.old_price = null;
        this.item.install_pay = null;
        this.item.free = null;
        this.item.gift = null;
        this.item.priority = null;
        this.item.hidden = null;
        this.item.locality_ids = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getTvBoxLocalities(this.params)
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (!this.item.free) this.item.free = null;
        if (!this.item.gift) this.item.gift = null;
        if (!this.item.hidden) this.item.hidden = null;
        this.item["tv_box_id"] = this.$route.params.id;
        if (this.dialog.item) {
          this.updateLocality(this.item)
            .then(() => {
                this.successActions({message: "Населённый пункт изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeLocality(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Населённый пункт добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    },
  watch: {
    search: throttle(function (val) {
      console.log(val)
      if (val === null || val.length < 3) return;

      this.locality_loading = true;

      this.getLocalities({
        searchFilter: {
          name: val
        },
        per_page: 100
        })
        .finally(() => {
            this.locality_loading = false;
        })
    }, 1000)
  }
}
</script>
