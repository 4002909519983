<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Наименование роутера"
                  type="text"
                  autofocus
                  v-model="item.name"
                  :error-messages="errors.name"
                ></v-text-field>
                <v-text-field
                  label="Технология"
                  type="text"
                  v-model="item.technology"
                  :error-messages="errors.technology"
                ></v-text-field>
                <v-text-field
                  label="Диапазон частот"
                  type="text"
                  v-model="item.frequency_range"
                  :error-messages="errors.frequency_range"
                ></v-text-field>
                <v-text-field
                  label="Площадь покрытия"
                  type="text"
                  v-model="item.area"
                  :error-messages="errors.area"
                ></v-text-field>
                <v-text-field
                  label="Кол-во антенн"
                  type="text"
                  v-model="item.antennas"
                  :error-messages="errors.antennas"
                ></v-text-field>
                <v-text-field
                  label="Стандарт Wi-Fi"
                  type="text"
                  v-model="item.standard"
                  :error-messages="errors.standard"
                ></v-text-field>
                <v-text-field
                  label="Цвет"
                  type="text"
                  v-model="item.color"
                  :error-messages="errors.color"
                ></v-text-field>
                <v-text-field
                  label="Размер"
                  type="text"
                  v-model="item.size"
                  :error-messages="errors.size"
                ></v-text-field>
                <v-text-field
                  label="Количество LAN"
                  type="text"
                  v-model="item.number_of_lan"
                  :error-messages="errors.number_of_lan"
                ></v-text-field>
                <v-checkbox
                  label="Поддержка 4g"
                  v-model="item.support_4g"
                  :error-messages="errors.support_4g"
                ></v-checkbox>
                <v-text-field
                  label="Бренд"
                  type="text"
                  v-model="item.brand"
                  :error-messages="errors.brand"
                ></v-text-field>
                <v-text-field
                  label="Провайдерский бренд"
                  type="text"
                  v-model="item.provider_brand"
                  :error-messages="errors.provider_brand"
                ></v-text-field>
                <v-autocomplete
                  v-model="item.provider_id"
                  item-text="name"
                  item-value="id"
                  :items="providers.data"
                  label="Провайдер"
                  :error-messages="errors.provider_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getProviders();
      this.nonDisabledAndLoading();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        routers: 'routers/ROUTERS',
        providers: 'providers/PROVIDERS',
      }),
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            name: item.name,
            technology: item.technology,
            frequency_range: item.frequency_range,
            area: item.area,
            antennas: item.antennas,
            standard: item.standard,
            color: item.color,
            size: item.size,
            number_of_lan: item.number_of_lan,
            support_4g: item.support_4g,
            brand: item.brand,
            provider_brand: item.provider_brand,
            provider_id: item.provider ? item.provider.id : null,
          }
        }
        else {
          return {
            name: '',
            technology: '',
            frequency_range: '',
            area: '',
            antennas: '',
            standard: '',
            color: '',
            size: '',
            number_of_lan: '',
            support_4g: '',
            brand: '',
            provider_brand: '',
            provider_id: '',
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getRouters: 'routers/GET_ROUTERS',
        storeRouter: 'routers/STORE_ROUTER',
        updateRouter: 'routers/UPDATE_ROUTER',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getProviders: 'providers/GET_ALL_PROVIDERS',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.name = null;
        this.item.technology = null;
        this.item.frequency_range = null;
        this.item.area = null;
        this.item.antennas = null;
        this.item.standard = null;
        this.item.color = null;
        this.item.size = null;
        this.item.number_of_lan = null;
        this.item.support_4g = null;
        this.item.brand = null;
        this.item.provider_brand = null;
        this.item.provider_id = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getRouters({ page: this.routers.meta.current_page, per_page: this.routers.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (!this.item.support_4g) this.item.support_4g = null;
        if (this.dialog.item) {
          this.updateRouter(this.item)
            .then(() => {
                this.successActions({message: "Роутер изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeRouter(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Роутер добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>