<template>
  <v-dialog v-model="filter_dialog.status" @click:outside="closeFilterDialog" @keydown.esc="closeFilterDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Фильтры</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
<!--                <v-autocomplete-->
<!--                    v-model="item.site_id"-->
<!--                    auto-select-first-->
<!--                    item-text="url"-->
<!--                    item-value="id"-->
<!--                    :items="sites.data"-->
<!--                    label="Фильтр по сайту"-->
<!--                    :error-messages="errors.site_id"-->
<!--                >-->
<!--                  <template v-slot:no-data>-->
<!--                    <v-list-item>-->
<!--                      <v-list-item-title>-->
<!--                        Ничего не найдено-->
<!--                      </v-list-item-title>-->
<!--                    </v-list-item>-->
<!--                  </template>-->
<!--                </v-autocomplete>-->
                <v-row align="center">
                  <v-checkbox
                      v-model="enabled.locality"
                      hide-details
                      class="shrink mr-2 mt-0"
                  ></v-checkbox>
                  <v-autocomplete
                      v-model="item.locality_id"
                      :disabled="!enabled.locality"
                      item-text="name"
                      item-value="id"
                      clearable
                      :items="localities.data"
                      label="Нас. пункт"
                      :error-messages="errors.locality_id"
                      :search-input.sync="search"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Ничего не найдено
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                          <v-list-item-subtitle v-html="data.item.region.name"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-row>
                <v-row align="center">
                  <v-checkbox
                      v-model="enabled.provider"
                      hide-details
                      class="shrink mr-2 mt-0"
                  ></v-checkbox>
                  <v-autocomplete
                      v-model="item.provider_id"
                      :disabled="!enabled.provider"
                      item-text="name"
                      item-value="id"
                      :items="providers.data"
                      label="Провайдер"
                      :error-messages="errors.provider_id"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Ничего не найдено
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-row>
                <v-row align="center">
                  <v-checkbox
                      v-model="enabled.approved"
                      hide-details
                      class="shrink mr-2 mt-0"
                  ></v-checkbox>
<!--                  <v-btn-toggle-->
<!--                      v-model="item.approved"-->
<!--                  >-->
<!--                    <v-btn value="true">-->
<!--                      Одобренно-->
<!--                    </v-btn>-->
<!--                    <v-btn value="false">-->
<!--                      Отклонено-->
<!--                    </v-btn>-->
<!--                    <v-btn value="null">-->
<!--                      Непроверено-->
<!--                    </v-btn>-->
<!--                  </v-btn-toggle>-->
                  <v-radio-group
                      label="По статусу"
                      row
                      mandatory
                      :disabled="!enabled.approved"
                      v-model="item.approved"
                      :error-messages="errors.approved"
                  >
                    <v-radio
                        label="Одобренно"
                        value="1"
                    ></v-radio>
                    <v-radio
                        label="Отклонено"
                        value="false"
                    ></v-radio>
                    <v-radio
                        label="Непроверено"
                        value="null"
                    ></v-radio>
                  </v-radio-group>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeFilterDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Применить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import throttle from "@/assets/js/throttle";
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
        search: null,
        enabled: {
          locality: false,
          provider: false,
          approved: false
        }
      }
    },
    computed: {
      ...mapGetters({
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
        reviews: 'reviews/REVIEWS',
        sites: 'sites/SITES',
        localities: 'localities/LOCALITIES',
        providers: 'providers/PROVIDERS',
        building_types: 'building_types/BUILDING_TYPES',
        general_services: 'general_services/GENERAL_SERVICES',
      }),
      
      item: function () {
        var item = this.filter_dialog.item;
        if (item) {
          return {
            locality_id: item.locality_id && this.enabled.locality ? item.locality_id : '',
            provider_id: item.provider_id && this.enabled.provider ? item.provider_id : '',
            approved: this.enabled.approved ? item.approved : '',
          }
        }
        else {
          return {
            locality_id: '',
            provider_id: '',
            approved: '',
          }
        }
      }
    },
    watch: {
      search (val) {
        if(val != null && val.length > 1) {
          this.searchLocalities(val);
        }
      },
    },
    methods: {
      ...mapActions({
        unsetFilterDialog: 'filter_dialog/SET_FILTER_DIALOG_FALSE',
        closeFilterDialog: 'filter_dialog/CLOSE_FILTER_DIALOG',
        saveFilterDialog: 'filter_dialog/SET_FILTER_DIALOG_ITEM',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getReviews: 'reviews/GET_REVIEWS',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getSites: 'sites/GET_ALL_SITES',
        getLocalities: 'localities/GET_LOCALITIES',
        getProviders: 'providers/GET_ALL_PROVIDERS',
        getBuildingTypes: 'building_types/GET_ALL_BUILDING_TYPES',
        getGeneralServices: 'general_services/GET_ALL_GENERAL_SERVICES',
        clearSites: 'sites/CLEAR_SITES',
        clearLocalities: 'localities/CLEAR_LOCALITIES',
        clearProviders: 'providers/CLEAR_PROVIDERS',
        clearBuildingTypes: 'building_types/CLEAR_BUILDING_TYPES',
        clearGeneralServices: 'general_services/CLEAR_GENERAL_SERVICES',
      }),

      searchLocalities: throttle(function (val) {
        this.loading = true;
        this.getLocalities({
          searchFilter: {
            name: val
          },
          per_page: 100
        }).then(() => {
          this.loading = false;
        });
      }, 700),


      submitForm () {
        this.setSnackbar({message: "Фильтр применён"});
        this.errors = false;
        this.saveFilterDialog(this.item);
        this.closeFilterDialog();
        this.disabledAndLoading();
        let params = this.item;
        params.per_page = this.reviews.meta.per_page;
        this.getReviews(params)
            .then(() => {
              this.nonDisabledAndLoading();
            });
      }

    }
}
</script>