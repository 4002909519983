<template>
  <v-data-table
      dense
      :loading="table_loading"
      loading-text="Загрузка данных..."
      item-key="id"
      :headers="headers"
      :items.sync="providers.data"
      disable-pagination
      disable-items-per-page
      hide-default-footer
      class="elevation-1"
      no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.fake_locality`]="{ item }" disabled>
      <div v-if="item.fake_locality"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.hidden`]="{ item }" disabled>
      <div v-if="item.hidden"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="providers.meta.from">
      <TablePagination :items="providers" action="locality_providers/GET_PROVIDERS" :add_params="{locality_id: locality_id}" />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TablePagination from '@/components/Common/TablePagination'
import Top from '@/components/Localities/Localities/relations/Providers/components/Top'
import Actions from '@/components/Localities/Localities/relations/Providers/components/Actions'

export default {
  components: {
    TablePagination,
    Top,
    Actions
  },
  data () {
    return {
      headers: [
        { text: 'Провайдер', value: 'name', sortable: false },
        { text: 'Фейковый', value: 'fake_locality', sortable: false },
        { text: 'Город донор', value: 'fake_locality.name', sortable: false },
        { text: 'Скрытый', value: 'hidden', sortable: false },
        { text: 'Приоритет', value: 'priority', sortable: false },
        { text: 'Действия', value: 'actions', sortable: false },
      ],
      check: 'mdi-check'
    }
  },
  async mounted () {
    this.clearProviders();
    this.disabledAndLoading();
    await this.getProviders({locality_id: this.locality_id});
    await this.getLocality(this.locality_id);
    await this.getLocalities({region_id: this.locality.data.region.id, per_page: 1000})
    this.nonDisabledAndLoading();
  },
  destroyed () {
    this.clearProviders();
    this.clearLocality();
    this.clearLocalities();
  },
  computed: {
    ...mapGetters({
      providers: 'locality_providers/PROVIDERS',
      table_loading: 'data_table/TABLE_LOADING',
      locality: 'localities/LOCALITY',
    }),
    locality_id: function () {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions({
      getProviders: 'locality_providers/GET_PROVIDERS',
      getLocality: 'localities/GET_LOCALITY',
      getLocalities: 'localities/GET_LOCALITIES',
      clearProviders: 'locality_providers/CLEAR_PROVIDERS',
      clearLocality: 'localities/CLEAR_LOCALITY',
      clearLocalities: 'localities/CLEAR_LOCALITIES',
      disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
      nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
    })
  }
}
</script>