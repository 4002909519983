<template>
  <v-list-group
      v-if="item.children"
      :key="item.text"
      v-model="item.model"
      :prepend-icon="item.model ? item.icon : item['icon-alt']"
      :sub-group="sub"
      append-icon=""
  >
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <tree-list
        v-for="children in item.children"
        :item="children"
        :key="children.text"
        :sub="true"
    ></tree-list>
  </v-list-group>

  <v-list-item
    v-else
    :key="item.text"
    link
    :to="item.link"
  >
      <v-list-item-action>
          <v-icon>
              {{ item.icon }}
          </v-icon>
      </v-list-item-action>

      <v-list-item-content>
          <v-list-item-title >
              {{ item.text }}
          </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "TreeList",
  props: {
    item: {
      type: Object,
      required: true
    },
    sub: {
      type: Boolean,
      default: false
    }
  }
}
</script>