export default({
  namespaced: true,

  state: {
    filter_dialog: {
      status: false,
      item: false
    }
  },

  getters: {
    FILTER_DIALOG (state){
      return state.filter_dialog;
    }
  },

  mutations: {
    SET_FILTER_DIALOG_TRUE (state) {
      state.filter_dialog.status = true;
    },

    TOGGLE_FILTER_DIALOG_STATUS (state) {
      state.filter_dialog.status = !state.filter_dialog.status;
    },

    SET_FILTER_DIALOG_FALSE (state) {
      state.filter_dialog.status = false;
    },

    SET_FILTER_DIALOG_ITEM (state, item) {
      state.filter_dialog.item = item;
    },

    RESET_FILTER_DIALOG_ITEM (state) {
      state.filter_dialog.item = false;
    },
  },

  actions: {
    SET_FILTER_DIALOG_ITEM({ commit }, item) {
      commit('SET_FILTER_DIALOG_ITEM', item)
    },

    CLOSE_FILTER_DIALOG({ commit }) {
      commit('SET_FILTER_DIALOG_FALSE')
    },

    OPEN_FILTER_DIALOG({ commit }) {
      commit('SET_FILTER_DIALOG_TRUE')
    },

    SET_FILTER_DIALOG_TRUE ({ commit }, item) {
      commit('SET_FILTER_DIALOG_TRUE')
      if (item) {
        commit('SET_FILTER_DIALOG_ITEM', item)
      }
    },

    SET_FILTER_DIALOG_FALSE ({ commit }) {
      commit('SET_FILTER_DIALOG_FALSE')
    },

    CLEAR_FILTER_DIALOG ({ commit }) {
        commit('RESET_FILTER_DIALOG_ITEM')
    },
  }
});
