<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete v-if="!dialog.item"
                  v-model="item.category_id"
                  item-text="name"
                  item-value="id"
                  :items="categories.data"
                  label="Категория тарифов"
                  :error-messages="errors.category_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-checkbox
                  label="Скрытый"
                  v-model="item.hidden"
                  :error-messages="errors.hidden"
                ></v-checkbox>
                <v-text-field
                  label="Приоритет"
                  type="text"
                  v-model="item.priority"
                  :error-messages="errors.priority"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getCategories();
      this.nonDisabledAndLoading();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        sites_localities_providers_categories: 'sites_localities_providers_categories/CATEGORIES',
        categories: 'categories/CATEGORIES'
      }),
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            category_id: item.name ? item.name : null,
            hidden: item.hidden ? item.hidden : null,
            priority: item.priority ? item.priority : null,
          }
        }
        else {
          return {
            category_id: '',
            hidden: '',
            priority: ''
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getSitesLocalitiesProvidersCategories: 'sites_localities_providers_categories/GET_CATEGORIES',
        storeCategory: 'sites_localities_providers_categories/STORE_CATEGORY',
        updateCategory: 'sites_localities_providers_categories/UPDATE_CATEGORY',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getCategories: 'categories/GET_ALL_CATEGORIES'
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.category_id = null;
        this.item.hidden = null;
        this.item.priority = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getSitesLocalitiesProvidersCategories({ site_id: this.$route.params.site_id, locality_id: this.$route.params.loc_id, provider_id: this.$route.params.id, page: this.sites_localities_providers_categories.meta.current_page, per_page: this.sites_localities_providers_categories.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (!this.item.hidden) this.item.hidden = null;
        this.item["site_id"] = this.$route.params.site_id;
        this.item["locality_id"] = this.$route.params.loc_id;
        this.item["provider_id"] = this.$route.params.id;
        if (this.dialog.item) {
          this.item.category_id  = this.item.id;
          this.updateCategory(this.item)
            .then(() => {
                this.successActions({message: "Категория изменена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeCategory(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Категория добавлена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>