<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="tarifs.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.name`]="{ item }" disabled>
      <div v-if="item.name && item.name.length > 15">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" style="border-bottom: 1px dashed #0000008a">
              {{ item.name.substring(0, 15) + '...' }}
            </span>
          </template>
          {{ item.name }}
        </v-tooltip>
      </div>
      <div v-else>{{ item.name }}</div>
    </template>

    <template v-slot:[`item.url`]="{ item }" disabled>
      <div v-if="item.url && item.url.length > 10">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" style="border-bottom: 1px dashed #0000008a">
              {{ item.url.substring(0, 10) + '...' }}
            </span>
          </template>
          {{ item.url }}
        </v-tooltip>
      </div>
      <div v-else>{{ item.url }}</div>
    </template>

    <template v-slot:[`item.description`]="{ item }" disabled>
      <div v-if="item.description" style="display: flex; justify-content: center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon>{{ description_check }}</v-icon>
            </span>
          </template>
          {{ item.description }}
        </v-tooltip>
      </div>
    </template>

    <template v-slot:[`item.business`]="{ item }" disabled>
      <div v-if="item.business"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.game`]="{ item }" disabled>
      <div v-if="item.game"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.action`]="{ item }" disabled>
      <div v-if="item.action"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.general_service`]="{ item }" disabled>
      <div v-if="item.general_service && item.general_service.name.length > 15">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" style="border-bottom: 1px dashed #0000008a">
              {{ item.general_service.name.substring(0, 15) + '...' }}
            </span>
          </template>
          {{ item.general_service.name }}
        </v-tooltip>
      </div>
      <div v-else>{{ item.general_service.name }}</div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>


    <template v-slot:footer v-if="tarifs.meta.from">
      <TablePagination :items="tarifs" action="tarifs/GET_TARIFS" :add_params="params" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Tarifs/Tarifs/components/Top'
  import Actions from '@/components/Tarifs/Tarifs/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'ID', value: 'id', sortable: false },
          { text: 'Название', value: 'name', sortable: false },
          { text: 'URL', value: 'url', sortable: false },
          { text: 'Описание', value: 'description', sortable: false },
          { text: 'Для юр. лиц', value: 'business', sortable: false },
          { text: 'Игровой', value: 'game', sortable: false },
          { text: 'Акционный', value: 'action', sortable: false },
          { text: 'Время до которого действует тариф', value: 'timer', sortable: false },
          { text: 'Текст к цене', value: 'text_by_price', sortable: false },
          { text: 'Категория', value: 'category.name', sortable: false },
          { text: 'Провайдер', value: 'provider.name', sortable: false },
          { text: 'Общая услуга', value: 'general_service', sortable: false },
          { text: 'Кол-во городов', value: 'localities_count', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
        check: 'mdi-check',
        description_check: 'mdi-eye-check-outline',
      }
    },
    mounted () {
      this.clearTarifs();
      this.disabledAndLoading();
      this.getTarifs()
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearTarifs();
      this.clearCategories();
      this.clearProviders();
      this.clearGeneralServices();
    },
    computed: {
      ...mapGetters({
        tarifs: 'tarifs/TARIFS',
        table_loading: 'data_table/TABLE_LOADING',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
      }),
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        return params;
      }
    },
    methods: {
      ...mapActions({
        getTarifs: 'tarifs/GET_TARIFS',
        clearTarifs: 'tarifs/CLEAR_TARIFS',
        clearCategories: 'categories/CLEAR_CATEGORIES',
        clearProviders: 'providers/CLEAR_PROVIDERS',
        clearGeneralServices: 'general_services/CLEAR_GENERAL_SERVICES',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>
