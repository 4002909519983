import axios from 'axios'

export default({
    namespaced: true,

    state: {
        genres: {
            data: [],
            meta: {
                current_page: null,
                from: null,
                last_page: null,
                path: null,
                per_page: null,
                to: null,
                total: null
            }
        },
        genre: null
    },

    getters: {
        GENRES (state) {
            return state.genres;
        },
        GENRE (state) {
            return state.genre;
        },
    },

    mutations: {
        SET_GENRES (state, data) {
            state.genres = data;
        },

        SET_GENRE (state, data) {
            state.genre = data;
        },

        CLEAR_GENRES (state) {
            state.genres = {
                data: [],
                meta: {
                    current_page: null,
                    from: null,
                    last_page: null,
                    path: null,
                    per_page: null,
                    to: null,
                    total: null
                }
            }
        },

        CLEAR_GENRE (state) {
            state.genre = null;
        }
    },

    actions: {
        GET_GENRES: async (context, params) => {
            var params_str;
            if (params) {
                params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                let {data} = await axios.get('/genres?' + params_str);
                context.commit('SET_GENRES', data);
            } else {
                let {data} = await axios.get('/genres');
                context.commit('SET_GENRES', data);
            }
        },

        GET_ALL_GENRES: async (context) => {
            let {data} = await axios.get('/genres?per_page=1000&sort_by=name');
            context.commit('SET_GENRES', data);
        },

        GET_GENRE: async (context, params) => {
            let {data} = await axios.get('/genres/' + params);
            context.commit('SET_GENRE', data);
        },

        CLEAR_GENRES (context) {
            context.commit('CLEAR_GENRES');
        },

        CLEAR_GENRE (context) {
            context.commit('CLEAR_GENRE');
        },

        STORE_GENRE: async (context, params) => {
            await axios.post('/genres', params);
        },

        UPDATE_GENRE: async (context, params) => {
            await axios.put('/genres/' + params.id, params);
        },

        DELETE_GENRE: async (context, params) => {
            await axios.delete('/genres/' + params.id);
        }
    }
});
