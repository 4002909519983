import { authGuardFail } from '../guards.js'

import Localities from '../../views/Localities/Localities/Localities.vue'
import LocalityTypes from '../../views/Localities/LocalityTypes.vue'
import Phones from '../../views/Localities/Phones.vue'
import OfficePhones from '../../views/Localities/OfficePhones.vue'
import Addresses from '../../views/Localities/Addresses.vue'

import LocalityAddresses from '../../views/Localities/Localities/Addresses/Addresses.vue'
import Providers from '../../views/Localities/Localities/Providers/Providers.vue'
import Categories from '../../views/Localities/Localities/Providers/Categories/Categories.vue'
import Tarifs from '../../views/Localities/Localities/Providers/Tarifs/Tarifs.vue'
import Options from '../../views/Localities/Localities/Providers/Tarifs/Options/Options.vue'
import Routers from '../../views/Localities/Localities/Providers/Tarifs/Routers/Routers.vue'
import RouterRents from '../../views/Localities/Localities/Providers/Tarifs/Routers/Rents.vue'
import RouterRentPrices from '../../views/Localities/Localities/Providers/Tarifs/Routers/RentPrices.vue'
import TvBoxes from '../../views/Localities/Localities/Providers/Tarifs/TvBoxes/TvBoxes.vue'
import TvBoxRents from '../../views/Localities/Localities/Providers/Tarifs/TvBoxes/Rents.vue'
import TvBoxRentPrices from '../../views/Localities/Localities/Providers/Tarifs/TvBoxes/RentPrices.vue'
import LocalityChannels from '../../views/Localities/Localities/Channels/Channels.vue'

export default [
  {
    path: '/localities',
    name: 'localities',
    component: Localities,
    beforeEnter: authGuardFail
  },
  {
    path: '/locality-types',
    name: 'locality_types',
    component: LocalityTypes,
    beforeEnter: authGuardFail
  },
  {
    path: '/phones',
    name: 'phones',
    component: Phones,
    beforeEnter: authGuardFail
  },
  {
    path: '/office-phones',
    name: 'office_phones',
    component: OfficePhones,
    beforeEnter: authGuardFail
  },
  {
    path: '/addresses',
    name: 'addresses',
    component: Addresses,
    beforeEnter: authGuardFail
  },
  {
    path: '/localities/:id/providers',
    name: 'locality-providers',
    component: Providers,
    beforeEnter: authGuardFail
  },
  {
    path: '/localities/:locality_id/providers/:id/categories',
    name: 'locality-provider-categories',
    component: Categories,
    beforeEnter: authGuardFail
  },
  {
    path: '/localities/:locality_id/providers/:id/tarifs',
    name: 'locality-provider-tarifs',
    component: Tarifs,
    beforeEnter: authGuardFail
  },
  {
    path: '/localities/:locality_id/providers/:provider_id/tarifs/:id/options',
    name: 'locality-provider-tarif-options',
    component: Options,
    beforeEnter: authGuardFail
  },
  {
    path: '/localities/:locality_id/providers/:provider_id/tarifs/:id/routers',
    name: 'locality-provider-tarif-routers',
    component: Routers,
    beforeEnter: authGuardFail
  },
  {
    path: '/localities/:locality_id/providers/:provider_id/tarifs/:tarif_id/routers/:id/rents',
    name: 'locality-provider-tarif-router-rents',
    component: RouterRents,
    beforeEnter: authGuardFail
  },
  {
    path: '/localities/:locality_id/providers/:provider_id/tarifs/:tarif_id/routers/:router_id/rents/:id/prices',
    name: 'locality-provider-tarif-router-rent-prices',
    component: RouterRentPrices,
    beforeEnter: authGuardFail
  },
  {
    path: '/localities/:locality_id/providers/:provider_id/tarifs/:id/tv-boxes',
    name: 'locality-provider-tarif-tv-boxes',
    component: TvBoxes,
    beforeEnter: authGuardFail
  },
  {
    path: '/localities/:locality_id/providers/:provider_id/tarifs/:tarif_id/tv-boxes/:id/rents',
    name: 'locality-provider-tarif-tv-box-rents',
    component: TvBoxRents,
    beforeEnter: authGuardFail
  },
  {
    path: '/localities/:locality_id/providers/:provider_id/tarifs/:tarif_id/tv-boxes/:tv_box_id/rents/:id/prices',
    name: 'locality-provider-tarif-tv-box-rent-prices',
    component: TvBoxRentPrices,
    beforeEnter: authGuardFail
  },
  {
    path: '/localities/:id/addresses',
    name: 'locality-addresses',
    component: LocalityAddresses,
    beforeEnter: authGuardFail
  },
  {
    path: '/localities/:id/channels',
    name: 'locality-channels',
    component: LocalityChannels,
    beforeEnter: authGuardFail
  },
];
