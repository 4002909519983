<template>
  <div>
    <v-icon small class="mr-2" @click="editItem(item)" :disabled="disabled" title="Редактировать">mdi-pencil</v-icon>
    <v-icon small class="mr-2" @click="deleteItem(item)" :disabled="disabled">mdi-delete</v-icon>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        tarifs: 'sites_localities_tarifs/TARIFS',
        provider_id: 'provider_id/PROVIDER_ID',
        category_id: 'category_id/CATEGORY_ID'
      })
    },
    methods: {
      ...mapActions({
        deleteTarif: 'sites_localities_tarifs/DELETE_TARIF',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getTarifs: 'sites_localities_tarifs/GET_TARIFS',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        var meta = this.tarifs.meta;
        this.deleteTarif({ id: item.id, site_id: this.$route.params.site_id, locality_id: this.$route.params.id })
          .then(() => {
            this.setSnackbar({message: "Тариф удален"});
            let current_page;
            if (meta.from === meta.to) {
              current_page = meta.current_page - 1;
            } else {
              current_page = meta.current_page;
            }
            let params = { site_id: this.$route.params.site_id, locality_id: this.$route.params.id, page: current_page, per_page: meta.per_page };
            if (this.provider_id) {
              params.provider_id = this.provider_id;
            }
            if (this.category_id) {
              params.category_id = this.category_id;
            }
            this.getTarifs(params)
              .then(() => {
                this.nonDisabledAndLoading();
              });
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
      },
    }
  }
</script>
