<template>
  <v-dialog v-model="filter_dialog.status" @click:outside="closeFilterDialog" @keydown.esc="closeFilterDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Фильтры</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-row align="center">
                  <v-checkbox
                      v-model="enabled.site"
                      hide-details
                      class="shrink mr-2 mt-0"
                  ></v-checkbox>
                  <v-autocomplete
                      v-model="item.site_id"
                      :disabled="!enabled.site"
                      auto-select-first
                      item-text="url"
                      item-value="id"
                      :items="sites.data"
                      label="Фильтр по сайту"
                      :error-messages="errors.site_id"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Ничего не найдено
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeFilterDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Применить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
        enabled: {
          site: false,
        }
      }
    },
    computed: {
      ...mapGetters({
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
        supports: 'supports/SUPPORTS',
        sites: 'sites/SITES',
      }),
      
      item: function () {
        var item = this.filter_dialog.item;
        if (item) {
          return {
            site_id: item.site_id && this.enabled.site ? item.site_id : '',
          }
        }
        else {
          return {
            site_id: '',
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetFilterDialog: 'filter_dialog/SET_FILTER_DIALOG_FALSE',
        closeFilterDialog: 'filter_dialog/CLOSE_FILTER_DIALOG',
        saveFilterDialog: 'filter_dialog/SET_FILTER_DIALOG_ITEM',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getSupports: 'supports/GET_SUPPORTS',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getSites: 'sites/GET_ALL_SITES',
        clearSites: 'sites/CLEAR_SITES',
      }),

      submitForm () {
        this.setSnackbar({message: "Фильтр применён"});
        this.errors = false;
        this.saveFilterDialog(this.item);
        this.closeFilterDialog();
        this.disabledAndLoading();
        let params = this.item;
        params.per_page = this.supports.meta.per_page;
        this.getSupports(params)
            .then(() => {
              this.nonDisabledAndLoading();
            });
      }

    }
}
</script>