import axios from 'axios'

export default({
  namespaced: true,

  state: {
    routers: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    },
    router: null
  },

  getters: {
    ROUTERS (state){
      return state.routers;
    },

    ROUTER (state){
      return state.router;
    }
  },

  mutations: {
    SET_ROUTERS (state, data) {
      state.routers = data;
    },

    SET_ROUTER (state, data) {
      state.router = data;
    },

    CLEAR_ROUTERS (state) {
      state.routers = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    },

    CLEAR_ROUTER (state) {
      state.router = null;
    }
  },

  actions: {
    GET_ROUTERS: async (context, params) => {
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/routers?' + params_str);
        context.commit('SET_ROUTERS', data);
      } else {
        let {data} = await axios.get('/routers');
        context.commit('SET_ROUTERS', data);
      }
    },

    GET_ROUTER: async (context, params) => {
      let {data} = await axios.get('/routers/' + params);
      context.commit('SET_ROUTER', data);
    },

    GET_ALL_ROUTERS: async (context) => {
      let {data} = await axios.get('/routers?per_page=1000&sort_by=name');
      context.commit('SET_ROUTERS', data);
    },

    CLEAR_ROUTERS (context) {
      context.commit('CLEAR_ROUTERS');
    },

    CLEAR_ROUTER (context) {
      context.commit('CLEAR_ROUTER');
    },

    STORE_ROUTER: async (context, params) => {
      await axios.post('/routers', params);
    },

    UPDATE_ROUTER: async (context, params) => {
      await axios.put('/routers/' + params.id, params);
    },

    DELETE_ROUTER: async (context, params) => {
      await axios.delete('/routers/' + params.id);
    }
  }
});
