<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete
                  v-model="item.site_id"
                  auto-select-first
                  item-text="url"
                  item-value="id"
                  :items="sites.data"
                  label="Сайт"
                  :error-messages="errors.site_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="item.parent_id"
                  item-text="title"
                  item-value="id"
                  :items="full_support.data"
                  label="Родитель"
                  clearable
                  :error-messages="errors.parent_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.title"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.site.url"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-text-field
                    label="Заголовок"
                    type="text"
                    v-model="item.title"
                    :error-messages="errors.url"
                ></v-text-field>
                <vue-editor
                    placeholder="Блок"
                    useCustomImageHandler
                    @image-added="handleImageAdded"
                    :editorToolbar="customToolbar"
                    v-model="item.block"
                >
                </vue-editor>
                <v-checkbox
                    label="Скрытый"
                    v-model="item.is_hidden"
                    :error-messages="errors.is_hidden"
                ></v-checkbox>
                <v-checkbox
                    label="URL"
                    v-model="item.is_link"
                    :error-messages="errors.is_link"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
import { mapGetters, mapActions } from 'vuex'
  export default {
    components: {
      VueEditor
    },
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
        customToolbar: [[{
          header: [false, 1, 2, 3, 4, 5, 6]
        }], ["bold", "italic", "underline", "strike"], // toggled buttons
          [{
            align: ""
          }, {
            align: "center"
          }, {
            align: "right"
          }, {
            align: "justify"
          }], ["blockquote"], [{
            list: "ordered"
          }, {
            list: "bullet"
          }], [{
            indent: "-1"
          }, {
            indent: "+1"
          }], // outdent/indent
          [{
            color: []
          }, {
            background: []
          }], // dropdown with defaults from theme
          ["link", "image"]
        ]
      }
    },
    created() {
      this.clearSites();
      this.clearFullSupport();
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getSites();
      await this.getFullSupport();
      this.nonDisabledAndLoading();
    },
    destroyed () {
      this.clearSites();
    },
    computed: {
      ...mapGetters({
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
        dialog: 'dialog/DIALOG',
        supports: 'supports/SUPPORTS',
        full_support: 'supports/FULL_SUPPORT',
        sites: 'sites/SITES',
      }),

      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            site_id: item.site.id,
            parent_id: item.parent ? item.parent.id : null,
            title: item.title,
            block: item.block,
            is_hidden: item.is_hidden ? item.is_hidden : null,
            is_link: item.is_link
          }
        }
        else {
          return {
            site_id: '',
            parent_id: '',
            title: '',
            block: '',
            is_hidden: '',
            is_link: true,
          }
        }
      },
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        params.page = this.supports.meta.current_page;
        params.per_page = this.supports.meta.per_page;
        return params;
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getSupports: 'supports/GET_SUPPORTS',
        storeSupport: 'supports/STORE_SUPPORT',
        updateSupport: 'supports/UPDATE_SUPPORT',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getSites: 'sites/GET_ALL_SITES',
        getFullSupport: 'supports/GET_FULL_SUPPORT',
        clearSites: 'sites/CLEAR_SITES',
        clearFullSupport: 'supports/CLEAR_FULL_SUPPORT',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.site_id = null;
        this.item.parent_id = null;
        this.item.title = null;
        this.item.block = null;
        this.item.is_hidden = null;
        this.item.is_link = true;
      },

      async successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();

        await this.getSupports(this.params);
        await this.getFullSupport();

        this.nonDisabledAndLoading();
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (!this.item.is_hidden) this.item.is_hidden = null;
        if (this.dialog.item) {
          this.updateSupport(this.item)
            .then(() => {
                this.successActions({message: "Измененения применены"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeSupport(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Добавлено"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      },
      handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
        // An example of using FormData
        // NOTE: Your key could be different such as:
        // formData.append('file', file)

        var formData = new FormData();
        formData.append("image", file);
        axios({
          url: process.env.VUE_APP_BASE_URL + 'api/image/support',
          method: "POST",
          data: formData
        })
            .then(result => {
              const url = result.data;
              Editor.insertEmbed(cursorLocation, "image", url);
              resetUploader();
            })
            .catch(err => {
              console.log(err);
            });
      },
    }
}
</script>
