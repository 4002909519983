import axios from 'axios'

export default({
  namespaced: true,

  state: {
    phones: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    PHONES (state){
      return state.phones;
    }
  },

  mutations: {
    SET_PHONES (state, data) {
      state.phones = data;
    },

    CLEAR_PHONES (state) {
      state.phones = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    },
  },

  actions: {
    GET_PHONES: async (context, params) => {
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/phones?' + params_str);
        context.commit('SET_PHONES', data);
      } else {
        let {data} = await axios.get('/phones');
        context.commit('SET_PHONES', data);
      }
    },

    GET_ALL_PHONES: async (context) => {
      let {data} = await axios.get('/phones?per_page=10000&sort_by=id');
      context.commit('SET_PHONES', data);
    },

    CLEAR_PHONES (context) {
      context.commit('CLEAR_PHONES');
    },

    STORE_PHONE: async (context, params) => {
      await axios.post('/phones', params);
    },

    UPDATE_PHONE: async (context, params) => {
      await axios.put('/phones/' + params.id, params);
    },

    DELETE_PHONE: async (context, params) => {
      await axios.delete('/phones/' + params.id);
    }
  }
});
