<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete v-if="!dialog.item"
                  v-model="item.option_id"
                  item-text="name"
                  item-value="id"
                  :items="options.data"
                  label="Опция"
                  :error-messages="errors.option_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>

                <v-text-field
                  label="Приоритет"
                  type="text"
                  v-model="item.priority"
                  :error-messages="errors.priority"
                />

                <v-checkbox
                  label="Скрытый"
                  v-model="item.hidden"
                  :error-messages="errors.hidden"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },

    async mounted () {
      this.disabledAndLoading();
      await this.getOptions({ provider_id: this.$route.params.provider_id });
      this.nonDisabledAndLoading();
    },

    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        locality_tarif_options: 'locality_tarif_options/OPTIONS',
        options: 'options/OPTIONS',
      }),

      item: function () {
        var item = this.dialog.item;

        if (item) {
          return {
            id: item.id,
            option_id: item.name ? item.name : null,
            priority: item.priority ? item.priority : null,
            hidden: item.hidden ? item.hidden : null,
          }
        } else {
          return {
            option_id: '',
            priority: '',
            hidden: '',
          }
        }
      }
    },

    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',

        getLocalityTarifOptions: 'locality_tarif_options/GET_OPTIONS',
        storeOption: 'locality_tarif_options/STORE_OPTION',
        updateOption: 'locality_tarif_options/UPDATE_OPTION',

        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getOptions: 'options/GET_OPTIONS_ON_PROVIDER',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.option_id = null;
        this.item.priority = null;
        this.item.hidden = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getLocalityTarifOptions({
            locality_id: this.$route.params.locality_id,
            provider_id: this.$route.params.provider_id,
            tarif_id: this.$route.params.id,
            page: this.options.meta.current_page,
            per_page: this.options.meta.per_page
          })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;

        if (!this.item.hidden) this.item.hidden = null;

        this.item["locality_id"] = this.$route.params.locality_id;
        this.item["provider_id"] = this.$route.params.provider_id;
        this.item["tarif_id"] = this.$route.params.id;

        if (this.dialog.item) {
          delete this.item.option_id;

          this.updateOption(this.item)
            .then(() => {
                this.successActions({message: "Опция изменена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        } else {
          this.storeOption(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Опция добавлена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>