import axios from 'axios'

export default({
    namespaced: true,

    state: {
        supports: {
            data: [],
            meta: {
                current_page: null,
                from: null,
                last_page: null,
                path: null,
                per_page: null,
                to: null,
                total: null
            }
        },
        full_support: {
            data: [],
            meta: {
                current_page: null,
                from: null,
                last_page: null,
                path: null,
                per_page: null,
                to: null,
                total: null
            }
        },
        support: null
    },

    getters: {
        SUPPORTS (state) {
            return state.supports;
        },
        FULL_SUPPORT (state) {
            return state.full_support;
        },
        SUPPORT (state) {
            return state.support;
        },
    },

    mutations: {
        SET_SUPPORTS (state, data) {
            state.supports = data;
        },

        SET_FULL_SUPPORT (state, data) {
            state.full_support = data;
        },

        SET_SUPPORT (state, data) {
            state.support = data;
        },

        CLEAR_SUPPORTS (state) {
            state.supports = {
                data: [],
                meta: {
                    current_page: null,
                    from: null,
                    last_page: null,
                    path: null,
                    per_page: null,
                    to: null,
                    total: null
                }
            }
        },

        CLEAR_FULL_SUPPORT (state) {
            state.full_support = {
                data: [],
                meta: {
                    current_page: null,
                    from: null,
                    last_page: null,
                    path: null,
                    per_page: null,
                    to: null,
                    total: null
                }
            }
        },

        CLEAR_SUPPORT (state) {
            state.support = null;
        }
    },

    actions: {
        GET_SUPPORTS: async (context, params) => {
            var params_str;
            if (params) {
                params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                let {data} = await axios.get('/supports?' + params_str);
                context.commit('SET_SUPPORTS', data);
            } else {
                let {data} = await axios.get('/supports');
                context.commit('SET_SUPPORTS', data);
            }
        },

        GET_ALL_SUPPORTS: async (context) => {
            let {data} = await axios.get('/supports?per_page=1000&sort_by=name');
            context.commit('SET_SUPPORTS', data);
        },

        GET_FULL_SUPPORT: async (context) => {
            let {data} = await axios.get('/supports?per_page=1000&sort_by=name');
            context.commit('SET_FULL_SUPPORT', data);
        },

        GET_SUPPORT: async (context, params) => {
            let {data} = await axios.get('/supports/' + params);
            context.commit('SET_SUPPORT', data);
        },

        CLEAR_SUPPORTS (context) {
            context.commit('CLEAR_SUPPORTS');
        },

        CLEAR_SUPPORT (context) {
            context.commit('CLEAR_SUPPORT');
        },

        CLEAR_FULL_SUPPORT (context) {
            context.commit('CLEAR_FULL_SUPPORT');
        },

        STORE_SUPPORT: async (context, params) => {
            await axios.post('/supports', params);
        },

        UPDATE_SUPPORT: async (context, params) => {
            await axios.put('/supports/' + params.id, params);
        },

        DELETE_SUPPORT: async (context, params) => {
            await axios.delete('/supports/' + params.id);
        }
    }
});
