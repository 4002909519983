import axios from 'axios'

export default({
    namespaced: true,

    state: {
        reviews: {
            data: [],
            meta: {
                current_page: null,
                from: null,
                last_page: null,
                path: null,
                per_page: null,
                to: null,
                total: null
            }
        },
        review: null
    },

    getters: {
        REVIEWS (state) {
            return state.reviews;
        },
        REVIEW (state) {
            return state.review;
        }
    },

    mutations: {
        SET_REVIEWS (state, data) {
            state.reviews = data;
        },

        SET_REVIEW (state, data) {
            state.review = data;
        },

        CLEAR_REVIEWS (state) {
            state.reviews = {
                data: [],
                meta: {
                    current_page: null,
                    from: null,
                    last_page: null,
                    path: null,
                    per_page: null,
                    to: null,
                    total: null
                }
            }
        },

        CLEAR_REVIEW (state) {
            state.review = null;
        }
    },

    actions: {
        GET_REVIEWS: async (context, params) => {
            var params_str;
            if (params) {
                params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                let {data} = await axios.get('/reviews?' + params_str);
                context.commit('SET_REVIEWS', data);
            } else {
                let {data} = await axios.get('/reviews');
                context.commit('SET_REVIEWS', data);
            }
        },

        GET_ALL_REVIEWS: async (context) => {
            let {data} = await axios.get('/reviews?per_page=1000&sort_by=name');
            context.commit('SET_REVIEWS', data);
        },

        GET_REVIEW: async (context, params) => {
            let {data} = await axios.get('/reviews/' + params);
            context.commit('SET_REVIEW', data);
        },

        CLEAR_REVIEWS (context) {
            context.commit('CLEAR_REVIEWS');
        },

        CLEAR_REVIEW (context) {
            context.commit('CLEAR_REVIEW');
        },

        STORE_REVIEW: async (context, params) => {
            await axios.post('/reviews', params);
        },

        UPDATE_REVIEW: async (context, params) => {
            await axios.put('/reviews/' + params.id, params);
        },

        DELETE_REVIEW: async (context, params) => {
            await axios.delete('/reviews/' + params.id);
        }
    }
});
