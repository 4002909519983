<template>
  <div>
    <v-icon small class="mr-2" @click="deleteItem(item)" :disabled="disabled">mdi-delete</v-icon>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        providers: 'sites_providers/PROVIDERS',
      })
    },
    methods: {
      ...mapActions({
        deleteProvider: 'sites_providers/DELETE_PROVIDER',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getProviders: 'sites_providers/GET_PROVIDERS',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      openCategories (id) {
        this.$router.push({
          name: 'site-localities-providers-categories', params: { site_id: this.$route.params.site_id, loc_id: this.$route.params.id, id: id }
        })
      },

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        var meta = this.providers.meta;
        this.deleteProvider({ id: item.id, site_id: this.$route.params.id })
          .then(() => {
            this.setSnackbar({message: "Провайдер удален"});
            if(meta.from === meta.to) {
              this.getProviders({ site_id: this.$route.params.id, page: meta.current_page - 1, per_page: meta.per_page })
                .then(() => {
                  this.nonDisabledAndLoading();
                });
            } else {
              this.getProviders({ site_id: this.$route.params.id, page: meta.current_page, per_page: meta.per_page })
                .then(() => {
                  this.nonDisabledAndLoading();
                });
            }
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
      },
    }
  }
</script>
