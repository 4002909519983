import axios from 'axios'

export default({
  namespaced: true,

  state: {
    banners: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    BANNERS (state){
      return state.banners;
    }
  },

  mutations: {
    SET_BANNERS (state, data) {
      state.banners = data;
    },

    CLEAR_BANNERS (state) {
      state.banners = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_BANNERS: async (context, params) => {
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/banners?' + params_str);
        context.commit('SET_BANNERS', data);
      } else {
        let {data} = await axios.get('/banners');
        context.commit('SET_BANNERS', data);
      }
    },

    GET_ALL_BANNERS: async (context) => {
      let {data} = await axios.get('/banners?per_page=1000&sort_by=name');
      context.commit('SET_BANNERS', data);
    },

    CLEAR_BANNERS (context) {
      context.commit('CLEAR_BANNERS');
    },

    STORE_BANNER: async (context, params) => {
      let data = new FormData();

      params.files.forEach(function (file, index) {
        data.append("images["+index+"]", file, file.name);
      })

      return await axios.post('/banners', data);
    },

    UPDATE_BANNER: async (context, params) => {
      await axios.put('/banners/' + params.name, params);
    },

    DELETE_BANNER: async (context, params) => {
      await axios.delete('/banners/' + params.name);
    }
  }
});