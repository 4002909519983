<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="resolutions.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="resolutions.meta.from">
      <TablePagination :items="resolutions" action="resolutions/GET_RESOLUTIONS" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Channels/Resolutions/components/Top'
  import Actions from '@/components/Channels/Resolutions/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Название', value: 'name', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
      }
    },
    mounted () {
      this.disabledAndLoading();
      this.getResolutions()
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearResolutions();
    },
    computed: {
      ...mapGetters({
        resolutions: 'resolutions/RESOLUTIONS',
        table_loading: 'data_table/TABLE_LOADING',
      })
    },
    methods: {
      ...mapActions({
        getResolutions: 'resolutions/GET_RESOLUTIONS',
        clearResolutions: 'resolutions/CLEAR_RESOLUTIONS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>
