import axios from 'axios'

export default({
  namespaced: true,

  state: {
    sites: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    },
    site: null
  },

  getters: {
    SITES (state){
      return state.sites;
    },
    SITE (state){
      return state.site;
    }
  },

  mutations: {
    SET_SITES (state, data) {
      state.sites = data;
    },

    SET_SITE (state, data) {
      state.site = data;
    },

    CLEAR_SITES (state) {
      state.sites = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    },

    CLEAR_SITE (state) {
      state.site = null
    }
  },

  actions: {
    GET_SITES: async (context, params) => {
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/sites?' + params_str);
        context.commit('SET_SITES', data);
      } else {
        let {data} = await axios.get('/sites');
        context.commit('SET_SITES', data);
      }
    },

    GET_ALL_SITES: async (context) => {
      let {data} = await axios.get('/sites?per_page=1000&sort_by=url');
      context.commit('SET_SITES', data);
    },

    GET_SITE: async (context, params) => {
      let {data} = await axios.get('/sites/' + params);
      context.commit('SET_SITE', data);
    },

    CLEAR_SITES (context) {
      context.commit('CLEAR_SITES');
    },

    CLEAR_SITE (context) {
      context.commit('CLEAR_SITE');
    },

    STORE_SITE: async (context, params) => {
      await axios.post('/sites', params);
    },

    UPDATE_SITE: async (context, params) => {
      await axios.put('/sites/' + params.id, params);
    },

    DELETE_SITE: async (context, params) => {
      await axios.delete('/sites/' + params.id);
    }
  }
});
