import axios from 'axios'

export default({
  namespaced: true,

  state: {
    routers: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    ROUTERS (state){
      return state.routers;
    }
  },

  mutations: {
    SET_ROUTERS (state, data) {
      state.routers = data;
    },

    CLEAR_ROUTERS (state) {
      state.routers = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_ROUTERS: async (context, params) => {
      var locality_id = params.locality_id;
      var tarif_id = params.tarif_id;
      delete params.locality_id;
      delete params.tarif_id;
      var params_str;
      params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      let {data} = await axios.get('/localities/' + locality_id + '/tarifs/' + tarif_id + '/routers?' + params_str);
      context.commit('SET_ROUTERS', data);
    },

    CLEAR_ROUTERS (context) {
      context.commit('CLEAR_ROUTERS');
    },

    STORE_ROUTER: async (context, params) => {
      var locality_id = params.locality_id;
      var tarif_id = params.tarif_id;
      delete params.locality_id;
      delete params.tarif_id;
      await axios.post('/localities/' + locality_id + '/tarifs/' + tarif_id + '/routers', params);
    },

    UPDATE_ROUTER: async (context, params) => {
      var locality_id = params.locality_id;
      var tarif_id = params.tarif_id;
      var id = params.id;
      delete params.locality_id;
      delete params.tarif_id;
      delete params.id;
      await axios.put('/localities/' + locality_id + '/tarifs/' + tarif_id + '/routers/' + id, params);
    },

    DELETE_ROUTER: async (context, params) => {
      await axios.delete('/localities/' + params.locality_id + '/tarifs/' + params.tarif_id + '/routers/' + params.id);
    }
  }
});
