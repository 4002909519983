<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete
                  v-model="item.news_category_id"
                  auto-select-first
                  item-text="name"
                  item-value="id"
                  :items="news_categories.data"
                  label="Категория новости"
                  :error-messages="errors.news_category_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    created() {
        this.clearNewsCategories();
    },
    async mounted () {
        this.disabledAndLoading();
        await this.getNewsCategories();
        this.nonDisabledAndLoading();
    },
    destroyed () {
        this.clearNewsCategories();
        this.clearNewsCategoriesNews();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        news_categories: 'news_categories/NEWS_CATEGORIES',
        news_categories_news: 'news_categories_news/NEWS_CATEGORIES',
      }),


      item: function () {
          var item = this.dialog.item;
          if (item) {
              return {
                  id: item.id,
                  news_category_id: item.name ? item.name : null,
              }
          }
          else {
              return {
                  news_category_id: '',
              }
          }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getNewsCategories: 'news_categories/GET_ALL_NEWS_CATEGORIES',
        getNewsCategoriesNews: 'news_categories_news/GET_NEWS_CATEGORIES',
        storeNewsCategory: 'news_categories_news/STORE_NEWS_CATEGORY',
        updateNewsCategory: 'news_categories_news/UPDATE_NEWS_CATEGORY',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        clearNewsCategoriesNews: 'news_categories_news/CLEAR_NEWS_CATEGORIES',
        clearNewsCategories: 'news_categories/CLEAR_NEWS_CATEGORIES',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.news_category_id = null;
      },

      async successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getNewsCategoriesNews({ news_id: this.$route.params.id, page: this.news_categories_news.meta.current_page, per_page: this.news_categories_news.meta.per_page });

        this.nonDisabledAndLoading();
      },

      submitForm () {
        this.disabled = this.loading = true;
        this.item["news_id"] = this.$route.params.id;
        if (this.dialog.item) {
          this.updateNewsCategory(this.item)
            .then(() => {
                this.successActions({message: "Связь новости с категорией изменена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeNewsCategory(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Связь новости с категорией добавлена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      },
    }
}
</script>
