<template>
  <div>
    <v-icon small class="mr-2" @click="openCategories(item.id)" :disabled="disabled" title="Категории новостей">mdi-cards</v-icon>
    <v-icon small class="mr-2" @click="editItem(item)" :disabled="disabled" title="Редактировать">mdi-pencil</v-icon>
    <v-icon small class="ml-2" @click="deleteItem(item)" :disabled="disabled" title="Удалить">mdi-delete</v-icon>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        news: 'news/NEWS',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
      }),
      params: function () {
          let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
          params.page = (this.news.meta.from === this.news.meta.to) ? this.news.meta.current_page - 1 : this.news.meta.current_page;
          params.per_page = this.news.meta.per_page;
          return params;
      }
    },
    methods: {
      ...mapActions({
        deleteNews: 'news/DELETE_NEWS',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getNews: 'news/GET_NEWS',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      editItem (item) {
        this.setDialog(item);
      },
      openCategories (id) {
        this.$router.push({
          name: 'news-news-categories', params: { id }
        })
      },

      deleteItem (item) {
        this.disabledAndLoading();
        this.deleteNews({ id: item.id })
          .then(() => {
            this.setSnackbar({message: "Новость удалена"});
            this.getNews(this.params)
              .then(() => {
                this.nonDisabledAndLoading();
              });
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
      },
    }
  }
</script>
