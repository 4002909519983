<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-row>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-img
                      :src="preview_img"
                      max-width="300"
                      max-height="150"
                      contain
                    >
                      <template v-slot:default>
                        <v-row
                          class="fill-height ma-0"
                          align="start"
                          justify="start"
                        >
                          <v-file-input
                            accept="image/png, image/jpeg, image/jpg"
                            @change="previewImageAdded"
                            prepend-icon="mdi-download"
                            hide-input
                            :error-messages="errors.preview_img"
                          ></v-file-input>
                        </v-row>
                      </template>
                      <template v-slot:placeholder>
                          <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                          >
                              <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                              ></v-progress-circular>
                          </v-row>
                      </template>
                    </v-img>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      v-model="item.site_id"
                      auto-select-first
                      item-text="url"
                      item-value="id"
                      :items="sites.data"
                      label="Сайт"
                      :error-messages="errors.site_id"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Ничего не найдено
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <v-autocomplete
                      v-model="item.news_topic_id"
                      auto-select-first
                      item-text="name"
                      item-value="id"
                      :items="news_topics.data"
                      label="Тема новости"
                      :error-messages="errors.news_topic_id"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Ничего не найдено
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-text-field
                  label="Заголовок"
                  type="text"
                  autofocus
                  v-model="item.title"
                  :error-messages="errors.title"
                ></v-text-field>
                <v-textarea
                  rows="1"
                  auto-grow
                  label="Превью текст"
                  v-model="item.preview_text"
                  :error-messages="errors.preview_text"
                ></v-textarea>

                <vue-editor
                    placeholder="Контент"
                    useCustomImageHandler
                    @image-added="handleImageAdded"
                    :editorToolbar="customToolbar"
                    v-model="item.content"
                >
                </vue-editor>
                <v-text-field
                    label="Дата публикации"
                    type="data"
                    v-model="item.date"
                    :error-messages="errors.date"
                ></v-text-field>
                <v-checkbox
                    label="Скрытый"
                    v-model="item.is_hidden"
                    :error-messages="errors.is_hidden"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
import { mapGetters, mapActions } from 'vuex'
  export default {
    components: {
        VueEditor
    },
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
        preview_img: require(`@/assets/img/no-img.png`),
        customToolbar: [[{
            header: [false, 1, 2, 3, 4, 5, 6]
        }], ["bold", "italic", "underline", "strike"], // toggled buttons
            [{
                align: ""
            }, {
                align: "center"
            }, {
                align: "right"
            }, {
                align: "justify"
            }], ["blockquote"], [{
                list: "ordered"
            }, {
                list: "bullet"
            }], [{
                indent: "-1"
            }, {
                indent: "+1"
            }], // outdent/indent
            [{
                color: []
            }, {
                background: []
            }], // dropdown with defaults from theme
            ["link", "image"]
        ]
      }
    },
    created() {
        this.clearSites();
        this.clearNewsTopics();
    },
    async mounted () {
        this.disabledAndLoading();
        await this.getSites();
        await this.getNewsTopics();
        this.nonDisabledAndLoading();
    },
    destroyed () {
        this.clearSites();
        this.clearNewsTopics();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
        sites: 'sites/SITES',
        news_topics: 'news_topics/NEWS_TOPICS',
        news: 'news/NEWS',
      }),
      item: function () {
        var item = this.dialog.item;
        let t = new Date();
        if (item) {
          return {
            id: item.id,
            site_id: item.site.id,
            news_topic_id: item.news_topic.id,
            title: item.title,
            preview_img: item.preview_img,
            preview_text: item.preview_text,
            content: item.content,
            date: item.date,
            is_hidden: item.is_hidden ? item.is_hidden : null,
          }
        }
        else {
          return {
            id: '',
            site_id: '',
            news_topic_id: '',
            title: '',
            preview_img: null,
            preview_text: '',
            content: '',
            date: t.getFullYear()+'-'+(t.getMonth()+1)+'-'+t.getDate(),
            is_hidden: '',
          }
        }
      },
      params: function () {
          let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
          params.page = this.news.meta.current_page;
          params.per_page = this.news.meta.per_page;
          return params;
      }
    },
    watch: {
      'dialog.item': function () {
        this.preview_img = this.dialog.item ? this.dialog.item.preview_img : require(`@/assets/img/no-img.png`);
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getSites: 'sites/GET_ALL_SITES',
        getNewsTopics: 'news_topics/GET_ALL_NEWS_TOPICS',
        getNews: 'news/GET_NEWS',
        storeNews: 'news/STORE_NEWS',
        updateNews: 'news/UPDATE_NEWS',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        clearSites: 'sites/CLEAR_SITES',
        clearNewsTopics: 'news_topics/CLEAR_NEWS_TOPICS',
        clearNews: 'news/CLEAR_NEWS',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        let t = new Date();
        this.item.site_id = null;
        this.item.news_topic_id = null;
        this.item.title = null;
        this.item.preview_img = null;
        this.item.preview_text = null;
        this.item.content = null;
        this.item.date = t.getFullYear()+'-'+(t.getMonth()+1)+'-'+t.getDate();
        this.item.is_hidden = null;
        this.preview_img = require(`@/assets/img/no-img.png`);
      },

      async successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        await this.getNews(this.params);

        this.nonDisabledAndLoading();
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (this.dialog.item) {
          this.updateNews(this.item)
            .then(() => {
                this.clearItem();
                this.successActions({message: "Новость изменена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeNews(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Новость добавлена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      },
      handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
        // An example of using FormData
        // NOTE: Your key could be different such as:
        // formData.append('file', file)

        var formData = new FormData();
        formData.append("image", file);
        axios({
          url: process.env.VUE_APP_BASE_URL + 'api/image/news/content',
          method: "POST",
          data: formData
        })
          .then(result => {
            const url = result.data;
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch(err => {
            console.log(err);
          });
      },
      previewImageAdded: function(file) {
        if (typeof file === 'object') {
          var formData = new FormData();
          formData.append("image", file);
          axios({
            url: process.env.VUE_APP_BASE_URL + 'api/image/news/preview',
            method: "POST",
            data: formData
          })
            .then(result => {
              this.item.preview_img = result.data;
              this.preview_img = result.data;
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          this.item.preview_img = null;
          this.preview_img = require(`@/assets/img/no-img.png`);
        }
      },
    }
}
</script>
