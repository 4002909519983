export default({
  namespaced: true,

  state: {
    snackbar: {
      status: false,
      message: false,
      color: ''
    }
  },

  getters: {
    SNACKBAR (state){
      return state.snackbar;
    }
  },

  mutations: {
    SET_SNACKBAR_TRUE (state) {
      state.snackbar.status = true;
    },

    SET_SNACKBAR_MESSAGE (state, message) {
      state.snackbar.message = message;
    },

    SET_SNACKBAR_COLOR (state, color) {
      state.snackbar.color = color;
    },
  },

  actions: {
    SET_SNACKBAR_TRUE ({ commit }, params) {
      commit('SET_SNACKBAR_TRUE'),
      commit('SET_SNACKBAR_MESSAGE', params.message)
      commit('SET_SNACKBAR_COLOR', params.color)
    },
  }
});
