<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  label="Название нас. пункта"
                  type="text"
                  autofocus
                  v-model="item.name"
                  :error-messages="errors.name"
                ></v-text-field>
                <v-text-field
                  label="URL"
                  type="text"
                  v-model="item.url"
                  :error-messages="errors.url"
                ></v-text-field>
                <v-text-field
                  label="SypexGeoID"
                  type="text"
                  v-model="item.sypex_geo_id"
                  :error-messages="errors.sypex_geo_id"
                ></v-text-field>
                <v-text-field
                    label="Идентификатор ФИАС нас. пункта"
                    hint="Например: a4859da8-9977-4b62-8436-4e1b98c5d13f"
                    type="text"
                    v-model="item.fias_id"
                    :error-messages="errors.fias_id"
                ></v-text-field>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-checkbox
                      label="Столица региона"
                      v-model="item.capital"
                      :error-messages="errors.capital"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-checkbox
                      label="В списке"
                      v-model="item.in_list"
                      :error-messages="errors.in_list"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <!-- 1 -->
                <v-autocomplete
                  v-model="item.locality_type_id"
                  item-text="name"
                  item-value="id"
                  :items="locality_types.data"
                  label="Тип нас. пункта"
                  :error-messages="errors.locality_type_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <!-- 2 -->
                <v-autocomplete
                  v-model="item.region_id"
                  item-text="name"
                  item-value="id"
                  :items="regions.data"
                  label="Регион"
                  :error-messages="errors.region_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <!-- 3 -->
                <!-- <v-autocomplete
                  v-model="item.region_id"
                  item-text="name"
                  item-value="id"
                  :items="regions.data"
                  label="Регион"
                  :error-messages="errors.region_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete> -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    created() {
      this.clearLocalityTypes();
      this.clearRegions();
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getLocalityTypes();
      await this.getRegions();
      this.nonDisabledAndLoading();
    },
    destroyed () {
      this.clearLocalityTypes();
      this.clearRegions();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        localities: 'localities/LOCALITIES',
        locality_types: 'locality_types/LOCALITY_TYPES',
        regions: 'regions/REGIONS'
      }),
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            name: item.name,
            url: item.url,
            sypex_geo_id: item.sypex_geo_id,
            capital: item.capital,
            in_list: item.in_list,
            locality_type_id: item.locality_type.id,
            region_id: item.region.id,
            district_id: item.district_id,
            fias_id: item.fias_id
          }
        }
        else {
          return {
            name: '',
            url: '',
            sypex_geo_id: '',
            capital: '',
            in_list: '',
            locality_type_id: '',
            region_id: '',
            district_id: '',
            fias_id: ''
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getLocalities: 'localities/GET_LOCALITIES',
        storeLocality: 'localities/STORE_LOCALITY',
        updateLocality: 'localities/UPDATE_LOCALITY',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getLocalityTypes: 'locality_types/GET_ALL_LOCALITY_TYPES',
        getRegions: 'regions/GET_ALL_REGIONS',
        clearLocalityTypes: 'locality_types/CLEAR_LOCALITY_TYPES',
        clearRegions: 'regions/CLEAR_REGIONS',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.name = null;
        this.item.url = null;
        this.item.sypex_geo_id = null;
        this.item.capital = null;
        this.item.in_list = null;
        this.item.locality_type_id = null;
        this.item.region_id = null;
        this.item.district_id = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getLocalities({ page: this.localities.meta.current_page, per_page: this.localities.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (!this.item.capital) this.item.capital = null;
        if (!this.item.in_list) this.item.in_list = null;
        if (this.dialog.item) {
          this.updateLocality(this.item)
            .then(() => {
                this.successActions({message: "Населенный пункт изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeLocality(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Населенный пункт добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>