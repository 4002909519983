<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-file-input
                    v-model="item.files"
                    counter
                    label="Загрузка изображений"
                    multiple
                    placeholder="Выберите баннеры (общий размер не больше 1М)"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                    accept="image/*"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                        label
                        small
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
              :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              type="submit"
              :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
      disabled: false,
      loading: false,
      errors: false,
    }
  },
  async mounted () {
    //
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog/DIALOG',
      banners: 'banners/BANNERS',
    }),

    item: function () {
      var item = this.dialog.item;
      if (item) {
        return {
          files: item.files,
        }
      }
      else {
        return {
          files: []
        }
      }
    }
  },
  methods: {
    ...mapActions({
      unsetDialog: 'dialog/SET_DIALOG_FALSE',
      disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
      nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      getBanners: 'banners/GET_BANNERS',
      storeBanners: 'banners/STORE_BANNER',
      setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
    }),

    closeDialog () {
      this.unsetDialog();
      this.errors = false;
    },

    clearItem () {
      this.item.files = [];
    },

    successActions (snackbar) {
      this.setSnackbar(snackbar);
      this.closeDialog();
      this.errors = false;
      this.disabledAndLoading();
      this.getBanners({ page: this.banners.meta.current_page, per_page: this.banners.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
    },

    submitForm () {
      this.disabled = this.loading = true;
      this.storeBanners(this.item)
          .then(() => {
            if(this.item.files.length > 1)
              this.successActions({message: "Баннеры добавлены"});
            else
              this.successActions({message: "Баннер добавлен"});
            this.clearItem();
          })
          .catch(e => {
            if (e.response) {
              this.errors = e.response.data.errors;
            } else {
              this.setSnackbar({message: "Ошибка сервера", color: "red"});
            }
          })
          .finally(() => {
            this.disabled = this.loading = false;
          });
    }
  }
}
</script>