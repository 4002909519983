<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-autocomplete v-if="!dialog.item"
                  v-model="provider_id"
                  item-text="name"
                  item-value="id"
                  :items="providers.data"
                  label="Провайдер"
                  :error-messages="errors.provider_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete v-if="!dialog.item"
                  v-model="item.tarif_id"
                  item-text="name"
                  item-value="id"
                  :items="tarifs.data"
                  label="Тариф"
                  :error-messages="errors.tarif_id"
                  :disabled="input_disabled"
                  :loading="input_loading"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </v-chip>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.category.name"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="item.speed_id"
                  item-text="speed"
                  item-value="id"
                  :items="speeds.data"
                  label="Скорость"
                  clearable
                  :error-messages="errors.speed_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-text-field
                  label="Цена"
                  type="text"
                  v-model="item.price"
                  :error-messages="errors.price"
                ></v-text-field>
                <v-text-field
                  label="Стоимость подключения"
                  type="text"
                  v-model="item.podkl_price"
                  :error-messages="errors.podkl_price"
                ></v-text-field>
                <v-text-field
                  label="Кол-во каналов"
                  type="text"
                  v-model="item.sum_channels"
                  :error-messages="errors.sum_channels"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  label="Моб. интернет"
                  type="text"
                  v-model="item.mobile"
                  :error-messages="errors.mobile"
                ></v-text-field>
                <v-autocomplete
                  v-model="item.action_id"
                  item-text="name"
                  item-value="id"
                  :items="actions.data"
                  label="Акция"
                  clearable
                  :error-messages="errors.action_id"
                  :disabled="input_disabled"
                  :loading="input_loading"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="item.option_id"
                  item-text="name"
                  item-value="id"
                  :items="options.data"
                  label="Опция"
                  clearable
                  :error-messages="errors.option_id"
                  :disabled="input_disabled"
                  :loading="input_loading"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="item.tech_id"
                  item-text="name"
                  item-value="id"
                  :items="techs.data"
                  label="Технология"
                  clearable
                  :error-messages="errors.tech_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-checkbox
                  label="Скрытый"
                  v-model="item.hidden"
                  :error-messages="errors.hidden"
                ></v-checkbox>
                <v-text-field
                  label="Приоритет"
                  type="text"
                  v-model="item.priority"
                  :error-messages="errors.priority"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
        provider_id: null,
        input_disabled: true,
        input_loading: false,
      }
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getProviders({site_id: this.site_id, locality_id: this.locality_id});
      await this.getSpeeds();
      await this.getTechs();
      this.nonDisabledAndLoading();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        sites_localities_tarifs: 'sites_localities_tarifs/TARIFS',
        providers: 'sites_localities_providers/PROVIDERS',
        tarifs: 'tarifs/TARIFS',
        speeds: 'speeds/SPEEDS',
        actions: 'actions/ACTIONS',
        options: 'options/OPTIONS',
        techs: 'techs/TECHS',
        filter_provider_id: 'provider_id/PROVIDER_ID',
        filter_category_id: 'category_id/CATEGORY_ID',
      }),
      site_id: function () {
        return this.$route.params.site_id;
      },
      locality_id: function () {
        return this.$route.params.id;
      },
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            tarif_id: item.name ? item.name : null,
            speed_id: item.speed ? item.speed.id : null,
            price: item.price,
            podkl_price: item.podkl_price,
            sum_channels: item.sum_channels,
            mobile: item.mobile,
            action_id: item.action ? item.action.id : null,
            option_id: item.option ? item.option.id : null,
            tech_id: item.tech ? item.tech.id : null,
            hidden: item.hidden ? item.hidden : null,
            priority: item.priority ? item.priority : null,
          }
        }
        else {
          return {
            tarif_id: '',
            speed_id: '',
            price: '',
            podkl_price: '',
            sum_channels: '',
            mobile: '',
            action_id: '',
            option_id: '',
            tech_id: '',
            hidden: '',
            priority: ''
          }
        }
      },
    },
    watch: {
      provider_id: function (val) {
        if (this.provider_id) {
          this.getProviderRelatedData(val)
            .then(() => this.input_disabled = false)
            .finally(() => this.input_loading = false)
        } else {
          this.input_disabled = true;
          this.clearTarifs();
          this.clearActions();
          this.clearOptions();
        }
      },
      item: function (val) {
        if (val.id) {
          this.provider_id = this.dialog.item.provider.id;
        } else {
          this.provider_id = null;
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getSitesLocalitiesTarifs: 'sites_localities_tarifs/GET_TARIFS',
        storeTarif: 'sites_localities_tarifs/STORE_TARIF',
        updateTarif: 'sites_localities_tarifs/UPDATE_TARIF',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getProviders: 'sites_localities_providers/GET_ALL_PROVIDERS',
        getTarifs: 'tarifs/GET_TARIFS_ON_PROVIDER',
        clearTarifs: 'tarifs/CLEAR_TARIFS',
        getSpeeds: 'speeds/GET_ALL_SPEEDS',
        getActions: 'actions/GET_ACTIONS_ON_PROVIDER',
        clearActions: 'actions/CLEAR_ACTIONS',
        getOptions: 'options/GET_OPTIONS_ON_PROVIDER',
        clearOptions: 'options/CLEAR_OPTIONS',
        getTechs: 'techs/GET_ALL_TECHS'
      }),

      async getProviderRelatedData (provider_id) {
        this.input_disabled = true;
        this.input_loading = true;

        await this.getTarifs({provider_id: provider_id})
          .catch(e => {
            if (e.response) {
              this.errors = e.response.data.errors;
            } else {
              this.setSnackbar({message: "Ошибка сервера", color: "red"});
            }
          });

        await this.getActions({provider_id: provider_id})
          .catch(e => {
            if (e.response) {
              this.errors = e.response.data.errors;
            } else {
              this.setSnackbar({message: "Ошибка сервера", color: "red"});
            }
          });

        await this.getOptions({provider_id: provider_id})
          .catch(e => {
            if (e.response) {
              this.errors = e.response.data.errors;
            } else {
              this.setSnackbar({message: "Ошибка сервера", color: "red"});
            }
          });
      },

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.tarif_id = null;
        this.item.speed_id = null;
        this.item.price = null;
        this.item.podkl_price = null;
        this.item.sum_channels = null;
        this.item.mobile = null;
        this.item.action_id = null;
        this.item.option_id = null;
        this.item.tech_id = null;
        this.item.hidden = null;
        this.item.priority = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        let params = { site_id: this.$route.params.site_id, locality_id: this.$route.params.id, page: this.sites_localities_tarifs.meta.current_page, per_page: this.sites_localities_tarifs.meta.per_page };
        if (this.filter_provider_id) {
          params.provider_id = this.filter_provider_id;
        }
        if (this.filter_category_id) {
          params.category_id = this.filter_category_id;
        }
        this.getSitesLocalitiesTarifs(params)
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (this.item.speed_id === undefined) this.item.speed_id = null;
        if (this.item.action_id === undefined) this.item.action_id = null;
        if (this.item.option_id === undefined) this.item.option_id = null;
        if (this.item.tech_id === undefined) this.item.tech_id = null;
        if (!this.item.hidden) this.item.hidden = null;
        this.item["site_id"] = this.$route.params.site_id;
        this.item["locality_id"] = this.$route.params.id;
        if (this.dialog.item) {
          this.item.tarif_id  = this.item.id;
          this.updateTarif(this.item)
            .then(() => {
              this.successActions({message: "Тариф изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeTarif(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Тариф добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>