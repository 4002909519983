<template>
  <v-toolbar
    flat
  >
    <v-toolbar-title>Новости</v-toolbar-title>
    <v-divider
        class="mx-4"
        inset
        vertical
    ></v-divider>
    <v-btn
        color="normal"
        class="mx-2"
        icon
        small
        elevation="2"
        :disabled="disabled"
        @click.stop="openFilterDialog()"
    >
        <v-icon color="primary">
            mdi-filter
        </v-icon>
    </v-btn>
    <v-spacer></v-spacer>

    <v-btn
      color="primary"
      class="mb-2"
      :disabled="disabled"
      @click.stop="openDialog()"
    >
      Добавить
    </v-btn>

    <Dialog />
    <FilterDialog />

  </v-toolbar>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Dialog from '@/components/News/News/components/Dialog'
  import FilterDialog from "@/components/News/News/components/FilterDialog";

  export default {
    components: {
      Dialog,
      FilterDialog
    },
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
      }),
    },
    methods: {
      ...mapActions({
        setDialog: 'dialog/SET_DIALOG_TRUE',
        setFilterDialog: 'filter_dialog/SET_FILTER_DIALOG_TRUE',
      }),
      openDialog() {
        this.setDialog();
      },
      openFilterDialog() {
          this.setFilterDialog();
      },
    }
  }
</script>
