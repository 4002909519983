<template>
  <v-navigation-drawer
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
  >
    <v-list dense>
      <tree-list
          v-for="item in items"
          :item="item"
          :key="item.text"
      ></tree-list>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import TreeList from "@/components/Common/components/TreeList";
  export default {
    components: {
      TreeList
    },
    data: () => ({
      drawer: true,
      items: [
        { icon: 'mdi-web', link:'/sites', text: 'Сайты' },
        { icon: 'mdi-city', link:'/copy-locality', text: 'Копировать Нас. пункт' },
        { icon: 'mdi-chevron-up', 'icon-alt': 'mdi-chevron-down', text: 'Населенные пункты', model: false,
          children: [
            { icon: 'mdi-city', link:'/localities', text: 'Населенные пункты' },
            { icon: 'mdi-phone', link:'/phones', text: 'Номера телефонов' },
            { icon: 'mdi-home-city', link:'/locality-types', text: 'Типы населенных пунктов' },
            { icon: 'mdi-map-marker', link:'/addresses', text: 'Адреса офисов' },
            { icon: 'mdi-deskphone', link:'/office-phones', text: 'Номера телефонов офисов' },
          ],
        },
        { icon: 'mdi-chevron-up', 'icon-alt': 'mdi-chevron-down', text: 'Тарифы', model: false,
          children: [
            { icon: 'mdi-alpha-t-box', link:'/tarifs', text: 'Тарифы' },
            { icon: 'mdi-cards', link:'/categories', text: 'Категории тарифов' },
            { icon: 'mdi-cash', link:'/actions', text: 'Акции' },
            { icon: 'mdi-controller-classic', link:'/options', text: 'Опции' },
            { icon: 'mdi-lan-connect', link:'/techs', text: 'Технологии' },
            { icon: 'mdi-speedometer', link:'/speeds', text: 'Скорости' },
          ],
        },
        { icon: 'mdi-chevron-up', 'icon-alt': 'mdi-chevron-down', text: 'Провайдеры', model: false,
          children: [
            { icon: 'mdi-ip-network', link:'/providers', text: 'Провайдеры' },
            { icon: 'mdi-food', link:'/general-services', text: 'Общие услуги' },
            { icon: 'mdi-food-apple', link:'/services', text: 'Услуги' },
            { icon: 'mdi-chevron-up', 'icon-alt': 'mdi-chevron-down', text: 'Лого / банеры', model: false,
              children: [
                { icon: 'mdi-image', link:'/logos', text: 'Лого' },
                { icon: 'mdi-image-multiple', link:'/banners', text: 'Банеры' },
                // { icon: 'mdi-image-size-select-large', link:'/banners-hidpi', text: 'Банеры Hidpi' },
              ],
            },
          ],
        },
        { icon: 'mdi-chevron-up', 'icon-alt': 'mdi-chevron-down', text: 'Оборудование', model: false,
          children: [
            { icon: 'mdi-router-wireless', link:'/routers', text: 'Роутеры' },
            { icon: 'mdi-television-box', link:'/tv-boxes', text: 'ТВ-приставки' },
          ],
        },
        { icon: 'mdi-chevron-up', 'icon-alt': 'mdi-chevron-down', text: 'Регионы', model: false,
          children: [
            { icon: 'mdi-map', link:'/regions', text: 'Регионы' },
            { icon: 'mdi-home-group', link:'/districts', text: 'Районы' },
          ],
        },
        { icon: 'mdi-chevron-up', 'icon-alt': 'mdi-chevron-down', text: 'Компании', model: false,
          children: [
            { icon: 'mdi-briefcase', link:'/companies', text: 'Компании' },
            { icon: 'mdi-facebook', link:'/socials', text: 'Соц. сети' },
          ],
        },
        { icon: 'mdi-chevron-up', 'icon-alt': 'mdi-chevron-down', text: 'СЕО-Услуги', model: false,
          children: [
            { icon: 'mdi-cards', link:'/seo-services-categories', text: 'Категории СЕО-услуг' },
            // { icon: 'mdi-cube-outline', link:'/seo-service-blocks', text: 'Блоки СЕО-услуг' },
            { icon: 'mdi-link-variant', link:'/seo-services', text: 'СЕО-услуги' },
          ],
        },
        { icon: 'mdi-chevron-up', 'icon-alt': 'mdi-chevron-down', text: 'Новости', model: false,
          children: [
            { icon: 'mdi-cards', link:'/news-categories', text: 'Категории новостей' },
            { icon: 'mdi-drama-masks', link:'/news-topics', text: 'Темы новостей' },
            { icon: 'mdi-format-float-left', link:'/news', text: 'Новости' },
          ],
        },
        { icon: 'mdi-chevron-up', 'icon-alt': 'mdi-chevron-down', text: 'TV-Каналы', model: false,
          children: [
            { icon: 'mdi-drama-masks', link:'/genres', text: 'Жанры' },
            { icon: 'mdi-high-definition', link:'/resolutions', text: 'Разрешение' },
            { icon: 'mdi-television-box', link:'/channels', text: 'Каналы' },
            { icon: 'mdi-television-classic', link:'/channels-packages', text: 'Пакеты каналов' },
          ],
        },
        { icon: 'mdi-chart-line', link: '/seo', text: 'СЕО' },
        { icon: 'mdi-message-text-outline', link:'/reviews', text: 'Отзывы' },
        { icon: 'mdi-help-circle-outline', link:'/supports', text: 'Поддержка' },
      ],
    }),
  }
</script>
