<template>
  <div>
    <v-icon small class="mr-2" @click="editItem(item)" :disabled="disabled" title="Редактировать">mdi-pencil</v-icon>
    <v-icon small class="ml-2" @click="deleteItem(item)" :disabled="disabled" title="Удалить">mdi-delete</v-icon>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        localities: 'router_localities/LOCALITIES',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
      }),
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        params.router_id = this.$route.params.id;
        params.page = (this.localities.meta.from === this.localities.meta.to) ? this.localities.meta.current_page - 1 : this.localities.meta.current_page;
        params.per_page = this.localities.meta.per_page;
        return params;
      }
    },
    methods: {
      ...mapActions({
        deleteLocality: 'router_localities/DELETE_LOCALITY',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getLocalities: 'router_localities/GET_LOCALITIES',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        this.deleteLocality({ id: item.id, router_id: this.$route.params.id })
          .then(() => {
            this.setSnackbar({message: "Населённый пункт удален"});
            this.getLocalities(this.params)
              .then(() => {
                this.nonDisabledAndLoading();
              });
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
      },
    }
  }
</script>
