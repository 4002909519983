export default({
  namespaced: true,

  state: {
    disabled: false,
    table_loading: false
  },

  getters: {
    DISABLED (state){
      return state.disabled;
    },
    TABLE_LOADING (state){
      return state.table_loading;
    }
  },

  mutations: {
    SET_DISABLED_TRUE (state) {
      state.disabled = true;
    },
    SET_DISABLED_FALSE (state) {
      state.disabled = false;
    },

    SET_TABLE_LOADING_TRUE (state) {
      state.table_loading = true;
    },
    SET_TABLE_LOADING_FALSE (state) {
      state.table_loading = false;
    },
  },

  actions: {
    SET_DISABLED_TRUE ({ commit }) {
      commit('SET_DISABLED_TRUE')
    },
    SET_DISABLED_FALSE ({ commit }) {
      commit('SET_DISABLED_FALSE')
    },

    SET_TABLE_LOADING_TRUE ({ commit }) {
      commit('SET_TABLE_LOADING_TRUE')
    },
    SET_TABLE_LOADING_FALSE ({ commit }) {
      commit('SET_TABLE_LOADING_FALSE')
    },

    SET_TABLE_DISABLE_AND_LOADING ({ commit }) {
      commit('SET_DISABLED_TRUE')
      commit('SET_TABLE_LOADING_TRUE')
    },
    RESET_TABLE_DISABLE_AND_LOADING ({ commit }) {
      commit('SET_DISABLED_FALSE')
      commit('SET_TABLE_LOADING_FALSE')
    }
  }
});
