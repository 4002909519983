import axios from 'axios'

export default({
  namespaced: true,

  state: {
    tarifs: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    },
    tarif: null
  },

  getters: {
    TARIFS (state) {
      return state.tarifs;
    },
    TARIF (state) {
      return state.tarif;
    }
  },

  mutations: {
    SET_TARIFS (state, data) {
      state.tarifs = data;
    },

    SET_TARIF (state, data) {
      state.tarif = data;
    },

    CLEAR_TARIFS (state) {
      state.tarifs = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    },

    CLEAR_TARIF (state) {
      state.tarif = null;
    }
  },

  actions: {
    GET_TARIFS: async (context, params) => {
      if (params) {
        let {data} = await axios.get('/tarifs?', {params: params});
        context.commit('SET_TARIFS', data);
      } else {
        let {data} = await axios.get('/tarifs');
        context.commit('SET_TARIFS', data);
      }
    },

    GET_ALL_TARIFS: async (context) => {
      let {data} = await axios.get('/tarifs?per_page=1000&sort_by=name');
      context.commit('SET_TARIFS', data);
    },

    GET_TARIFS_ON_PROVIDER: async (context, params) => {
      var provider_id = params.provider_id;
      delete params.provider_id;
      let {data} = await axios.get('/tarifs?per_page=1000&provider_id=' + provider_id);
      context.commit('SET_TARIFS', data);
    },

    GET_TARIF: async (context, params) => {
      let {data} = await axios.get('/tarifs/' + params);
      context.commit('SET_TARIF', data);
    },

    CLEAR_TARIFS (context) {
      context.commit('CLEAR_TARIFS');
    },

    CLEAR_TARIF (context) {
      context.commit('CLEAR_TARIF');
    },

    STORE_TARIF: async (context, params) => {
      await axios.post('/tarifs', params);
    },

    UPDATE_TARIF: async (context, params) => {
      await axios.put('/tarifs/' + params.id, params);
    },

    DELETE_TARIF: async (context, params) => {
      await axios.delete('/tarifs/' + params.id);
    }
  }
});
