import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth/auth'

import data_table from './interface/data_table'
import dialog from './interface/dialog'
import filter_dialog from './interface/filter_dialog'
import snackbar from './interface/snackbar'
import errors from './interface/errors'

import provider_id from './interface/filters/provider_id'
import category_id from './interface/filters/category_id'
import region_id from './interface/filters/region_id'
import search from './interface/filters/search'

import companies from './data/companies/companies'
import socials from './data/companies/socials'

import routers from './data/devices/routers/routers'
import router_localities from './data/devices/routers/localities'
import router_tarifs from './data/devices/routers/tarifs'
import tv_boxes from './data/devices/tv_boxes/tv_boxes'
import tv_box_localities from './data/devices/tv_boxes/localities'
import tv_box_tarifs from './data/devices/tv_boxes/tarifs'

import reviews from './data/reviews/reviews'
import building_types from './data/reviews/building_tipes'

import supports from './data/supports/supports'

import seo from './data/seo/seo'

import seo_services_categories from './data/seo_services/seo_services_categories'
import seo_services from './data/seo_services/seo_services/seo_services'
import seo_service_blocks from './data/seo_services/seo_services/seo_service_blocks'

import news_categories from './data/news/news_categories'
import news_topics from './data/news/news_topics'
import news from './data/news/news/news'
import news_categories_news from './data/news/news/news_categories'

import genres from './data/channels/genres'
import resolutions from './data/channels/resolutions'
import channels_packages from './data/channels/channels_packages/channels_packages'
import channels from './data/channels/channels'
import channels_package_channels from './data/channels/channels_packages/channels'

import localities from './data/localities/localities/localities'
import locality_types from './data/localities/locality_types'
import phones from './data/localities/phones'
import office_phones from './data/localities/office_phones'
import addresses from './data/localities/addresses'
import locality_addresses from './data/localities/localities/addresses/addresses'
import questions_answers from './data/sites/localities/questions_answers/questions_answers'
import locality_tarifs from './data/localities/localities/providers/tarifs/tarifs'
import locality_tarif_options from './data/localities/localities/providers/tarifs/options/options'
import locality_tarif_routers from './data/localities/localities/providers/tarifs/routers/routers'
import locality_tarif_router_rents from './data/localities/localities/providers/tarifs/routers/rents'
import locality_tarif_router_rent_prices from './data/localities/localities/providers/tarifs/routers/rent_prices'
import locality_tarif_tv_boxes from './data/localities/localities/providers/tarifs/tv_boxes/tv_boxes'
import locality_tarif_tv_box_rents from './data/localities/localities/providers/tarifs/tv_boxes/rents'
import locality_tarif_tv_box_rent_prices from './data/localities/localities/providers/tarifs/tv_boxes/rent_prices'
import locality_channels from './data/localities/localities/channels/channels'

import providers from './data/providers/providers/providers'
import provider_general_services from './data/providers/providers/general_services'
import provider_banners from './data/providers/providers/banners'
import general_services from './data/providers/general_services/general_services'
import general_services_services from './data/providers/general_services/services'
import services from './data/providers/services'
import logos from './data/providers/images/logos'
import banners from './data/providers/images/banners'
import banners_hidpi from './data/providers/images/banners_hidpi'

import regions from './data/regions/regions'
import districts from './data/regions/districts'

import categories from './data/tarifs/categories'
import tarifs from './data/tarifs/tarifs'
import tarif_localities from './data/tarifs/localities'
import tarif_routers from './data/tarifs/routers'
import tarif_tv_boxes from './data/tarifs/tv_boxes'
import actions from './data/tarifs/actions'
import options from './data/tarifs/options'
import techs from './data/tarifs/techs'
import speeds from './data/tarifs/speeds'

import sites from './data/sites/sites'
import sites_regions from './data/sites/regions/regions'
import sites_localities from './data/sites/localities/localities'
import sites_providers from './data/sites/providers/providers'
import sites_localities_tarifs from './data/sites/localities/tarifs/tarifs'
import locality_providers from './data/localities/localities/providers/providers'
import locality_provider_categories from './data/localities/localities/providers/categories/categories'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,

    data_table,
    dialog,
    filter_dialog,
    snackbar,
    errors,

    provider_id,
    category_id,
    region_id,
    search,

    companies,
    phones,
    regions,
    providers,
    provider_general_services,
    provider_banners,
    districts,
    locality_types,
    localities,
    locality_providers,
    locality_provider_categories,
    locality_tarifs,
    locality_tarif_options,
    locality_tarif_routers,
    locality_tarif_router_rents,
    locality_tarif_router_rent_prices,
    locality_tarif_tv_boxes,
    locality_tarif_tv_box_rents,
    locality_tarif_tv_box_rent_prices,
    locality_channels,
    office_phones,
    addresses,
    locality_addresses,
    questions_answers,
    categories,
    tarifs,
    tarif_localities,
    tarif_routers,
    tarif_tv_boxes,
    actions,
    options,
    techs,
    speeds,
    socials,
    general_services,
    general_services_services,
    services,
    logos,
    banners,
    banners_hidpi,
    routers,
    router_localities,
    router_tarifs,
    tv_boxes,
    tv_box_localities,
    tv_box_tarifs,
    seo,
    reviews,
    building_types,
    supports,

    seo_services_categories,
    seo_services,
    seo_service_blocks,

    news_categories,
    news_topics,
    news,
    news_categories_news,

    genres,
    resolutions,
    channels_packages,
    channels,
    channels_package_channels,

    sites,
    sites_regions,
    sites_localities,
    sites_providers,
    sites_localities_tarifs,
  }
})
