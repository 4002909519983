import { authGuardFail } from '../guards.js'

import Regions from '../../views/Regions/Regions.vue'
import Districts from '../../views/Regions/Districts.vue'

export default [
  {
    path: '/regions',
    name: 'regions',
    component: Regions,
    beforeEnter: authGuardFail
  },
  {
    path: '/districts',
    name: 'districts',
    component: Districts,
    beforeEnter: authGuardFail
  },
]