import axios from 'axios'

export default({
    namespaced: true,

    state: {
        seo: {
            data: [],
            meta: {
                current_page: null,
                from: null,
                last_page: null,
                path: null,
                per_page: null,
                to: null,
                total: null
            }
        },
        one_seo: null
    },

    getters: {
        SEO (state) {
            return state.seo;
        },
        ONE_SEO (state) {
            return state.one_seo;
        }
    },

    mutations: {
        SET_SEO (state, data) {
            state.seo = data;
        },

        SET_ONE_SEO (state, data) {
            state.one_seo = data;
        },

        CLEAR_SEO (state) {
            state.seo = {
                data: [],
                meta: {
                    current_page: null,
                    from: null,
                    last_page: null,
                    path: null,
                    per_page: null,
                    to: null,
                    total: null
                }
            }
        },

        CLEAR_ONE_SEO (state) {
            state.one_seo = null;
        }
    },

    actions: {
        GET_SEO: async (context, params) => {
            var params_str;
            if (params) {
                params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                let {data} = await axios.get('/seo?' + params_str);
                context.commit('SET_SEO', data);
            } else {
                let {data} = await axios.get('/seo');
                context.commit('SET_SEO', data);
            }
        },

        GET_ALL_SEO: async (context) => {
            let {data} = await axios.get('/seo?per_page=1000');
            context.commit('SET_SEO', data);
        },

        GET_ONE_SEO: async (context, params) => {
            let {data} = await axios.get('/seo/' + params);
            context.commit('SET_ONE_SEO', data);
        },

        CLEAR_SEO (context) {
            context.commit('CLEAR_SEO');
        },

        CLEAR_ONE_SEO (context) {
            context.commit('CLEAR_ONE_SEO');
        },

        STORE_SEO: async (context, params) => {
            await axios.post('/seo', params);
        },

        UPDATE_SEO: async (context, params) => {
            await axios.put('/seo/' + params.id, params);
        },

        DELETE_SEO: async (context, params) => {
            await axios.delete('/seo/' + params.id);
        }
    }
});
