import axios from 'axios'

export default({
  namespaced: true,

  state: {
    providers: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    },
    provider: null
  },

  getters: {
    PROVIDERS (state) {
      return state.providers;
    },
    PROVIDER (state) {
      return state.provider;
    }
  },

  mutations: {
    SET_PROVIDERS (state, data) {
      state.providers = data;
    },

    SET_PROVIDER (state, data) {
      state.provider = data;
    },

    CLEAR_PROVIDERS (state) {
      state.providers = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    },

    CLEAR_PROVIDER (state) {
      state.provider = null
    }
  },

  actions: {
    GET_PROVIDERS: async (context, params) => {
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/providers?' + params_str);
        context.commit('SET_PROVIDERS', data);
      } else {
        let {data} = await axios.get('/providers');
        context.commit('SET_PROVIDERS', data);
      }
    },

    GET_ALL_PROVIDERS: async (context) => {
      let {data} = await axios.get('/providers?per_page=1000&sort_by=name');
      context.commit('SET_PROVIDERS', data);
    },

    GET_PROVIDER: async (context, params) => {
      let {data} = await axios.get('/providers/' + params);
      context.commit('SET_PROVIDER', data);
    },

    CLEAR_PROVIDER (context) {
      context.commit('CLEAR_PROVIDER');
    },

    CLEAR_PROVIDERS (context) {
      context.commit('CLEAR_PROVIDERS');
    },

    STORE_PROVIDER: async (context, params) => {
      await axios.post('/providers', params);
    },

    UPDATE_PROVIDER: async (context, params) => {
      await axios.put('/providers/' + params.id, params);
    },

    DELETE_PROVIDER: async (context, params) => {
      await axios.delete('/providers/' + params.id);
    }
  }
});
