<template>
  <div>
    <v-icon small class="mr-2" @click="editItem(item)" :disabled="disabled" title="Редактировать">mdi-pencil</v-icon>
    <v-icon small class="ml-2" @click="deleteItem(item)" :disabled="disabled" title="Удалить">mdi-delete</v-icon>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ["item"],
  computed: {
    ...mapGetters({
      disabled: 'data_table/DISABLED',
      supports: 'supports/SUPPORTS',
      filter_dialog: 'filter_dialog/FILTER_DIALOG',
    }),
    params: function () {
      let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
      params.page = (this.supports.meta.from === this.supports.meta.to) ? this.supports.meta.current_page - 1 : this.supports.meta.current_page;
      params.per_page = this.supports.meta.per_page;
      return params;
    }
  },
  methods: {
    ...mapActions({
      deleteSupport: 'supports/DELETE_SUPPORT',
      setDialog: 'dialog/SET_DIALOG_TRUE',
      disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
      nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
      getSupports: 'supports/GET_SUPPORTS',
      showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
    }),

    editItem (item) {
      this.setDialog(item);
    },

    deleteItem (item) {
      this.disabledAndLoading();
      this.deleteSupport({ id: item.id })
          .then(() => {
            this.setSnackbar({message: "Удалено"});
            this.getSupports(this.params)
                .then(() => {
                  this.nonDisabledAndLoading();
                });
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
    },
  }
}
</script>
