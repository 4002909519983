<template>
  <div style="white-space: nowrap">
    <template v-if="!item.fake_locality">
      <v-btn :disabled="disabled" link :to="{name: 'locality-provider-categories', params: { locality_id: this.$route.params.id, id: item.id }}" icon color="gray" small rounded class="mr-2" title="Категории тарифов"><v-icon>mdi-cards</v-icon></v-btn>
      <v-btn :disabled="disabled" link :to="{name: 'locality-provider-tarifs', params: { locality_id: this.$route.params.id, id: item.id }}" icon color="gray" small rounded class="mr-2" title="Тарифы"><v-icon>mdi-alpha-t-box-outline</v-icon></v-btn>
    </template>
    <v-icon small class="mr-2" @click="editItem(item)" :disabled="disabled" title="Редактировать">mdi-pencil</v-icon>
    <v-icon small class="mr-2" @click="deleteItem(item)" :disabled="disabled">mdi-delete</v-icon>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        providers: 'locality_providers/PROVIDERS',
      }),
    },
    methods: {
      ...mapActions({
        deleteProvider: 'locality_providers/DELETE_PROVIDER',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getProviders: 'locality_providers/GET_PROVIDERS',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        var meta = this.providers.meta;
        this.deleteProvider({ id: item.id, locality_id: this.$route.params.id })
          .then(() => {
            this.setSnackbar({message: "Провайдер удален"});
            if(meta.from === meta.to) {
              this.getProviders({ locality_id: this.$route.params.id, page: meta.current_page - 1, per_page: meta.per_page })
                .then(() => {
                  this.nonDisabledAndLoading();
                });
            } else {
              this.getProviders({ locality_id: this.$route.params.id, page: meta.current_page, per_page: meta.per_page })
                .then(() => {
                  this.nonDisabledAndLoading();
                });
            }
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
      },
    }
  }
</script>
