<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md=""
              >
                <v-text-field
                  label="Наименование региона"
                  hint="Например: Хабаровский Край"
                  type="text"
                  autofocus
                  v-model="item.name"
                  :error-messages="errors.name"
                ></v-text-field>
                <v-text-field
                  label="Наименование региона 2"
                  hint="Например: Хабаровском Крае"
                  type="text"
                  v-model="item.second_name"
                  :error-messages="errors.second_name"
                ></v-text-field>
                <v-text-field
                  label="URL региона"
                  hint="Например: khabarovsky-kray"
                  type="text"
                  v-model="item.url"
                  :error-messages="errors.url"
                ></v-text-field>
                <v-text-field
                    label="Идентификатор ФИАС региона"
                    hint="Например: 7d468b39-1afa-41ec-8c4f-97a8603cb3d4"
                    type="text"
                    v-model="item.fias_id"
                    :error-messages="errors.fias_id"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        regions: 'regions/REGIONS',
      }),
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            name: item.name,
            second_name: item.second_name,
            url: item.url,
            fias_id: item.fias_id
          }
        }
        else {
          return {
            name: '',
            second_name: '',
            url: '',
            fias_id: ''
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getRegions: 'regions/GET_REGIONS',
        storeRegion: 'regions/STORE_REGION',
        updateRegion: 'regions/UPDATE_REGION',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE'
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.name = null;
        this.item.second_name = null;
        this.item.url = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getRegions({ page: this.regions.meta.current_page, per_page: this.regions.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (this.dialog.item) {
          this.updateRegion(this.item)
            .then(() => {
                this.successActions({message: "Регион изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeRegion(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Регион добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>