<template>
  <v-toolbar
    flat
  >
    <v-toolbar-title v-if="tv_box">{{tv_box.data.name}}: Населённые пункты</v-toolbar-title>
    <v-divider
      class="mx-4"
      inset
      vertical
    ></v-divider>
    <v-btn
        color="normal"
        class="mx-2"
        icon
        small
        elevation="2"
        :disabled="disabled"
        @click.stop="openFilterDialog()"
    >
      <v-icon color="primary">
        mdi-filter
      </v-icon>
    </v-btn>
    <v-spacer></v-spacer>

    <v-btn
      color="primary"
      class="mb-2"
      :disabled="disabled"
      @click.stop="openDialog()"
    >
      Добавить
    </v-btn>

    <Dialog />
    <FilterDialog />

  </v-toolbar>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Dialog from '@/components/Devices/TvBoxes/relations/Localities/components/Dialog'
  import FilterDialog from '@/components/Devices/TvBoxes/relations/Localities/components/FilterDialog'

  export default {
    components: {
      Dialog,
      FilterDialog
    },
    mounted () {
      this.getTvBox(this.$route.params.id);
    },
    destroyed () {
      this.clearTvBox();
    },
    computed: {
      ...mapGetters({
        tv_box: 'tv_boxes/TV_BOX',
        disabled: 'data_table/DISABLED',
      }),
    },
    methods: {
      ...mapActions({
        setDialog: 'dialog/SET_DIALOG_TRUE',
        setFilterDialog: 'filter_dialog/SET_FILTER_DIALOG_TRUE',
        getTvBox: 'tv_boxes/GET_TV_BOX',
        clearTvBox: 'tv_boxes/CLEAR_TV_BOX',
      }),
      openDialog() {
        this.setDialog();
      },
      openFilterDialog() {
        this.setFilterDialog();
      },
    }
  }
</script>
