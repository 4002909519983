import axios from 'axios'

export default({
  namespaced: true,

  state: {
    speeds: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    SPEEDS (state){
      return state.speeds;
    }
  },

  mutations: {
    SET_SPEEDS (state, data) {
      state.speeds = data;
    },

    CLEAR_SPEEDS (state) {
      state.speeds = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_SPEEDS: async (context, params) => {
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/speeds?' + params_str);
        context.commit('SET_SPEEDS', data);
      } else {
        let {data} = await axios.get('/speeds');
        context.commit('SET_SPEEDS', data);
      }
    },

    GET_ALL_SPEEDS: async (context) => {
      let {data} = await axios.get('/speeds?per_page=1000&sort_by=name');
      context.commit('SET_SPEEDS', data);
    },

    CLEAR_SPEEDS (context) {
      context.commit('CLEAR_SPEEDS');
    },

    STORE_SPEED: async (context, params) => {
      await axios.post('/speeds', params);
    },

    UPDATE_SPEED: async (context, params) => {
      await axios.put('/speeds/' + params.id, params);
    },

    DELETE_SPEED: async (context, params) => {
      await axios.delete('/speeds/' + params.id);
    }
  }
});
