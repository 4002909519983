<template>
  <td colspan="11">
    <div class="custom_title_text_wrap">
      <div class="custom_title">Заголовок: <Nullable :value="item.title" /></div>
    </div>
    <div class="custom_title_text_wrap">
      <div class="custom_title">Описание: <Nullable :value="item.description" /></div>
    </div>
    <div class="custom_title_text_wrap">
      <div class="custom_title">H1: <Nullable :value="item.h1" /></div>
    </div>
    <div class="custom_title_text_wrap">
      <div class="custom_title">Текст под H1: <Nullable :value="item.sub_h1" /></div>
    </div>
    <div class="custom_title_text_wrap">
      <div class="custom_title">Текст над тарифами (H2): <Nullable :value="item.h2" /></div>
    </div>
  </td>
</template>

<script>
  import Nullable from '@/components/Common/Nullable'
  export default {
    components: {Nullable},
    props: ["item"]
  }
</script>
