import axios from 'axios'

export default({
  namespaced: true,

  state: {
    options: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    OPTIONS (state){
      return state.options;
    }
  },

  mutations: {
    SET_OPTIONS(state, data) {
      state.options = data;
    },

    CLEAR_OPTIONS (state) {
      state.options = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_OPTIONS: async (context, params) => {
      var locality_id = params.locality_id;
      var provider_id = params.provider_id;
      var tarif_id = params.tarif_id;

      delete params.locality_id;
      delete params.provider_id;
      delete params.tarif_id;

      var params_str;
      params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');

      let {data} = await axios.get(
        '/localities/' + locality_id +
        '/providers/' + provider_id +
        '/tarifs/' + tarif_id +
        '/options?' + params_str
      );

      context.commit('SET_OPTIONS', data);
    },

    CLEAR_OPTIONS (context) {
      context.commit('CLEAR_OPTIONS');
    },

    STORE_OPTION: async (context, params) => {
      var locality_id = params.locality_id;
      var provider_id = params.provider_id;
      var tarif_id = params.tarif_id;

      delete params.locality_id;
      delete params.provider_id;
      delete params.tarif_id;

      await axios.post(
        '/localities/' + locality_id +
        '/providers/' + provider_id +
        '/tarifs/' + tarif_id +
        '/options',
        params
      );
    },

    UPDATE_OPTION: async (context, params) => {
      var locality_id = params.locality_id;
      var provider_id = params.provider_id;
      var tarif_id = params.tarif_id;
      var id = params.id;

      delete params.locality_id;
      delete params.provider_id;
      delete params.tarif_id;
      delete params.id;

      await axios.put(
        '/localities/' + locality_id +
        '/providers/' + provider_id +
        '/tarifs/' + tarif_id +
        '/options/' +
        id,
        params
      );
    },

    DELETE_OPTION: async (context, params) => {
      await axios.delete(
        '/localities/' + params.locality_id +
        '/providers/' + params.provider_id +
        '/tarifs/' + params.tarif_id +
        '/options/' + params.id
      );
    }
  }
});
