<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="providers.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
    show-expand
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:expanded-item="{ item }">
      <Expanded :item="item"/>
    </template>

    <template v-slot:[`item.regional`]="{ item }" disabled>
      <div v-if="item.regional"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="providers.meta.from">
      <TablePagination :items="providers" action="providers/GET_PROVIDERS" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Providers/Providers/components/Top'
  import Expanded from '@/components/Providers/Providers/components/Expanded/Expanded'
  import Actions from '@/components/Providers/Providers/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions,
      Expanded
    },
    data () {
      return {
        headers: [
          { text: 'Название', value: 'name', sortable: false },
          { text: 'URL', value: 'url', sortable: false },
          { text: 'Личный кабинет', value: 'lk_url', sortable: false },
          { text: 'Номер телефона', value: 'phone.phone', sortable: false },
          { text: 'Тех. поддержка', value: 'tech_tel', sortable: false },
          { text: 'Дата начала работы', value: 'birth', sortable: false },
          { text: 'Количество клиентов', value: 'clients', sortable: false },
          { text: 'Региональный провайдер', value: 'regional', sortable: false, align: 'center' },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
        check: 'mdi-check'
      }
    },
    mounted () {
      this.clearProviders();
      this.disabledAndLoading();
      this.getProviders()
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearProviders();
    },
    computed: {
      ...mapGetters({
        providers: 'providers/PROVIDERS',
        table_loading: 'data_table/TABLE_LOADING',
      })
    },
    methods: {
      ...mapActions({
        getProviders: 'providers/GET_PROVIDERS',
        clearProviders: 'providers/CLEAR_PROVIDERS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>