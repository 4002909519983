<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Название"
                  type="text"
                  autofocus
                  v-model="item.name"
                  :error-messages="errors.name"
                ></v-text-field>
                <v-text-field
                  label="Описание"
                  type="text"
                  v-model="item.description"
                  :error-messages="errors.description"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        techs: 'techs/TECHS',
      }),
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            name: item.name,
            description: item.description
          }
        }
        else {
          return {
            name: '',
            description: ''
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getTechs: 'techs/GET_TECHS',
        storeTech: 'techs/STORE_TECH',
        updateTech: 'techs/UPDATE_TECH',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE'
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.name = null;
        this.item.description = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getTechs({ page: this.techs.meta.current_page, per_page: this.techs.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (this.dialog.item) {
          this.updateTech(this.item)
            .then(() => {
                this.successActions({message: "Технология изменена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeTech(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Технология добавлена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>