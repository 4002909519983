<template>
  <v-toolbar
      flat
  >
    <v-toolbar-title>Баннеры Hidpi</v-toolbar-title>
    <v-divider
        class="mx-4"
        inset
        vertical
    ></v-divider>
    <v-spacer></v-spacer>

    <v-btn
        color="primary"
        class="mb-2"
        :disabled="disabled"
        @click.stop="openDialog()"
    >
      Добавить
    </v-btn>

    <Dialog />

  </v-toolbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Dialog from '@/components/Providers/Images/BannersHidpi/components/Dialog'

export default {
  components: {
    Dialog
  },
  computed: {
    ...mapGetters({
      disabled: 'data_table/DISABLED',
    }),
  },
  methods: {
    ...mapActions({
      setDialog: 'dialog/SET_DIALOG_TRUE',
    }),
    openDialog() {
      this.setDialog();
    },
  }
}
</script>