<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="primary"
    dark
  >
    <v-app-bar-nav-icon @click.stop="$emit('toggle-drawer')"></v-app-bar-nav-icon>
    <v-toolbar-title style="width: 300px" class="ml-0 pl-4"><span class="hidden-sm-and-down">MultiNet</span></v-toolbar-title>
<!--    <v-text-field-->
<!--      flat-->
<!--      solo-inverted-->
<!--      hide-details-->
<!--      prepend-inner-icon="mdi-magnify"-->
<!--      label="Поиск"-->
<!--      class="hidden-sm-and-down"-->
<!--      disabled-->
<!--    ></v-text-field>-->
    <v-spacer></v-spacer>
    <v-btn icon>
      <v-icon disabled>mdi-apps</v-icon>
    </v-btn>
    <v-btn icon>
      <v-icon disabled>mdi-bell</v-icon>
    </v-btn>

    <v-menu offset-y auto>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon large v-bind="attrs" v-on="on">
          <v-avatar size="32px" item>
            <v-img src="@/assets/img/user.png"></v-img>
          </v-avatar>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item @click.prevent="" disabled>
          <v-list-item-icon class="mr-2">
            <v-icon v-text="'mdi-account-circle'"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>Профиль</v-list-item-title>
        </v-list-item>

        <v-list-item @click.prevent="signOut">
          <v-list-item-icon class="mr-2">
            <v-icon v-text="'mdi-exit-to-app'"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>Выход</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    methods: {
      ...mapActions({
        signOutAction: 'auth/signOut'
      }),

      signOut () {
        this.signOutAction().then(() =>{
          this.$router.replace({
            name: 'signin'
          })
        })
      }
    }
  }
</script>