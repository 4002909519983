import axios from 'axios'

export default({
  namespaced: true,

  state: {
    token: null,
    user: null,
  },

  getters: {
    authenticated (state) {
      return state.token && state.user
    },
    user (state) {
      return state.user
    }
  },

  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
    },
    SET_USER (state, data) {
      state.user = data
    }
  },

  actions: {
    async signIn ({ dispatch }, credentials ) {
      let responce = await axios.post('auth/signin', credentials)
      return dispatch('attempt', responce.data.token)
    },

    async attempt ({ commit, state }, token) {
      if (token) {
        commit('SET_TOKEN', token)
      }

      if (!state.token) {
        return
      }

      try {
        let responce = await axios.get('auth/me')
        commit('SET_USER', responce.data)
      } catch (e) {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
      }
    },

    async signOut ({ commit }) {
      return axios.post('auth/signout').then(() => {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
      })
    },

    // GET_USER: async (context) => {
    //   let {data} = await axios.get('auth/me');
    //   context.commit('SET_USER', data.data)
    // },
  }
})
