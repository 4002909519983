import axios from 'axios'

export default({
  namespaced: true,

  state: {
    localities: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    },
    locality: null
  },

  getters: {
    LOCALITIES (state){
      return state.localities;
    },
    LOCALITY (state){
      return state.locality;
    }
  },

  mutations: {
    SET_LOCALITIES (state, data) {
      state.localities = data;
    },

    SET_LOCALITY (state, data) {
      state.locality = data;
    },

    CLEAR_LOCALITIES (state) {
      state.localities = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    },

    CLEAR_LOCALITY (state) {
      state.locality = null
    }
  },

  actions: {
    GET_SEARCH_LOCALITIES: async (context, params) => {
      var jsonParams = params.jsonParams;
      params = params.params;
      var params_str;
      if (params) {
        params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let {data} = await axios.get('/localities?' + params_str, {params: jsonParams});
        context.commit('SET_LOCALITIES', data);
        return data;
      } else {
        let {data} = await axios.get('/localities', {params: jsonParams});
        context.commit('SET_LOCALITIES', data);
        return data;
      }
    },

    GET_LOCALITIES: async (context, params) => {
      if (params) {
        let {data} = await axios.get('/localities', {params: params});
        context.commit('SET_LOCALITIES', data);
        return data;
      } else {
        let {data} = await axios.get('/localities');
        context.commit('SET_LOCALITIES', data);
        return data;
      }
    },

    GET_ALL_LOCALITIES: async (context, params) => {
        if (params) {
            let {data} = await axios.get('/localities?per_page=100000&sort_by=name', {params: params});
            context.commit('SET_LOCALITIES', data);
            return data;
        } else {
          let {data} = await axios.get('/localities?per_page=100000&sort_by=name');
          context.commit('SET_LOCALITIES', data);
        }
    },

    GET_LOCALITY: async (context, params) => {
      let {data} = await axios.get('/localities/' + params);
      context.commit('SET_LOCALITY', data);
    },

    CLEAR_LOCALITIES (context) {
      context.commit('CLEAR_LOCALITIES');
    },

    CLEAR_LOCALITY (context) {
      context.commit('CLEAR_LOCALITY');
    },

    STORE_LOCALITY: async (context, params) => {
      await axios.post('/localities', params);
    },

    UPDATE_LOCALITY: async (context, params) => {
      await axios.put('/localities/' + params.id, params);
    },

    DELETE_LOCALITY: async (context, params) => {
      await axios.delete('/localities/' + params.id);
    }
  }
});
