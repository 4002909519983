import { authGuardFail } from '../guards.js'

import GeneralServices from '../../views/Providers/GeneralServices/GeneralServices.vue'
import Services from '../../views/Providers/GeneralServices/Services.vue'

export default [
  {
    path: '/general-services',
    name: 'general_services',
    component: GeneralServices,
    beforeEnter: authGuardFail
  },
  {
    path: '/general-services/:id/services',
    name: 'general-service-services',
    component: Services,
    beforeEnter: authGuardFail
  },
];