<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md=""
              >
                <v-text-field
                  label="Название провайдера"
                  hint="Например: ТТК"
                  type="text"
                  autofocus
                  v-model="item.name"
                  :error-messages="errors.name"
                ></v-text-field>
                <v-text-field
                  label="URL провайдера"
                  hint="Например: ttk"
                  type="text"
                  v-model="item.url"
                  :error-messages="errors.url"
                ></v-text-field>
                <v-autocomplete
                    v-model="item.phone_id"
                    item-text="phone"
                    item-value="id"
                    :items="phones.data"
                    label="Номер телефона"
                    clearable
                    :error-messages="errors.phone_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-text-field
                  label="Номер тех. поддержки"
                  type="text"
                  v-model="item.tech_tel"
                  :error-messages="errors.tech_tel"
                ></v-text-field>
                <v-text-field
                  label="Дата начала работы"
                  v-model="item.birth"
                  :error-messages="errors.birth"
                ></v-text-field>
                <v-text-field
                  label="Количество клиентов"
                  v-model="item.clients"
                  :error-messages="errors.clients"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  label="URL личного кабинета"
                  type="text"
                  v-model="item.lk_url"
                  :error-messages="errors.lk_url"
                ></v-text-field>
                <v-text-field
                    label="Текст при наведении на логотип (title)"
                    type="text"
                    v-model="item.logo.title"
                ></v-text-field>
                <v-text-field
                    label="Текст в случае непрогрузки логотипа (alt)"
                    type="text"
                    v-model="item.logo.alt"
                ></v-text-field>
                <v-autocomplete
                    v-model="item.logo.value"
                    :items="logos.data"
                    small-chips
                    item-text="name"
                    item-value="url"
                    label="Логотип"
                    clearable
                >
                  <template v-slot:selection="data">
                    <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                    >
                      <v-avatar left>
                        <v-img contain :src="img(data.item.url)"></v-img>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-img contain :src="img(data.item.url)"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-checkbox
                  label="Региональный"
                  v-model="item.regional"
                  :error-messages="errors.regional"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    async mounted () {
      this.clearLogos();
      this.disabled = this.loading = true;
      await this.getLogos();
      await this.getPhones();
      this.disabled = this.loading = false;
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        providers: 'providers/PROVIDERS',
        logos: 'logos/LOGOS',
        phones: 'phones/PHONES',
      }),

      sites_api: function () {
        return process.env.VUE_APP_SITES_API_URL;
      },


      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            name: item.name,
            url: item.url,
            phone_id: item.phone ? item.phone.id : null,
            tech_tel: item.tech_tel,
            birth: item.birth,
            lk_url: item.lk_url,
            logo: this.logo(JSON.parse(item.logo)),
            clients: item.clients,
            regional: item.regional,
          }
        }
        else {
          return {
            name: '',
            url: '',
            tech_tel: '',
            phone_id: '',
            birth: '',
            lk_url: '',
            logo: this.logo(),
            clients: '',
            regional: '',
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getProviders: 'providers/GET_PROVIDERS',
        storeProvider: 'providers/STORE_PROVIDER',
        updateProvider: 'providers/UPDATE_PROVIDER',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getLogos: 'logos/GET_ALL_LOGOS',
        clearLogos: 'logos/CLEAR_LOGOS',
        getPhones: 'phones/GET_ALL_PHONES'
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.name = null;
        this.item.url = null;
        this.item.phone_id = null;
        this.item.tech_tel = null;
        this.item.birth = null;
        this.item.lk_url = null;
        this.item.logo = this.logo();
        this.item.clients = null;
        this.item.regional = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getProviders({ page: this.providers.meta.current_page, per_page: this.providers.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        let data = Object.assign({}, this.item)
        if (!data.regional) data.regional = null;
        if (data.logo.value) {
          if (data.logo.value.endsWith(".svg")) {
            data.logo = JSON.stringify({
              alt: data.logo.alt,
              title: data.logo.title,
              svg: data.logo.value,
              hidpi: {},
              regular: {}
            });
          } else {
            data.logo = JSON.stringify({
              alt: data.logo.alt,
              title: data.logo.title,
              svg: null,
              hidpi: {},
              regular: {
                lg: data.logo.value
              }
            });
          }
        } else {
          data.logo = null;
        }

        if (this.dialog.item) {
          this.updateProvider(data)
            .then(() => {
                this.successActions({message: "Провайдер изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeProvider(data)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Провайдер добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      },

      img(img)  {
        return this.sites_api+img;
      },

      logo: function (logo) {
        if (logo) {
          return {
            value: (logo.svg) ? logo.svg : (logo.regular.lg) ? logo.regular.lg : '',
            alt: (logo.alt) ? logo.alt : '',
            title: (logo.title) ? logo.title : ''
          }
        }
        else {
          return {
            value: '',
            alt: '',
            title: ''
          }
        }
      },
    }
}
</script>