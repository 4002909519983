<template>
  <v-icon class="ml-2" @click="deleteItem(item)" :disabled="disabled" title="Удалить">mdi-trash-can-outline</v-icon>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ["item"],
  computed: {
    ...mapGetters({
      disabled: 'data_table/DISABLED',
      banners: 'banners/BANNERS',
    })
  },
  methods: {
    ...mapActions({
      deleteBanners: 'banners/DELETE_BANNER',
      setDialog: 'dialog/SET_DIALOG_TRUE',
      disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
      nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
      getBanners: 'banners/GET_BANNERS',
      showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
    }),

    deleteItem (item) {
      this.disabledAndLoading();
      var meta = this.banners.meta;
      this.deleteBanners({ name: item.name })
          .then(() => {
            this.setSnackbar({message: "Логотип удалён"});
            if(meta.from === meta.to) {
              this.getBanners({ page: meta.current_page - 1, per_page: meta.per_page })
                  .then(() => {
                    this.nonDisabledAndLoading();
                  });
            } else {
              this.getBanners({ page: meta.current_page, per_page: meta.per_page })
                  .then(() => {
                    this.nonDisabledAndLoading();
                  });
            }
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
    }
  }
}
</script>