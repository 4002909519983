<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete v-if="!dialog.item"
                  v-model="item.tv_box_id"
                  item-text="name"
                  item-value="id"
                  :items="tv_boxes.data"
                  label="ТВ-приставки"
                  :error-messages="errors.tv_box_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-text-field
                  label="Цена"
                  type="text"
                  v-model="item.price"
                  :error-messages="errors.price"
                ></v-text-field>
                <v-text-field
                  label="Зачеркнутая цена"
                  type="text"
                  v-model="item.old_price"
                  :error-messages="errors.old_price"
                ></v-text-field>
                <v-text-field
                  label="Плата за установку"
                  type="text"
                  v-model="item.install_pay"
                  :error-messages="errors.install_pay"
                ></v-text-field>
                <v-checkbox
                  label="Бесплатный"
                  v-model="item.free"
                  :error-messages="errors.free"
                ></v-checkbox>
                <v-checkbox
                  label="В подарок"
                  v-model="item.gift"
                  :error-messages="errors.gift"
                ></v-checkbox>
                <v-text-field
                  label="Приоритет"
                  type="text"
                  v-model="item.priority"
                  :error-messages="errors.priority"
                ></v-text-field>
                <v-checkbox
                  label="Скрытый"
                  v-model="item.hidden"
                  :error-messages="errors.hidden"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getTvBoxes();
      this.nonDisabledAndLoading();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        locality_tarif_tv_boxes: 'locality_tarif_tv_boxes/TV_BOXES',
        tv_boxes: 'tv_boxes/TV_BOXES',
      }),
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            tv_box_id: item.name ? item.name : null,
            price: item.price ? item.price : null,
            old_price: item.old_price ? item.old_price : null,
            install_pay: item.install_pay ? item.install_pay : null,
            free: item.free ? item.free : null,
            gift: item.gift ? item.gift : null,
            priority: item.priority ? item.priority : null,
            hidden: item.hidden ? item.hidden : null,
          }
        }
        else {
          return {
            tv_box_id: '',
            price: '',
            old_price: '',
            install_pay: '',
            free: '',
            gift: '',
            priority: '',
            hidden: '',
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getLocalityTarifTvBoxes: 'locality_tarif_tv_boxes/GET_TV_BOXES',
        storeTvBox: 'locality_tarif_tv_boxes/STORE_TV_BOX',
        updateTvBox: 'locality_tarif_tv_boxes/UPDATE_TV_BOX',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getTvBoxes: 'tv_boxes/GET_ALL_TV_BOXES',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.tv_box_id = null;
        this.item.price = null;
        this.item.old_price = null;
        this.item.install_pay = null;
        this.item.free = null;
        this.item.gift = null;
        this.item.priority = null;
        this.item.hidden = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getLocalityTarifTvBoxes({ locality_id: this.$route.params.locality_id, tarif_id: this.$route.params.id, page: this.tv_boxes.meta.current_page, per_page: this.tv_boxes.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (!this.item.free) this.item.free = null;
        if (!this.item.gift) this.item.gift = null;
        if (!this.item.hidden) this.item.hidden = null;
        this.item["locality_id"] = this.$route.params.locality_id;
        this.item["tarif_id"] = this.$route.params.id;
        if (this.dialog.item) {
          this.item.tv_box_id  = this.item.id;
          this.updateTvBox(this.item)
            .then(() => {
                this.successActions({message: "ТВ-приставка изменена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeTvBox(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "ТВ-приставка добавлена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>