<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete v-if="!dialog.item"
                  v-model="item.locality_id"
                  item-text="name"
                  item-value="id"
                  :items="localities.data"
                  label="Нас. пункт"
                  :error-messages="errors.locality_id"
                  :search-input.sync="search"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.region.name"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-text-field
                  label="Главный заголовок"
                  type="text"
                  autofocus
                  v-model="item.main_head"
                  :error-messages="errors.main_head"
                ></v-text-field>
                <v-text-field
                  label="Дополнительный заголовок"
                  type="text"
                  v-model="item.sub_head"
                  :error-messages="errors.sub_head"
                ></v-text-field>
                <v-autocomplete
                  v-model="item.phone_id"
                  item-text="phone"
                  item-value="id"
                  :items="phones.data"
                  label="Номер телефона"
                  clearable
                  :error-messages="errors.phone_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-checkbox
                  label="Скрытый"
                  v-model="item.hidden"
                  :error-messages="errors.hidden"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import throttle from "@/assets/js/throttle";
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        search: null,
        errors: false,
      }
    },
    async mounted () {
      this.disabledAndLoading();
      // await this.getLocalities();
      await this.getPhones();
      this.nonDisabledAndLoading();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        sites_localities: 'sites_localities/LOCALITIES',
        localities: 'localities/LOCALITIES',
        phones: 'phones/PHONES',
        filter_region_id: 'region_id/REGION_ID',
      }),

      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            locality_id: item.name ? item.name : null,
            main_head: item.main_head,
            sub_head: item.sub_head,
            phone_id: item.phone ? item.phone.id : null,
            hidden: item.hidden,
          }
        }
        else {
          return {
            locality_id: '',
            main_head: '',
            sub_head: '',
            phone_id: '',
            hidden: '',
          }
        }
      }
    },
    watch: {
      search(val) {
        if (val != null) {
          if (val.length > 1) {
            this.searchLocalities(val);
          } else {
            this.clearLocalities();
          }
        }
      },
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getSitesLocalities: 'sites_localities/GET_LOCALITIES',
        storeLocality: 'sites_localities/STORE_LOCALITY',
        updateLocality: 'sites_localities/UPDATE_LOCALITY',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getLocalities: 'localities/GET_LOCALITIES',
        clearLocalities: 'localities/CLEAR_LOCALITIES',
        getPhones: 'phones/GET_ALL_PHONES'
      }),

      searchLocalities: throttle(function (val) {
        this.loading = true;
        this.getLocalities({
          searchFilter: {
            name: val
          },
          per_page: 100
        }).then(() => {
          this.loading = false;
        });
      }, 700),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.locality_id = null;
        this.item.main_head = null;
        this.item.sub_head = null;
        this.item.phone_id = null;
        this.item.hidden = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        let params = { site_id: this.$route.params.id, page: this.sites_localities.meta.current_page, per_page: this.sites_localities.meta.per_page };
        if (this.filter_region_id) {
          params.region_id = this.filter_region_id;
        }
        this.getSitesLocalities(params)
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (this.item.phone_id === undefined) this.item.phone_id = null;
        if (!this.item.hidden) this.item.hidden = null;
        this.item["site_id"] = this.$route.params.id;
        if (this.dialog.item) {
          this.item.locality_id  = this.item.id;
          this.updateLocality(this.item)
            .then(() => {
                this.successActions({message: "Нас. пункт изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeLocality(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Нас. пункт добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>
