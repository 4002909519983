<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="tarifs.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.hidden`]="{ item }" disabled>
      <div v-if="item.hidden"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="tarifs.meta.from">
      <TablePagination :items="tarifs" action="sites_localities_tarifs/GET_TARIFS" :add_params="params" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Sites/relations/Localities/relations/Tarifs/components/Top'
  import Actions from '@/components/Sites/relations/Localities/relations/Tarifs/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Тариф', value: 'name', sortable: false },
          { text: 'Скорость', value: 'speed.speed', sortable: false },
          { text: 'Цена', value: 'price', sortable: false },
          { text: 'Стоимость подключения', value: 'podkl_price', sortable: false },
          { text: 'Кол-во каналов', value: 'sum_channels', sortable: false },
          { text: 'Моб. интернет', value: 'mobile', sortable: false },
          { text: 'Акция', value: 'action.name', sortable: false },
          { text: 'Опция', value: 'option.name', sortable: false },
          { text: 'Технология', value: 'tech.name', sortable: false },
          { text: 'Провайдер', value: 'provider.name', sortable: false },
          { text: 'Категория', value: 'category.name', sortable: false },
          { text: 'Скрытый', value: 'hidden', sortable: false },
          { text: 'Приоритет', value: 'priority', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
        check: 'mdi-check',
      }
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getTarifs({site_id: this.site_id, locality_id: this.locality_id});
      this.nonDisabledAndLoading();
    },
    destroyed () {
      this.clearTarifs();
    },
    computed: {
      ...mapGetters({
        tarifs: 'sites_localities_tarifs/TARIFS',
        table_loading: 'data_table/TABLE_LOADING',
        provider_id: 'provider_id/PROVIDER_ID',
        category_id: 'category_id/CATEGORY_ID'
      }),
      site_id: function () {
        return this.$route.params.site_id;
      },
      locality_id: function () {
        return this.$route.params.id;
      },
      params: function () {
        let params = {site_id: this.site_id, locality_id: this.locality_id};
        if (this.provider_id) {
          params.provider_id = this.provider_id;
        }
        if (this.category_id) {
          params.category_id = this.category_id;
        }
        return params;
      }
    },
    methods: {
      ...mapActions({
        getTarifs: 'sites_localities_tarifs/GET_TARIFS',
        clearTarifs: 'sites_localities_tarifs/CLEAR_TARIFS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>