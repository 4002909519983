<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="reviews.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
    show-expand
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:expanded-item="{ item }">
      <Expanded :item="item"/>
    </template>

    <template v-slot:[`item.emotion`]="{ item }" disabled>
      <div v-if="item.emotion"><v-icon :color="emotion[item.emotion].color">{{ emotion[item.emotion].icon }}</v-icon></div>
    </template>


    <template v-slot:[`item.selfmade`]="{ item }" disabled>
      <div v-if="item.selfmade"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.hidden`]="{ item }" disabled>
      <div v-if="item.hidden"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.approved`]="{ item }" disabled>
      <div v-if="item.approved"><v-icon>{{ check }}</v-icon></div>
      <div v-else-if="item.approved != null"><v-icon>{{ nocheck }}</v-icon></div>
      <div v-else><v-icon>{{ unknown }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="reviews.meta.from">
      <TablePagination :items="reviews" action="reviews/GET_REVIEWS" :add_params="params" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Reviews/components/Top'
  import Expanded from '@/components/Reviews/components/Expanded/Expanded'
  import Actions from '@/components/Reviews/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Expanded,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Имя', value: 'name', sortable: false },
          // { text: 'Заголовок', value: 'title', sortable: false },
          // { text: 'Текст', value: 'text', sortable: false },
          { text: 'Характер', value: 'emotion', sortable: false },
          { text: 'Selfmade', value: 'selfmade', sortable: false },
          { text: 'Улица', value: 'street', sortable: false },
          { text: 'Дом', value: 'house', sortable: false },
          { text: 'Приоритет', value: 'priority', sortable: false },
          { text: 'Скрытый', value: 'hidden', sortable: false },
          { text: 'Одобрен', value: 'approved', sortable: false },
          { text: 'Нас. пункт', value: 'locality.name', sortable: false },
          { text: 'Провайдер', value: 'provider.name', sortable: false },
          { text: 'Тип строения', value: 'building_type.name', sortable: false },
          { text: 'Общая услуга', value: 'general_service.name', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
        check: 'mdi-check',
        nocheck: 'mdi-close',
        unknown: 'mdi-help',
        emotion: {
          1: {
            icon: 'mdi-emoticon-happy-outline',
            color: 'green'
          },
          2: {
            icon: 'mdi-emoticon-neutral-outline',
            color: 'orange'
          },
          3: {
            icon: 'mdi-emoticon-sad-outline',
            color: 'red'
          }
        },
      }
    },
    created() {
      this.clearReviews();
    },
    mounted () {
      this.getReviews();
    },
    destroyed () {
      this.clearReviews();
      this.clearFilterDialog();
    },
    computed: {
      ...mapGetters({
        reviews: 'reviews/REVIEWS',
        table_loading: 'data_table/TABLE_LOADING',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
      }),
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        return params;
      }
    },
    methods: {
      ...mapActions({
        getReviews: 'reviews/GET_REVIEWS',
        clearReviews: 'reviews/CLEAR_REVIEWS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        clearFilterDialog: 'filter_dialog/CLEAR_FILTER_DIALOG'
      })
    }
  }
</script>
