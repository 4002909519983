import axios from 'axios'

export default({
  namespaced: true,

  state: {
    regions: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    REGIONS (state){
      return state.regions;
    }
  },

  mutations: {
    SET_REGIONS (state, data) {
      state.regions = data;
    },

    CLEAR_REGIONS (state) {
      state.regions = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_REGIONS: async (context, params) => {
      var site_id = params.site_id;
      delete params.site_id;
      var params_str;
      params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      let {data} = await axios.get('/sites/' + site_id + '/regions?' + params_str);
      context.commit('SET_REGIONS', data);
    },

    CLEAR_REGIONS (context) {
      context.commit('CLEAR_REGIONS');
    },

    STORE_REGION: async (context, params) => {
      var site_id = params.site_id;
      delete params.site_id;
      await axios.post('/sites/' + site_id + '/regions', params);
    },

    UPDATE_REGION: async (context, params) => {
      var site_id = params.site_id;
      delete params.site_id;
      await axios.put('/sites/' + site_id + '/regions/' + params.id, params);
    },

    DELETE_REGION: async (context, params) => {
      await axios.delete('/sites/' + params.site_id + '/regions/' + params.id);
    }
  }
});
