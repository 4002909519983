export default({
  namespaced: true,

  state: {
    category_id: null
  },

  getters: {
    CATEGORY_ID (state){
      return state.category_id;
    }
  },

  mutations: {
    SET_CATEGORY_ID (state, data) {
      state.category_id = data;
    },

    CLEAR_CATEGORY_ID (state) {
      state.category_id = null;
    }
  },

  actions: {
    SET_CATEGORY_ID: async (context, params) => {
      context.commit('SET_CATEGORY_ID', params);
    },

    CLEAR_CATEGORY_ID (context) {
      context.commit('CLEAR_CATEGORY_ID');
    },
  }
});
