<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item" >
            <div v-if="dialog.mode">
              <v-icon small class="mr-2">mdi-content-copy</v-icon>
              {{dialog.mode}}
            </div>
            <div v-else>Редактировать</div>
          </span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Название"
                  type="text"
                  autofocus
                  v-model="item.name"
                  :error-messages="errors.name"
                ></v-text-field>
                <v-text-field
                  label="Описание"
                  type="text"
                  v-model="item.description"
                  :error-messages="errors.description"
                ></v-text-field>
                <v-autocomplete
                  v-model="item.provider_id"
                  item-text="name"
                  item-value="id"
                  :items="providers.data"
                  label="Провайдер"
                  :error-messages="errors.provider_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    mounted () {
      this.disabledAndLoading();
      this.getProviders()
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        actions: 'actions/ACTIONS',
        providers: 'providers/PROVIDERS',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
      }),

      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
            provider_id: item.provider ? item.provider.id : null,
          }
        }
        else {
          return {
            name: '',
            description: '',
            provider_id: '',
          }
        }
      },
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        params.page = this.actions.meta.current_page;
        params.per_page = this.actions.meta.per_page;
        return params;
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getActions: 'actions/GET_ACTIONS',
        storeAction: 'actions/STORE_ACTION',
        updateAction: 'actions/UPDATE_ACTION',
        copyAction: 'actions/COPY_ACTION',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getProviders: 'providers/GET_ALL_PROVIDERS'
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.name = null;
        this.item.description = null;
        this.item.provider_id = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getActions(this.params)
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (this.dialog.item) {
          if (this.dialog.mode) {
            this.copyAction(this.item)
              .then(() => {
                this.successActions({message: "Акция скопирована"});
              })
              .catch(e => {
                if (e.response) {
                  this.errors = e.response.data.errors;
                } else {
                  this.setSnackbar({message: "Ошибка сервера", color: "red"});
                }
              })
              .finally(() => {
                  this.disabled = this.loading = false;
              });
          } else {
            this.updateAction(this.item)
              .then(() => {
                this.successActions({message: "Акция изменена"});
              })
              .catch(e => {
                if (e.response) {
                  this.errors = e.response.data.errors;
                } else {
                  this.setSnackbar({message: "Ошибка сервера", color: "red"});
                }
              })
              .finally(() => {
                this.disabled = this.loading = false;
              });
          }
        }
        else {
          this.storeAction(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Акция добавлена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>
