<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item" >
            <div v-if="dialog.mode">
              <v-icon small class="mr-2">mdi-content-copy</v-icon>
              {{dialog.mode}}
            </div>
            <div v-else>Редактировать</div>
          </span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete
                  v-model="item.provider_id"
                  item-text="name"
                  item-value="id"
                  :items="providers.data"
                  label="Провайдер"
                  :error-messages="errors.provider_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-text-field
                  label="Название"
                  type="text"
                  autofocus
                  v-model="item.name"
                  :error-messages="errors.name"
                ></v-text-field>
                <v-textarea
                  rows="1"
                  auto-grow
                  label="Описание"
                  v-model="item.description"
                  :error-messages="errors.description"
                ></v-textarea>
                <v-checkbox
                  label="Дополнительный"
                  v-model="item.is_extension"
                  :error-messages="errors.is_extension"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
        channels_packages: 'channels_packages/CHANNELS_PACKAGES',
        providers: 'providers/PROVIDERS',
      }),

      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            provider_id: item.provider.id,
            name: item.name,
            description: item.description ? item.description : null,
            is_extension: item.is_extension ? item.is_extension : null,
          }
        }
        else {
          return {
            provider_id: '',
            name: '',
            description: '',
            is_extension: null,
          }
        }
      },
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        params.page = this.channels_packages.meta.current_page;
        params.per_page = this.channels_packages.meta.per_page;
        return params;
      },
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getChannelsPackages: 'channels_packages/GET_CHANNELS_PACKAGES',
        storeChannelsPackage: 'channels_packages/STORE_CHANNELS_PACKAGE',
        updateChannelsPackage: 'channels_packages/UPDATE_CHANNELS_PACKAGE',
        copyChannelsPackage: 'channels_packages/COPY_CHANNELS_PACKAGE',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.provider_id = null;
        this.item.name = null;
        this.item.description = null;
        this.item.is_extension = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.disabledAndLoading();

        this.getChannelsPackages(this.params)
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (!this.item.is_extension) this.item.is_extension = false;
        if (this.dialog.item) {
          if (this.dialog.mode) {
            this.copyChannelsPackage(this.item)
            .then(() => {
                this.successActions({message: "Пакет каналов скопирован"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
          } else {
            this.updateChannelsPackage(this.item)
            .then(() => {
                this.successActions({message: "Пакет каналов изменён"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
          }
        }
        else {
          this.storeChannelsPackage(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Пакет каналов добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>
