<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-row>
                  <v-col
                      cols="6"
                      sm="6"
                      md="6"
                  >
                    <v-autocomplete
                      v-model="item.site_id"
                      auto-select-first
                      item-text="url"
                      item-value="id"
                      :items="sites.data"
                      label="Сайт"
                      :error-messages="errors.site_id || errors.url"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Ничего не найдено
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <v-autocomplete
                      v-model="item.provider_id"
                      item-text="name"
                      item-value="id"
                      :items="providers.data"
                      label="Провайдер"
                      :error-messages="errors.provider_id || errors.url"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Ничего не найдено
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <v-autocomplete
                      v-model="item.category_id"
                      item-text="name"
                      item-value="id"
                      :items="categories.data"
                      label="Категория тарифов (необязательно)"
                      clearable
                      :error-messages="errors.category_id"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Ничего не найдено
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                            <v-list-item-subtitle v-html="data.item.url"></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      v-model="item.seo_category_service_id"
                      item-text="name"
                      item-value="id"
                      :items="seo_services_categories.data"
                      label="Категория СЕО-услуги"
                      :error-messages="errors.seo_category_service_id"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Ничего не найдено
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <v-autocomplete
                      v-model="item.service_id"
                      item-text="name"
                      item-value="id"
                      :items="services.data"
                      label="Связь с услугой (необязательно)"
                      clearable
                      :error-messages="errors.service_id"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Ничего не найдено
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-img
                      :src="preview"
                      max-height="150"
                      contain
                    >
                      <template v-slot:default>
                        <v-row
                          class="fill-height ma-0"
                          align="end"
                          justify="end"
                        >
                          <v-icon small class="ml-2" @click="deletePreviewImg()" title="Удалить">mdi-delete</v-icon>
                        </v-row>
                      </template>
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-file-input
                      accept="image/png, image/jpeg, image/jpg"
                      @change="previewImageAdded"
                      prepend-icon=""
                      prepend-inner-icon="mdi-download"
                      filled
                      dense
                      single-line
                      label="Изображение на превью"
                      :error-messages="errors.preview"
                      :clearable="false"
                    >
                        <template v-slot:selection>
                            <label class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Изображение на превью</label>
                        </template>
                    </v-file-input>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Название в карточке"
                      type="text"
                      v-model="item.name"
                      :error-messages="errors.name"
                    ></v-text-field>
                    <v-textarea
                      rows="1"
                      auto-grow
                      label="Описание в карточке"
                      v-model="item.short_desc"
                      :error-messages="errors.short_desc"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-img
                        :src="img"
                        max-height="150"
                        contain
                    >
                      <template v-slot:default>
                        <v-row
                          class="fill-height ma-0"
                          align="end"
                          justify="end"
                        >
                            <v-icon small class="ml-2" @click="deleteImg()" title="Удалить">mdi-delete</v-icon>
                        </v-row>
                      </template>
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-file-input
                      accept="image/png, image/jpeg, image/jpg"
                      @change="ImageAdded"
                      prepend-icon=""
                      prepend-inner-icon="mdi-download"
                      filled
                      dense
                      single-line
                      label="Изображение"
                      :error-messages="errors.img"
                      :clearable="false"
                    >
                      <template v-slot:selection>
                        <label class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Изображение</label>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Полное наименование (h1)"
                      type="text"
                      v-model="item.title"
                      :error-messages="errors.title"
                    ></v-text-field>
                    <v-textarea
                      rows="1"
                      auto-grow
                      label="Описание (h2)"
                      v-model="item.description"
                      :error-messages="errors.description"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-checkbox
                      label="Скрытая"
                      v-model="item.is_hidden"
                      :error-messages="errors.is_hidden"
                    ></v-checkbox>
                    <v-textarea
                      rows="1"
                      auto-grow
                      label="Meta заголовок"
                      v-model="item.meta_title"
                      :error-messages="errors.meta_title"
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-checkbox
                      label="Для юр. лиц"
                      v-model="item.is_business"
                      :error-messages="errors.is_business || errors.url"
                    ></v-checkbox>
                    <v-textarea
                      rows="1"
                      auto-grow
                      label="Meta описание"
                      v-model="item.meta_description"
                      :error-messages="errors.meta_description"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
        preview: require(`@/assets/img/no-img.png`),
        img: require(`@/assets/img/no-img.png`),
      }
    },
    created() {
      this.clearSites();
      this.clearProviders();
      this.clearSeoServicesCategories();
      this.clearServices();
      this.clearCategories();
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getSites();
      await this.getProviders();
      await this.getSeoServicesCategories();
      await this.getServices();
      await this.getCategories();
      this.nonDisabledAndLoading();
    },
    destroyed () {
      this.clearSites();
      this.clearProviders();
      this.clearSeoServicesCategories();
      this.clearServices();
      this.clearCategories();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
        seo_services: 'seo_services/SEO_SERVICES',
        sites: 'sites/SITES',
        providers: 'providers/PROVIDERS',
        seo_services_categories: 'seo_services_categories/SEO_SERVICES_CATEGORIES',
        categories: 'categories/CATEGORIES',
        services: 'services/SERVICES',
      }),

      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            site_id: item.site.id,
            provider_id: item.provider.id,
            seo_category_service_id: item.category ? item.category.id : null,
            service_id: item.service ? item.service.id : null,
            category_id: item.tariff_category ? item.tariff_category.id : null,
            name: item.name,
            short_desc: item.short_desc,
            preview: item.preview,
            title: item.title,
            meta_title: item.meta_title,
            description: item.description,
            meta_description: item.meta_description,
            img: item.img,
            is_business: item.is_business ? item.is_business : null,
            is_hidden: item.is_hidden ? item.is_hidden : null,
          }
        }
        else {
          return {
            site_id: '',
            provider_id: '',
            seo_category_service_id: '',
            service_id: '',
            tariff_category: '',
            name: '',
            short_desc: '',
            preview: '',
            title: '',
            meta_title: '',
            description: '',
            meta_description: '',
            img: '',
            is_business: null,
            is_hidden: null,
          }
        }
      },
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        params.page = this.seo_services.meta.current_page;
        params.per_page = this.seo_services.meta.per_page;
        return params;
      },
    },
    watch: {
      'dialog.item': function () {
        this.preview = this.dialog.item ? this.dialog.item.preview : require(`@/assets/img/no-img.png`);
        this.img = this.dialog.item ?  this.dialog.item.img : require(`@/assets/img/no-img.png`);
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getSeoServices: 'seo_services/GET_SEO_SERVICES',
        storeSeoService: 'seo_services/STORE_SEO_SERVICE',
        updateSeoService: 'seo_services/UPDATE_SEO_SERVICE',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getSites: 'sites/GET_ALL_SITES',
        getProviders: 'providers/GET_ALL_PROVIDERS',
        getSeoServicesCategories: 'seo_services_categories/GET_ALL_SEO_SERVICES_CATEGORIES',
        getServices: 'services/GET_ALL_SERVICES',
        getCategories: 'categories/GET_ALL_CATEGORIES',
        clearSites: 'sites/CLEAR_SITES',
        clearProviders: 'providers/CLEAR_PROVIDERS',
        clearSeoServicesCategories: 'seo_services_categories/CLEAR_SEO_SERVICES_CATEGORIES',
        clearServices: 'services/CLEAR_SERVICES',
        clearCategories: 'categories/CLEAR_CATEGORIES',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.site_id= null;
        this.item.provider_id = null;
        this.item.seo_category_service_id = null;
        this.item.service_id = null;
        this.item.category_id = null;
        this.item.name = null;
        this.item.short_desc = null;
        this.item.title = null;
        this.item.meta_title = null;
        this.item.description = null;
        this.item.meta_description = null;
        this.item.is_business = null;
        this.item.is_hidden = null;
        this.deletePreviewImg();
        this.deleteImg();
      },

      async successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();

        await this.getSeoServices(this.params);

        this.nonDisabledAndLoading();
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (!this.item.service_id) this.item.service_id = null;
        if (!this.item.category_id) this.item.category_id = null;
        if (!this.item.is_hidden) this.item.is_hidden = false;
        if (!this.item.is_business) this.item.is_business = false;
        if (this.dialog.item) {
          this.updateSeoService(this.item)
            .then(() => {
                this.successActions({message: "СЕО-услуга изменена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeSeoService(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "СЕО-услуга добавлена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      },
      previewImageAdded: function(file) {
        if (typeof file === 'object') {
          var formData = new FormData();
          formData.append("image", file);
          axios({
            url: process.env.VUE_APP_BASE_URL + 'api/image/seo-services/preview',
            method: "POST",
            data: formData
          })
            .then(result => {
              this.item.preview = result.data;
              this.preview = result.data;
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          this.item.preview = null;
          this.preview = require(`@/assets/img/no-img.png`);
        }
      },
      ImageAdded: function(file) {
        if (typeof file === 'object') {
          var formData = new FormData();
          formData.append("image", file);
          axios({
            url: process.env.VUE_APP_BASE_URL + 'api/image/seo-services/preview',
            method: "POST",
            data: formData
          })
            .then(result => {
              this.item.img = result.data;
              this.img = result.data;
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          this.item.img = null;
          this.img = require(`@/assets/img/no-img.png`);
        }
      },
      deletePreviewImg: function (){
        this.item.preview = null;
        this.preview = require(`@/assets/img/no-img.png`);
      },
      deleteImg: function (){
        this.item.img = null;
        this.img = require(`@/assets/img/no-img.png`);
      }
    }
}
</script>
