<template>
  <div style="white-space: nowrap">
<!--    <v-btn link :to="{name: 'locality-provider-tarif-routers', params: { locality_id: this.$route.params.locality_id, provider_id: this.$route.params.id, id: item.id }}" icon color="gray" small rounded class="mr-2" title="Роутеры"><v-icon>mdi-router-wireless</v-icon></v-btn>-->
<!--    <v-btn link :to="{name: 'locality-provider-tarif-tv-boxes', params: { locality_id: this.$route.params.locality_id, provider_id: this.$route.params.id, id: item.id }}" icon color="gray" small rounded class="mr-2" title="ТВ-приставки"><v-icon>mdi-television-box</v-icon></v-btn>-->
    <v-btn :disabled="disabled" link :to="{name: 'locality-provider-tarif-options', params: { locality_id: this.$route.params.locality_id, provider_id: this.$route.params.id, id: item.id }}" icon color="gray" small rounded class="mr-2" title="Опции"><v-icon>mdi-controller-classic</v-icon></v-btn>
    <v-icon small class="mr-2" @click="editItem(item)" :disabled="disabled" title="Редактировать">mdi-pencil</v-icon>
    <v-icon small class="mr-2" @click="deleteItem(item)" :disabled="disabled">mdi-delete</v-icon>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        tarifs: 'locality_tarifs/TARIFS',
      })
    },
    methods: {
      ...mapActions({
        deleteTarif: 'locality_tarifs/DELETE_TARIF',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getTarifs: 'locality_tarifs/GET_TARIFS',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        var meta = this.tarifs.meta;
        this.deleteTarif({ id: item.id, locality_id: this.$route.params.locality_id, provider_id: this.$route.params.id })
            .then(() => {
              this.setSnackbar({message: "Тариф удален"});
              let current_page;
              if (meta.from === meta.to) {
                current_page = meta.current_page - 1;
              } else {
                current_page = meta.current_page;
              }
              let params = { locality_id: this.$route.params.locality_id, provider_id: this.$route.params.id, page: current_page, per_page: meta.per_page };
              this.getTarifs(params)
                  .then(() => {
                    this.nonDisabledAndLoading();
                  });
            })
            .catch( e => {
              this.showErrorConflict({response: e.response, obj: this});
            });
      },
    }
  }
</script>
