<template>
  <v-toolbar
    flat
  >
    <v-toolbar-title>Адреса офисов</v-toolbar-title>
    <v-divider
      class="mx-4"
      inset
      vertical
    ></v-divider>
    <v-text-field
        label="Название нас. пункта"
        dense
        outlined
        clearable
        hide-details
        v-model="searchField"
    ></v-text-field>
    <v-spacer></v-spacer>

    <v-btn
      color="primary"
      class="mb-2"
      :disabled="disabled"
      @click.stop="openDialog()"
    >
      Добавить
    </v-btn>

    <Dialog />

  </v-toolbar>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Dialog from '@/components/Localities/Addresses/components/Dialog'
  import throttle from "@/assets/js/throttle";

  export default {
    components: {
      Dialog
    },
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        search: 'search/SEARCH'
      }),

      searchField: {
        get() {
          return this.$attrs.value;
        },
        set(val) {
          if (val === undefined) {
            this.setSearch(null);
          } else {
            this.setSearch(val);
          }
        },
      },
      params: function () {
        var params = {};
        if (this.search) {
          params.searchFilter = {
            "city": this.search
          }
        }
        return params;
      }
    },
    watch: {
      search: throttle(function () {
        this.disabledAndLoading();
        this.getAddresses(this.params)
            .then(() => {
              this.nonDisabledAndLoading();
            });
      }, 1000),
    },
    methods: {
      ...mapActions({
        setDialog: 'dialog/SET_DIALOG_TRUE',
        getAddresses: 'addresses/GET_ADDRESSES',
        setSearch: 'search/SET_SEARCH',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      }),
      openDialog() {
        this.setDialog();
      },
    }
  }
</script>