<template>
  <v-toolbar
    flat
  >
    <v-toolbar-title v-if="provider">{{provider.data.name}}: Баннеры</v-toolbar-title>
    <v-divider
      class="mx-4"
      inset
      vertical
    ></v-divider>
    <v-spacer></v-spacer>

    <v-btn
      color="primary"
      class="mb-2"
      :disabled="disabled"
      @click.stop="openDialog()"
    >
      Добавить
    </v-btn>

    <Dialog />

  </v-toolbar>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Dialog from '@/components/Providers/Providers/relations/Banners/components/Dialog'

  export default {
    components: {
      Dialog
    },
    mounted () {
      this.getProvider(this.$route.params.id);
    },
    destroyed () {
      this.clearProvider();
    },
    computed: {
      ...mapGetters({
        provider: 'providers/PROVIDER',
        disabled: 'data_table/DISABLED',
      }),
    },
    methods: {
      ...mapActions({
        setDialog: 'dialog/SET_DIALOG_TRUE',
        getProvider: 'providers/GET_PROVIDER',
        clearProvider: 'providers/CLEAR_PROVIDER',
      }),
      openDialog() {
        this.setDialog();
      },
    }
  }
</script>