import { authGuardFail } from '../guards.js'

import Sites from '../../views/Sites/Sites.vue'
import Regions from '../../views/Sites/Regions.vue'
import Localities from '../../views/Sites/Localities/Localities.vue'
import Providers from '../../views/Sites/Providers.vue'
import Tarifs from '../../views/Sites/Tarifs.vue'
import Categories from '../../views/Sites/Categories.vue'
import QuestionsAnswers from '../../views/Sites/Localities/QuestionsAnswers/QuestionsAnswers.vue'

export default [
  {
    path: '/sites',
    name: 'sites',
    component: Sites,
    beforeEnter: authGuardFail
  },
  {
    path: '/sites/:id/regions',
    name: 'site-regions',
    component: Regions,
    beforeEnter: authGuardFail
  },
  {
    path: '/sites/:id/localities',
    name: 'site-localities',
    component: Localities,
    beforeEnter: authGuardFail
  },
  {
    path: '/sites/:id/providers',
    name: 'site-providers',
    component: Providers,
    beforeEnter: authGuardFail
  },
  // {
  //   path: '/sites/:site_id/localities/:id/providers',
  //   name: 'site-localities-providers',
  //   component: Providers,
  //   beforeEnter: authGuardFail
  // },
  {
    path: '/sites/:site_id/localities/:id/tarifs',
    name: 'site-localities-tarifs',
    component: Tarifs,
    beforeEnter: authGuardFail
  },
  {
    path: '/sites/:site_id/localities/:id/questions-answers',
    name: 'site-locality-questions-answers',
    component: QuestionsAnswers,
    beforeEnter: authGuardFail
  },
  {
    path: '/sites/:site_id/localities/:loc_id/providers/:id/categories',
    name: 'site-localities-providers-categories',
    component: Categories,
    beforeEnter: authGuardFail
  },
]
