export default({
  namespaced: true,

  state: {
    provider_id: null
  },

  getters: {
    PROVIDER_ID (state){
      return state.provider_id;
    }
  },

  mutations: {
    SET_PROVIDER_ID (state, data) {
      state.provider_id = data;
    },

    CLEAR_PROVIDER_ID (state) {
      state.provider_id = null;
    }
  },

  actions: {
    SET_PROVIDER_ID (context, params) {
      context.commit('SET_PROVIDER_ID', params);
    },

    CLEAR_PROVIDER_ID (context) {
      context.commit('CLEAR_PROVIDER_ID');
    },
  }
});
