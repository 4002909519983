import axios from 'axios'

export default({
    namespaced: true,

    state: {
        building_types: {
            data: [],
            meta: {
                current_page: null,
                from: null,
                last_page: null,
                path: null,
                per_page: null,
                to: null,
                total: null
            }
        },
        building_type: null
    },

    getters: {
        BUILDING_TYPES (state) {
            return state.building_types;
        },
        BUILDING_TYPE (state) {
            return state.building_type;
        }
    },

    mutations: {
        SET_BUILDING_TYPES (state, data) {
            state.building_types = data;
        },

        SET_BUILDING_TYPE (state, data) {
            state.building_type = data;
        },

        CLEAR_BUILDING_TYPES (state) {
            state.building_types = {
                data: [],
                meta: {
                    current_page: null,
                    from: null,
                    last_page: null,
                    path: null,
                    per_page: null,
                    to: null,
                    total: null
                }
            }
        },

        CLEAR_BUILDING_TYPE (state) {
            state.building_type = null;
        }
    },

    actions: {
        GET_BUILDING_TYPES: async (context, params) => {
            var params_str;
            if (params) {
                params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                let {data} = await axios.get('/building-type?' + params_str);
                context.commit('SET_BUILDING_TYPES', data);
            } else {
                let {data} = await axios.get('/building-type');
                context.commit('SET_BUILDING_TYPES', data);
            }
        },

        GET_ALL_BUILDING_TYPES: async (context) => {
            let {data} = await axios.get('/building-type?per_page=1000&sort_by=name');
            context.commit('SET_BUILDING_TYPES', data);
        },

        GET_BUILDING_TYPE: async (context, params) => {
            let {data} = await axios.get('/building-type/' + params);
            context.commit('SET_BUILDING_TYPE', data);
        },

        CLEAR_BUILDING_TYPES (context) {
            context.commit('CLEAR_BUILDING_TYPES');
        },

        CLEAR_BUILDING_TYPE (context) {
            context.commit('CLEAR_BUILDING_TYPE');
        },

        STORE_BUILDING_TYPE: async (context, params) => {
            await axios.post('/building-type', params);
        },

        UPDATE_BUILDING_TYPE: async (context, params) => {
            await axios.put('/building-type/' + params.id, params);
        },

        DELETE_BUILDING_TYPE: async (context, params) => {
            await axios.delete('/building-type/' + params.id);
        }
    }
});
