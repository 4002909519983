import axios from 'axios'

export default({
  namespaced: true,

  state: {
    addresses: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    ADDRESSES (state){
      return state.addresses;
    }
  },

  mutations: {
    SET_ADDRESSES (state, data) {
      state.addresses = data;
    },

    CLEAR_ADDRESSES (state) {
      state.addresses = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    },
  },

  actions: {
    GET_ADDRESSES: async (context, params) => {
      if (params) {
        let {data} = await axios.get('/addresses?', {params: params});
        context.commit('SET_ADDRESSES', data);
      } else {
        let {data} = await axios.get('/addresses');
        context.commit('SET_ADDRESSES', data);
      }
    },

    GET_ALL_ADDRESSES: async (context) => {
      let {data} = await axios.get('/addresses?per_page=10000&sort_by=id');
      context.commit('SET_ADDRESSES', data);
    },

    CLEAR_ADDRESSES (context) {
      context.commit('CLEAR_ADDRESSES');
    },

    STORE_ADDRESS: async (context, params) => {
      await axios.post('/addresses', params);
    },

    UPDATE_ADDRESS: async (context, params) => {
      await axios.put('/addresses/' + params.id, params);
    },

    DELETE_ADDRESS: async (context, params) => {
      await axios.delete('/addresses/' + params.id);
    }
  }
});
