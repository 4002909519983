import { authGuardFail } from '../guards.js'

import Genres from '../../views/Channels/Genres.vue'
import Resolutions from '../../views/Channels/Resolutions.vue'
import ChannelsPackages from '../../views/Channels/ChannelsPackages/ChannelsPackages.vue'
import Channels from '../../views/Channels/Channels.vue'
import ChannelsPackageChannels from '../../views/Channels/ChannelsPackages/Channels.vue'

export default [
  {
    path: '/genres',
    name: 'genres',
    component: Genres,
    beforeEnter: authGuardFail
  },
  {
    path: '/resolutions',
    name: 'resolutions',
    component: Resolutions,
    beforeEnter: authGuardFail
  },
  {
      path: '/channels',
      name: 'channels',
      component: Channels,
      beforeEnter: authGuardFail
  },
  {
    path: '/channels-packages',
    name: 'channels-packages',
    component: ChannelsPackages,
    beforeEnter: authGuardFail
  },
  {
      path: '/channels-packages/:id/channels',
      name: 'channels-package-channels',
      component: ChannelsPackageChannels,
      beforeEnter: authGuardFail
  },
]
