<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-row>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-img
                      :src="img"
                      max-height="150"
                      contain
                    >
                      <template v-slot:default>
                        <v-row
                          class="fill-height ma-0"
                          align="start"
                          justify="start"
                        >
                          <v-file-input
                            accept="image/png, image/jpeg, image/jpg"
                            @change="ImageAdded"
                            prepend-icon="mdi-download"
                            hide-input
                            :error-messages="errors.img"
                          ></v-file-input>
                        </v-row>
                      </template>
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                        v-model="item.genre_id"
                        auto-select-first
                        item-text="name"
                        item-value="id"
                        :items="genres.data"
                        label="Жанр"
                        :error-messages="errors.genre_id"
                    >
                        <template v-slot:no-data>
                            <v-list-item>
                                <v-list-item-title>
                                    Ничего не найдено
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete
                        v-model="item.resolution_id"
                        auto-select-first
                        item-text="name"
                        item-value="id"
                        :items="resolutions.data"
                        label="Разрешение"
                        :error-messages="errors.resolution_id"
                    >
                        <template v-slot:no-data>
                            <v-list-item>
                                <v-list-item-title>
                                    Ничего не найдено
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-text-field
                  label="Название"
                  type="text"
                  autofocus
                  v-model="item.name"
                  :error-messages="errors.name"
                ></v-text-field>
                <v-textarea
                  rows="1"
                  auto-grow
                  label="Описание"
                  v-model="item.description"
                  :error-messages="errors.description"
                ></v-textarea>
                <v-checkbox v-if="!dialog.item"
                  dense
                  label="Добавить канал всем населённым пунктам"
                  v-model="item.is_localities"
                  color="orange darken-3"
                  :messages="'Запрос будет обрабатываться чуть дольше обычного'"
                  :error-messages="errors.is_localities"
                >
                    <template v-slot:message="{ message }">
                        <div v-if="item.is_localities">{{ message }}</div>
                        <div v-else></div>
                    </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from "axios";
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
        img: require(`@/assets/img/no-img.png`),
      }
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
        channels: 'channels/CHANNELS',
        genres: 'genres/GENRES',
        resolutions: 'resolutions/RESOLUTIONS',
      }),

      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            genre_id: item.genre.id,
            resolution_id: item.resolution.id,
            name: item.name,
            description: item.description ? item.description : null,
            img: item.img,
          }
        }
        else {
          return {
            genre_id: '',
            resolution_id: '',
            name: '',
            description: '',
            img: '',
            is_localities: null
          }
        }
      },
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        params.page = this.channels.meta.current_page;
        params.per_page = this.channels.meta.per_page;
        return params;
      }
    },
    watch: {
      'dialog.item': function () {
        this.img = this.dialog.item ? this.dialog.item.img : require(`@/assets/img/no-img.png`);
      },
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getChannels: 'channels/GET_CHANNELS',
        storeChannel: 'channels/STORE_CHANNEL',
        updateChannel: 'channels/UPDATE_CHANNEL',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.genre_id = null;
        this.item.resolution_id = null;
        this.item.name = null;
        this.item.description = null;
        this.item.img = null;
        this.item.is_localities = null;
        this.img = require(`@/assets/img/no-img.png`);
      },

      async successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.disabledAndLoading();

        await this.getChannels(this.params);

        this.nonDisabledAndLoading();
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (this.dialog.item) {
          this.updateChannel(this.item)
            .then(() => {
                this.successActions({message: "Канад изменён"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          if (!this.item.is_localities) this.item.is_localities = null;
          this.storeChannel(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Канал добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      },
      ImageAdded: function(file) {
        if (typeof file === 'object') {
          var formData = new FormData();
          formData.append("image", file);
          axios({
            url: process.env.VUE_APP_BASE_URL + 'api/image/channels',
            method: "POST",
            data: formData
          })
            .then(result => {
              this.item.img = result.data;
              this.img = result.data;
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          this.item.img = null;
          this.img = require(`@/assets/img/no-img.png`);
        }
      },
    }
}
</script>
