<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-autocomplete v-if="!dialog.item"
                                v-model="item.provider_id"
                                item-text="name"
                                item-value="id"
                                :items="providers.data"
                                label="Провайдер"
                                :error-messages="errors.provider_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                    v-model="item.fake_locality_id"
                    clearable
                    item-text="name"
                    item-value="id"
                    :items="localities.data"
                    label="Название города донора (если фейк)"
                    :error-messages="errors.locality_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.region.name"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-checkbox
                    label="Скрытый"
                    v-model="item.hidden"
                    :error-messages="errors.hidden"
                ></v-checkbox>
                <v-text-field
                    label="Приоритет"
                    type="text"
                    v-model="item.priority"
                    :error-messages="errors.priority"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
              :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              type="submit"
              :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
      disabled: false,
      loading: false,
      errors: false,
      search: {
        val: null,
        loading: false
      },
    }
  },
  async mounted () {
    this.disabledAndLoading();
    await this.getProviders();
    this.nonDisabledAndLoading();
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog/DIALOG',
      locality_providers: 'locality_providers/PROVIDERS',
      providers: 'providers/PROVIDERS',
      localities: 'localities/LOCALITIES',
    }),

    item: function () {
      var item = this.dialog.item;
      if (item) {
        return {
          id: item.id,
          provider_id: item.name ? item.name : null,
          fake_locality_id: item.fake_locality ? item.fake_locality.id : '',
          hidden: item.hidden ? item.hidden : null,
          priority: item.priority ? item.priority : null,
        }
      }
      else {
        return {
          provider_id: '',
          fake_locality_id: '',
          hidden: '',
          priority: ''
        }
      }
    }
  },
  methods: {
    ...mapActions({
      unsetDialog: 'dialog/SET_DIALOG_FALSE',
      disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
      nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      getLocalityProviders: 'locality_providers/GET_PROVIDERS',
      storeProvider: 'locality_providers/STORE_PROVIDER',
      updateProvider: 'locality_providers/UPDATE_PROVIDER',
      setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
      getProviders: 'providers/GET_ALL_PROVIDERS',
    }),

    closeDialog () {
      this.unsetDialog();
      this.errors = false;
    },

    clearItem () {
      this.item.provider_id = null;
      this.item.fake_locality_id = null;
      this.item.hidden = null;
      this.item.priority = null;
    },

    successActions (snackbar) {
      this.setSnackbar(snackbar);
      this.closeDialog();
      this.errors = false;
      this.disabledAndLoading();
      this.getLocalityProviders({ locality_id: this.$route.params.id, page: this.locality_providers.meta.current_page, per_page: this.locality_providers.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
    },

    submitForm () {
      this.disabled = this.loading = true;
      if (!this.item.fake_locality_id) this.item.fake_locality_id = null;
      if (!this.item.hidden) this.item.hidden = null;
      this.item["locality_id"] = this.$route.params.id;
      if (this.dialog.item) {
        this.item.provider_id  = this.item.id;
        this.updateProvider(this.item)
            .then(() => {
              this.successActions({message: "Провайдер изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
      }
      else {
        this.storeProvider(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Провайдер добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
      }
    }
  },
}
</script>