<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-textarea
                    label="Контент"
                    type="text"
                    autofocus
                    v-model="item.block"
                    :error-messages="errors.block"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        seo_service_blocks: 'seo_service_blocks/SEO_SERVICE_BLOCKS',
      }),

      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            block: item.block,
          }
        }
        else {
          return {
            block: '',
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getSeoServiceBlocks: 'seo_service_blocks/GET_SEO_SERVICE_BLOCKS',
        storeSeoServiceBlock: 'seo_service_blocks/STORE_SEO_SERVICE_BLOCK',
        updateSeoServiceBlock: 'seo_service_blocks/UPDATE_SEO_SERVICE_BLOCK',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.block = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getSeoServiceBlocks({ seo_service_id: this.$route.params.id, page: this.seo_service_blocks.meta.current_page, per_page: this.seo_service_blocks.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        this.item["seo_service_id"] = this.$route.params.id;
        if (this.dialog.item) {
          this.updateSeoServiceBlock(this.item)
            .then(() => {
                this.successActions({message: "Блок СЕО-услуги изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeSeoServiceBlock(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Блок СЕО-услуги добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      },
    }
}
</script>
