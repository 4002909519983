<template>
  <v-main>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
      <v-col
          cols="12"
          sm="10"
          md="8"
      >
        <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
          <v-card :loading="loading">
            <v-card-title>Копировать населенный пункт</v-card-title>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete
                  autofocus
                  v-model="item.locality_id"
                  item-text="name"
                  item-value="id"
                  :items="copin_localities.data"
                  :loading="search.loading"
                  label="Название копируемого нас. пункта"
                  :error-messages="errors.locality_id"
                  :search-input.sync="search.val"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.region.name"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-checkbox v-for="(provider ,i) in providers.data" :key="provider.id"
                  v-model="item.providers[i]"
                  :label= provider.name
                  :value= provider.id
                ></v-checkbox>
                <v-autocomplete
                    v-model="region_id"
                    item-text="name"
                    item-value="id"
                    :items="regions.data"
                    label="Регион"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="item.localities"
                  chips
                  deletable-chips
                  multiple
                  item-text="name"
                  item-value="id"
                  :items="copout_localities.data"
                  :loading="copout_localities.loading"
                  label="Нас. пункты"
                  :error-messages="errors.localities"
                  :disabled="!region_id || copout_localities.loading"
                >
                  <template v-slot:prepend-item v-if="checkCopoutLocalitiesEmpty">
                    <v-list-item
                        ripple
                        @click="toggleSelectAll">
                      <v-list-item-title>
                        Выбрать всё
                      </v-list-item-title>
                      <v-list-item-icon>
                        <v-icon v-text="selectAllIcon"></v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.region.name"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                type="submit"
                :disabled="disabled"
              >
                Копировать
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar.status" top :color="snackbar.color">
      {{snackbar.message}}
    </v-snackbar>
  </v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import throttle from "@/assets/js/throttle";

export default {
  data () {
    return {
      disabled: false,
      loading: false,
      errors: false,
      search: {
        val: null,
        loading: false
      },
      region_id: null,
      copin_localities: [],
      copout_localities: {
        data: [],
        loading: false
      },
      item: {
        locality_id: null,
        providers: [],
        localities: []
      }
    }
  },
  created() {
    this.clearLocalities();
    this.clearProviders();
    this.clearRegions();
  },
  mounted () {
    this.disabled = this.loading = true;
    this.getRegions();
    this.disabled = this.loading = false;
  },
  destroyed () {
    this.clearLocalities();
    this.clearRegions();
    this.clearProviders();
  },
  computed: {
    ...mapGetters({
      providers: 'locality_providers/PROVIDERS',
      regions: 'regions/REGIONS',
      snackbar: 'snackbar/SNACKBAR',
    }),
    check_loc () {
      return this.item.locality_id;
    },
    likesAllLocality () {
      if (this.item.localities && this.copout_localities.data)
        return this.item.localities.length === this.copout_localities.data.length;
      else
        return 0;
    },
    likesSomeLocality () {
      return this.item.localities.length > 0 && !this.likesAllLocality
    },
    selectAllIcon () {
      if (this.likesAllLocality) return 'mdi-checkbox-marked'
      if (this.likesSomeLocality) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    checkCopoutLocalitiesEmpty () {
      if (this.copout_localities.data && this.copout_localities.data.length > 1)
        return 1;
      else
        return 0;
    }
  },
  watch: {
    check_loc: function (val) {
      this.item.providers = [];
      this.getProviders({ locality_id: val });
    },
    "search.val" (val) {
      if(val != null && val.length > 1) {
         this.searchLocalities(val);
      }
    },
    region_id (val) {
      this.copout_localities.loading = true;
      this.item.localities = [];
      this.getLocalities({region_id: val, per_page: 1000})
      .then((response) => {
        this.copout_localities = response;
        this.copout_localities.loading = false;
      });
    }
  },
  methods: {
    ...mapActions({
      getLocalities: 'localities/GET_LOCALITIES',
      clearLocalities: 'localities/CLEAR_LOCALITIES',
      getProviders: 'locality_providers/GET_PROVIDERS_ON_LOCALITY',
      clearProviders: 'locality_providers/CLEAR_PROVIDERS',
      getRegions: 'regions/GET_ALL_REGIONS',
      clearRegions: 'regions/CLEAR_REGIONS',
      setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
    }),

    toggleSelectAll () {
      this.$nextTick(() => {
        if (this.likesAllLocality) {
          this.item.localities = [];
        } else {
          this.item.localities = this.copout_localities.data.map(item => item.id)
        }
      })
    },

    searchLocalities: throttle (function (val) {
      this.search.loading = true;
      this.getLocalities({
        searchFilter: {
          name: val
        },
        per_page: 100
      }).then((response) => {
        this.copin_localities = response;
        this.search.loading = false;
      });
    }, 700),

    successActions (snackbar) {
      this.setSnackbar(snackbar);
      this.errors = false;
    },

    submitForm () {
      this.disabled = this.loading = true;
      axios
        .post('/copy-locality', this.item)
        .then(() => { 
          this.successActions({message: "Населенный пункт скопирован"});
        })
        .catch(e => {
          if (e.response) {
            this.errors = e.response.data.errors;
            if (this.errors.providers) {
            this.setSnackbar({message: "Необходимо выбрать провайдер(ы)", color: "red"});
            }
          } else {
            this.setSnackbar({message: "Ошибка сервера", color: "red"});
          }
        })
        .finally(() => {
          this.disabled = this.loading = false;
        });
    }
  }
}
</script>
