// throttle.js
export default function throttle (callback, limit) {
    var timeoutID = null
    return function () {
        clearTimeout(timeoutID)
        var args = arguments
        var that = this
        timeoutID = setTimeout(function () {
            callback.apply(that, args)
        }, limit)
    }
}
