<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="channels.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.img`]="{ item }" disabled>
      <v-img max-height="25" contain
             max-width="100"
             :src="item.img"></v-img>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="channels.meta.from">
      <TablePagination :items="channels" action="channels_package_channels/GET_CHANNELS" :add_params="params" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Channels/ChannelsPackages/relations/Channels/components/Top'
  import Actions from '@/components/Channels/ChannelsPackages/relations/Channels/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Логотип', value: 'img', width: '135', sortable: false },
          { text: 'Название', value: 'name', sortable: false },
          { text: 'Жанр', value: 'genre.name', sortable: false },
          { text: 'Разрешение', value: 'resolution.name', sortable: false },
          { text: 'Действия', value: 'actions', align: 'end', sortable: false },
        ],
      }
    },
    mounted () {
      this.disabledAndLoading();
      this.getChannels({channels_package_id: this.channels_package_id})
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearChannels();
      this.clearFilterDialog();
    },
    computed: {
      ...mapGetters({
        channels: 'channels_package_channels/CHANNELS',
        table_loading: 'data_table/TABLE_LOADING',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
      }),
      channels_package_id: function () {
        return this.$route.params.id;
      },
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        params.channels_package_id = this.channels_package_id;
        return params;
      }
    },
    methods: {
      ...mapActions({
        getChannels: 'channels_package_channels/GET_CHANNELS',
        clearChannels: 'channels_package_channels/CLEAR_CHANNELS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        clearFilterDialog: 'filter_dialog/CLEAR_FILTER_DIALOG'
      })
    }
  }
</script>
