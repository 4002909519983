<template>
  <div>
    <v-icon small class="mr-2" @click="openRegions(item.id)" :disabled="disabled" title="Регионы">mdi-map</v-icon>
    <v-icon small class="mr-2" @click="openLocalities(item.id)" :disabled="disabled" title="Нас. пункты">mdi-city</v-icon>
    <v-icon small class="mr-2" @click="openProviders(item.id)" :disabled="disabled" title="Провайдеры">mdi-ip-network</v-icon>
    <v-icon small class="mr-2" @click="editItem(item)" :disabled="disabled" title="Редактировать">mdi-pencil</v-icon>
    <v-icon small class="mr-2" @click.stop="dialogdelete = true"  :disabled="disabled" title="Удалить">mdi-delete</v-icon>

    <v-dialog
        v-model="dialogdelete"
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Удаление</span>
        </v-card-title>
        <v-card-text>
          Вы уверены что хотите удалить {{item.url}}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogdelete = false">
            Отмена
          </v-btn>

          <v-btn color="blue darken-1" text @click="deleteItem(item)">
            Подтвердить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    data () {
      return {
        dialogdelete: false,
      }
    },
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        sites: 'sites/SITES',
      })
    },
    methods: {
      ...mapActions({
        deleteSite: 'sites/DELETE_SITE',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getSites: 'sites/GET_SITES',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      openRegions (id) {
        this.$router.push({
          name: 'site-regions', params: { id }
        })
      },

      openLocalities (id) {
        this.$router.push({
          name: 'site-localities', params: { id }
        })
      },

      openProviders (id) {
        this.$router.push({
          name: 'site-providers', params: { id }
        })
      },

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        var meta = this.sites.meta;
        this.deleteSite({ id: item.id })
          .then(() => {
            this.setSnackbar({message: "Сайт удален"});
            if(meta.from === meta.to) {
              this.getSites({ page: meta.current_page - 1, per_page: meta.per_page })
                .then(() => {
                  this.nonDisabledAndLoading();
                });
            } else {
              this.getSites({ page: meta.current_page, per_page: meta.per_page })
                .then(() => {
                  this.nonDisabledAndLoading();
                });
            }
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
      },
    }
  }
</script>
