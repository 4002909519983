import axios from 'axios'

export default({
  namespaced: true,

  state: {
    tarifs: {
      data: [],
      meta: {
        current_page: null,
        from: null,
        last_page: null,
        path: null,
        per_page: null,
        to: null,
        total: null
      }
    }
  },

  getters: {
    TARIFS (state){
      return state.tarifs;
    },
  },

  mutations: {
    SET_TARIFS (state, data) {
      state.tarifs = data;
    },

    CLEAR_TARIFS (state) {
      state.tarifs = {
        data: [],
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          path: null,
          per_page: null,
          to: null,
          total: null
        }
      }
    }
  },

  actions: {
    GET_TARIFS: async (context, params) => {
      var tv_box_id = params.tv_box_id;
      delete params.tv_box_id;
      var params_str;
      params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      let {data} = await axios.get('/tv-boxes/' + tv_box_id + '/tarifs?' + params_str);
      context.commit('SET_TARIFS', data);
    },

    CLEAR_TARIFS (context) {
      context.commit('CLEAR_TARIFS');
    },

    STORE_TARIF: async (context, params) => {
      var tv_box_id = params.tv_box_id;
      delete params.tv_box_id;
      await axios.post('/tv-boxes/' + tv_box_id + '/tarifs', params);
    },

    UPDATE_TARIF: async (context, params) => {
      var id = params.id;
      var tv_box_id = params.tv_box_id;
      delete params.tv_box_id;
      delete params.id;
      await axios.put('/tv-boxes/' + tv_box_id + '/tarifs/' + id, params);
    },

    DELETE_TARIF: async (context, params) => {
      await axios.delete('/tv-boxes/' + params.tv_box_id + '/tarifs/' + params.id);
    }
  }
});
