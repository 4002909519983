<template>
  <v-data-iterator
      :loading="table_loading"
      loading-text="Загрузка данных..."
      item-key="name"
      :items.sync="banners_hidpi.data"
      disable-pagination
      disable-items-per-page
      hide-default-footer
      class="elevation-1"
      no-data-text="Нет данных"
  >
    <template v-slot:header>
        <Top />
    </template>

    <template v-slot:default="{ items }">
      <v-row>
        <v-col
            v-for="item in items"
            :key="item.name"
            cols="12"
            sm="4"
            md="3"
            lg="2"
        >
          <v-card>
            <v-card-actions>
              <v-card-title class="pa-0">
                <h5>{{ item.name }}</h5>
              </v-card-title>
              <v-spacer></v-spacer>
              <Actions :item="item" />
            </v-card-actions>
            <v-img
                height="200"
                contain
                :src="banner_img(item.url)"
            ></v-img>
            <v-spacer></v-spacer>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <template v-slot:footer v-if="banners_hidpi.meta.from">
      <TablePagination :items="banners_hidpi" action="banners_hidpi/GET_BANNERS" />
    </template>
  </v-data-iterator>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TablePagination from '@/components/Common/TablePagination'
import Top from '@/components/Providers/Images/BannersHidpi/components/Top'
import Actions from '@/components/Providers/Images/BannersHidpi/components/Actions'

export default {
  components: {
    TablePagination,
    Top,
    Actions
  },
  mounted () {
    this.disabledAndLoading();
    this.getBanners()
        .then(() => {
          this.nonDisabledAndLoading();
        });
  },
  destroyed () {
    this.clearBanners();
  },
  computed: {
    ...mapGetters({
      banners_hidpi: 'banners_hidpi/BANNERS',
      table_loading: 'data_table/TABLE_LOADING',
    }),
    sites_api: function () {
      return process.env.VUE_APP_SITES_API_URL;
    },
  },
  methods: {
    ...mapActions({
      getBanners: 'banners_hidpi/GET_BANNERS',
      clearBanners: 'banners_hidpi/CLEAR_BANNERS',
      disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
      nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
    }),
    banner_img(img)  {
      return this.sites_api+img;
    }
  }
}
</script>