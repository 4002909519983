<template>
  <div>
    <v-icon small class="mr-2" @click="editItem(item)" :disabled="disabled" title="Редактировать">mdi-pencil</v-icon>
    <v-icon small class="mr-2" @click="deleteItem(item)" :disabled="disabled" title="Удалить">mdi-delete</v-icon>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        questions_answers: 'questions_answers/QUESTIONS_ANSWERS',
      })
    },
    methods: {
      ...mapActions({
        deleteQuestionAnswer: 'questions_answers/DELETE_QUESTION_ANSWER',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getQuestionsAnswers: 'questions_answers/GET_QUESTIONS_ANSWERS',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        var meta = this.questions_answers.meta;
        this.deleteQuestionAnswer({ id: item.id, site_id: this.$route.params.site_id, locality_id: this.$route.params.id })
          .then(() => {
            this.setSnackbar({message: "Вопрос-ответ удален"});
            if(meta.from === meta.to) {
              this.getQuestionsAnswers({ site_id: this.$route.params.site_id, locality_id: this.$route.params.id, page: meta.current_page - 1, per_page: meta.per_page })
                .then(() => {
                  this.nonDisabledAndLoading();
                });
            } else {
              this.getQuestionsAnswers({ site_id: this.$route.params.site_id, locality_id: this.$route.params.id, page: meta.current_page, per_page: meta.per_page })
                .then(() => {
                  this.nonDisabledAndLoading();
                });
            }
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
      },
    }
  }
</script>
