<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                    label="Текстовая информация об изображении"
                    type="text"
                    v-model="item.alt"
                ></v-text-field>
                <v-text-field
                    label="Всплывающая подсказка"
                    type="text"
                    v-model="item.title"
                ></v-text-field>
                <v-autocomplete
                    v-model="item.img"
                    :items="banners.data"
                    small-chips
                    item-text="name"
                    item-value="url"
                    label="Изображение"
                    clearable
                    :error-messages="errors.img"
                >
                  <template v-slot:selection="data">
                    <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                    >
                      <v-avatar left>
                        <v-img contain :src="img(data.item.url)"></v-img>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-img contain :src="img(data.item.url)"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                    v-model="item.img_mobile"
                    :items="banners.data"
                    small-chips
                    item-text="name"
                    item-value="url"
                    label="Мобильное изображение"
                    clearable
                    :error-messages="errors.img_mobile"
                >
                  <template v-slot:selection="data">
                    <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                    >
                      <v-avatar left>
                        <v-img contain :src="img(data.item.url)"></v-img>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-img contain :src="img(data.item.url)"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-text-field
                    label="Внешняя ссылка"
                    type="text"
                    v-model="item.url"
                ></v-text-field>
                <v-text-field
                    label="Приоритет"
                    type="text"
                    v-model="item.priority"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getBanners();
      await this.getProviderBanners();
      this.nonDisabledAndLoading();
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        provider_banners: 'provider_banners/BANNERS',
        banners: 'banners/BANNERS'
      }),

      sites_api: function () {
        return process.env.VUE_APP_SITES_API_URL;
      },
      
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            alt: item.alt ? item.alt : null,
            img: item.img ? item.img : null,
            img_mobile: item.img_mobile ? item.img_mobile : null,
            title: item.title ? item.title : null,
            url: item.url ? item.url : null,
            priority: item.priority ? item.priority : null,
          }
        }
        else {
          return {
            alt: '',
            img: '',
            img_mobile: '',
            title: '',
            url: '',
            priority: ''
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getProviderBanners: 'provider_banners/GET_BANNERS',
        storeProviderBanner: 'provider_banners/STORE_BANNER',
        updateProviderBanner: 'provider_banners/UPDATE_BANNER',
        getBanners: 'banners/GET_ALL_BANNERS',
        clearBanners: 'banners/CLEAR_BANNERS',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.alt = null;
        this.item.img = null;
        this.item.img_mobile = null;
        this.item.title = null;
        this.item.url = null;
        this.item.priority = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getProviderBanners({ provider_id: this.$route.params.id, page: this.provider_banners.meta.current_page, per_page: this.provider_banners.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      img(img)  {
        return this.sites_api+img;
      },

      submitForm () {
        this.disabled = this.loading = true;
        this.item["provider_id"] = this.$route.params.id;
        if (this.dialog.item) {
          this.updateProviderBanner(this.item)
            .then(() => {
                this.successActions({message: "Баннер изменен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeProviderBanner(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Баннер добавлен"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>