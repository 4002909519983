<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="channels_packages.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.is_extension`]="{ item }" disabled>
        <div v-if="item.is_extension"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="channels_packages.meta.from">
      <TablePagination :items="channels_packages" action="channels_packages/GET_CHANNELS_PACKAGES" :add_params="params" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Channels/ChannelsPackages/components/Top'
  import Actions from '@/components/Channels/ChannelsPackages/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Провайдер', value: 'provider.name', sortable: false },
          { text: 'Название', value: 'name', sortable: false },
          { text: 'Дополнительный', value: 'is_extension', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
        check: 'mdi-check',
      }
    },

    created () {
      this.clearChannelsPackages();
      this.clearProviders();
      this.clearFilterDialog();
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getProviders();
      await this.getChannelsPackages();
      this.nonDisabledAndLoading();
    },
    destroyed () {
      this.clearChannelsPackages();
      this.clearProviders();
      this.clearFilterDialog();
    },
    computed: {
      ...mapGetters({
        channels_packages: 'channels_packages/CHANNELS_PACKAGES',
        providers: 'providers/PROVIDERS',
        table_loading: 'data_table/TABLE_LOADING',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
      }),
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        return params;
      }
    },
    methods: {
      ...mapActions({
        getChannelsPackages: 'channels_packages/GET_CHANNELS_PACKAGES',
        getProviders: 'providers/GET_ALL_PROVIDERS',
        clearChannelsPackages: 'channels_packages/CLEAR_CHANNELS_PACKAGES',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        clearProviders: 'providers/CLEAR_PROVIDERS',
        clearFilterDialog: 'filter_dialog/CLEAR_FILTER_DIALOG'
      })
    }
  }
</script>
