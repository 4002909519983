<template>
  <v-app>
    <navigation-drawer v-if="authenticated" ref="drawer"/>
    <header-bar v-if="authenticated" @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer" />
    <router-view />
  </v-app>
</template>

<script>
  import HeaderBar from '@/components/Common/HeaderBar'
  import NavigationDrawer from '@/components/Common/NavigationDrawer'
  
  import { mapGetters } from 'vuex'

  export default {
    components:{
      'header-bar': HeaderBar,
      'navigation-drawer': NavigationDrawer
    },

    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated'
      })
    }
  }
</script>