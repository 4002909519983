<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="questions_answers.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="questions_answers.meta.from">
      <TablePagination :items="questions_answers" action="questions_answers/GET_QUESTIONS_ANSWERS"/>
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination.vue'
  import Top from '@/components/Sites/relations/Localities/relations/QuestionAnswer/components/Top.vue'
  import Actions from '@/components/Sites/relations/Localities/relations/QuestionAnswer/components/Actions.vue'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Вопрос', value: 'question', sortable: true },
          { text: 'Ответ', value: 'answer', sortable: true },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
        check: 'mdi-check'
      }
    },
    created() {
      this.clearQuestionsAnswers();
    },
    mounted () {
      this.disabledAndLoading();
      this.getQuestionsAnswers({site_id: this.site_id, locality_id: this.locality_id})
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearQuestionsAnswers();
    },
    computed: {
      ...mapGetters({
        questions_answers: 'questions_answers/QUESTIONS_ANSWERS',
        table_loading: 'data_table/TABLE_LOADING',
      }),
        site_id: function () {
            return this.$route.params.site_id;
        },
        locality_id: function () {
            return this.$route.params.id;
        },
    },
    methods: {
      ...mapActions({
        getQuestionsAnswers: 'questions_answers/GET_QUESTIONS_ANSWERS',
        clearQuestionsAnswers: 'questions_answers/CLEAR_QUESTIONS_ANSWERS',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>
