<template>
  <div>
    <v-icon small class="mr-2" @click="editItem(item)" :disabled="disabled" title="Редактировать">mdi-pencil</v-icon>
    <v-icon small class="ml-2" @click="deleteItem(item)" :disabled="disabled" title="Удалить">mdi-delete</v-icon>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ["item"],
    computed: {
      ...mapGetters({
        disabled: 'data_table/DISABLED',
        options: 'locality_tarif_options/OPTIONS',
      })
    },
    methods: {
      ...mapActions({
        deleteOption: 'locality_tarif_options/DELETE_OPTION',
        setDialog: 'dialog/SET_DIALOG_TRUE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getOptions: 'locality_tarif_options/GET_OPTIONS',
        showErrorConflict: 'errors/SHOW_ERROR_CONFLICT'
      }),

      editItem (item) {
        this.setDialog(item);
      },

      deleteItem (item) {
        this.disabledAndLoading();
        var meta = this.options.meta;
        this.deleteOption({
            id: item.id,
            locality_id: this.$route.params.locality_id,
            provider_id: this.$route.params.provider_id,
            tarif_id: this.$route.params.id
          })
          .then(() => {
            this.setSnackbar({message: "Опция удалена"});
            if(meta.from === meta.to) {
              this.getOptions({
                    locality_id: this.$route.params.locality_id,
                    provider_id: this.$route.params.provider_id,
                    tarif_id: this.$route.params.id,
                    page: meta.current_page - 1,
                    per_page: meta.per_page
                  })
                .then(() => {
                  this.nonDisabledAndLoading();
                });
            } else {
              this.getOptions({
                    locality_id: this.$route.params.locality_id,
                    provider_id: this.$route.params.provider_id,
                    tarif_id: this.$route.params.id,
                    page: meta.current_page,
                    per_page: meta.per_page
                  })
                .then(() => {
                  this.nonDisabledAndLoading();
                });
            }
          })
          .catch( e => {
            this.showErrorConflict({response: e.response, obj: this});
          });
      },
    }
  }
</script>
