<template>
  <span v-if="value">
    <slot>
      {{ value }}
    </slot>
  </span>
  <span v-else class="text-gray-400">Не задано</span>
</template>

<script>
export default {
  name: "Nullable",
  props: {
    value: {
      required: true
    }
  }
}
</script>

<style scoped>
  .text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
</style>
