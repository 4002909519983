<template>
  <v-main>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
      <div>
        <span class="indigo--text text-h5">Страница не найдена</span>
      </div>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  export default {
    name: 'page-not-found',
    data() {
      return {
      };
    }
  }
</script>
