<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="localities.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.hidden`]="{ item }" disabled>
      <div v-if="item.hidden"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="localities.meta.from">
      <TablePagination :items="localities" action="sites_localities/GET_LOCALITIES" :add_params="params" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/Sites/relations/Localities/components/Top'
  import Actions from '@/components/Sites/relations/Localities/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
          { text: 'Нас. пункт', value: 'name', sortable: false },
          { text: 'Номер телефона', value: 'phone.phone', sortable: false },
          { text: 'Главный заголовок', value: 'main_head', sortable: false },
          { text: 'Дополнительный заголовок', value: 'sub_head', sortable: false },
          { text: 'Скрытый', value: 'hidden', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false },
        ],
        check: 'mdi-check'
      }
    },
    mounted () {
      this.disabledAndLoading();
      this.getLocalities({site_id: this.site_id})
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearLocalities();
    },
    computed: {
      ...mapGetters({
        localities: 'sites_localities/LOCALITIES',
        table_loading: 'data_table/TABLE_LOADING',
        region_id: 'region_id/REGION_ID',
      }),
      site_id: function () {
        return this.$route.params.id;
      },
      params: function () {
        let params = {site_id: this.site_id};
        if (this.region_id) {
          params.region_id = this.region_id;
        }
        return params;
      }
    },
    methods: {
      ...mapActions({
        getLocalities: 'sites_localities/GET_LOCALITIES',
        clearLocalities: 'sites_localities/CLEAR_LOCALITIES',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
      })
    }
  }
</script>
