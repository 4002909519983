export default({
  namespaced: true,

  state: {
    search: null
  },

  getters: {
    SEARCH (state){
      return state.search;
    }
  },

  mutations: {
    SET_SEARCH (state, data) {
      state.search = data;
    },

    CLEAR_SEARCH (state) {
      state.search = null;
    }
  },

  actions: {
    SET_SEARCH: async (context, params) => {
      context.commit('SET_SEARCH', params);
    },

    CLEAR_SEARCH (context) {
      context.commit('CLEAR_SEARCH');
    },
  }
});
