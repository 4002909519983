<template>
  <v-data-table
    dense
    :loading="table_loading"
    loading-text="Загрузка данных..."
    item-key="id"
    :headers="headers"
    :items.sync="seo_services.data"
    disable-pagination
    disable-items-per-page
    hide-default-footer
    class="elevation-1"
    no-data-text="Нет данных"
  >
    <template v-slot:top>
      <Top />
    </template>

    <template v-slot:[`item.category`]="{ item }" disabled>
      <div v-if="item.category">{{ item.category.name }}</div>
      <div v-else><v-icon>{{ minus }}</v-icon></div>
    </template>

    <template v-slot:[`item.service`]="{ item }" disabled>
      <div v-if="item.service">{{ item.service.name }}</div>
      <div v-else><v-icon>{{ minus }}</v-icon></div>
    </template>

    <template v-slot:[`item.tariff_category`]="{ item }" disabled>
      <div v-if="item.tariff_category">{{ item.tariff_category.name }}</div>
      <div v-else><v-icon>{{ minus }}</v-icon></div>
    </template>

    <template v-slot:[`item.is_business`]="{ item }" disabled>
      <div v-if="item.is_business"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.is_hidden`]="{ item }" disabled>
      <div v-if="item.is_hidden"><v-icon>{{ check }}</v-icon></div>
    </template>

    <template v-slot:[`item.actions`]="{ item }" disabled>
      <Actions :item="item" />
    </template>

    <template v-slot:footer v-if="seo_services.meta.from">
      <TablePagination :items="seo_services" action="seo_services/GET_SEO_SERVICES" :add_params="params" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import TablePagination from '@/components/Common/TablePagination'
  import Top from '@/components/SeoServices/SeoServices/components/Top'
  import Actions from '@/components/SeoServices/SeoServices/components/Actions'

  export default {
    components: {
      TablePagination,
      Top,
      Actions
    },
    data () {
      return {
        headers: [
            { text: 'Сайт', value: 'site.url', sortable: false },
            { text: 'Провайдер', value: 'provider.name', sortable: false },
            { text: 'Категория', value: 'category', sortable: false },
            { text: 'Категория тарифов', value: 'tariff_category', sortable: false },
            { text: 'Оригинальная услуга', value: 'service', sortable: false },
            { text: 'Название', value: 'name', sortable: false },
            { text: 'URL', value: 'url', sortable: false },
            { text: 'Для юр. лиц', value: 'is_business', sortable: false },
            { text: 'Скрытая', value: 'is_hidden', sortable: false },
            { text: 'Действия', value: 'actions', sortable: false },
        ],
        check: 'mdi-check',
        minus: 'mdi-minus',
      }
    },
    mounted () {
      this.disabledAndLoading();
      this.clearFilterDialog();
      this.getSeoServices()
        .then(() => {
          this.nonDisabledAndLoading();
        });
    },
    destroyed () {
      this.clearSeoServices();
      this.clearFilterDialog();
    },
    computed: {
      ...mapGetters({
        seo_services: 'seo_services/SEO_SERVICES',
        table_loading: 'data_table/TABLE_LOADING',
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
      }),
      params: function () {
          let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
          return params;
      }
    },
    methods: {
      ...mapActions({
        getSeoServices: 'seo_services/GET_SEO_SERVICES',
        clearSeoServices: 'seo_services/CLEAR_SEO_SERVICES',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        clearFilterDialog: 'filter_dialog/CLEAR_FILTER_DIALOG'
      })
    }
  }
</script>
