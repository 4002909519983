import axios from 'axios'

export default({
    namespaced: true,

    state: {
        seo_services_categories: {
            data: [],
            meta: {
                current_page: null,
                from: null,
                last_page: null,
                path: null,
                per_page: null,
                to: null,
                total: null
            }
        },
        seo_services_category: null
    },

    getters: {
        SEO_SERVICES_CATEGORIES (state) {
            return state.seo_services_categories;
        },
        SEO_SERVICES_CATEGORY (state) {
            return state.seo_services_category;
        },
    },

    mutations: {
        SET_SEO_SERVICES_CATEGORIES (state, data) {
            state.seo_services_categories = data;
        },

        SET_SEO_SERVICES_CATEGORY (state, data) {
            state.seo_services_category = data;
        },

        CLEAR_SEO_SERVICES_CATEGORIES (state) {
            state.seo_services_categories = {
                data: [],
                meta: {
                    current_page: null,
                    from: null,
                    last_page: null,
                    path: null,
                    per_page: null,
                    to: null,
                    total: null
                }
            }
        },

        CLEAR_SEO_SERVICES_CATEGORY (state) {
            state.seo_services_category = null;
        }
    },

    actions: {
        GET_SEO_SERVICES_CATEGORIES: async (context, params) => {
            var params_str;
            if (params) {
                params_str = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                let {data} = await axios.get('/seo-services-categories?' + params_str);
                context.commit('SET_SEO_SERVICES_CATEGORIES', data);
            } else {
                let {data} = await axios.get('/seo-services-categories');
                context.commit('SET_SEO_SERVICES_CATEGORIES', data);
            }
        },

        GET_ALL_SEO_SERVICES_CATEGORIES: async (context) => {
            let {data} = await axios.get('/seo-services-categories?per_page=1000&sort_by=name');
            context.commit('SET_SEO_SERVICES_CATEGORIES', data);
        },

        GET_SEO_SERVICES_CATEGORY: async (context, params) => {
            let {data} = await axios.get('/seo-services-categories/' + params);
            context.commit('SET_SEO_SERVICES_CATEGORY', data);
        },

        CLEAR_SEO_SERVICES_CATEGORIES (context) {
            context.commit('CLEAR_SEO_SERVICES_CATEGORIES');
        },

        CLEAR_SEO_SERVICES_CATEGORY (context) {
            context.commit('CLEAR_SEO_SERVICES_CATEGORY');
        },

        STORE_SEO_SERVICES_CATEGORY: async (context, params) => {
            await axios.post('/seo-services-categories', params);
        },

        UPDATE_SEO_SERVICES_CATEGORY: async (context, params) => {
            await axios.put('/seo-services-categories/' + params.id, params);
        },

        DELETE_SEO_SERVICES_CATEGORY: async (context, params) => {
            await axios.delete('/seo-services-categories/' + params.id);
        }
    }
});
