<template>
  <v-dialog
    v-model="dialog.status"
    max-width="800px"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-form
      ref="form"
      :disabled="disabled"
      @submit.prevent="submitForm"
    >
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>

          <template v-if="!dialog.item">
            <v-spacer></v-spacer>
            <span style="margin-right: 5px; font-weight: 400;">{{mode ? 'Изменение' : 'Создание'}} </span>
            <v-switch
                v-model="mode"
            ></v-switch>
          </template>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <!-- 1ый столбец -->
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <!-- Регион -->
                <v-autocomplete
                  v-if="!dialog.item"
                  v-model="region_ids"
                  item-text="name"
                  item-value="id"
                  :items="regions.data"
                  label="Регион"
                  clearable
                  multiple
                  chips
                  :error-messages="errors.region_ids"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"/>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

                <!-- Нас. пункт -->
                <v-autocomplete
                  v-if="!dialog.item"
                  v-model="item.locality_ids"
                  item-value="id"
                  :items="localities.data"
                  :item-text="item => {if (item && item.locality_type) return `${item.locality_type.name} ${item.name}`}"
                  label="Нас. пункт"
                  :error-messages="errors.locality_ids"
                  :loading="input_loading || locality_loading"
                  :disabled="!region_ids.length || input_loading || locality_loading"
                  ref="locality_autocomplete"
                  multiple
                  clearable
                  chips
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="data">
                    <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="[data.item.locality_type.name, data.item.name].join(' ')"></v-list-item-title>
                        </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

                <!-- Скорость -->
                <v-autocomplete
                  v-model="item.speed_id"
                  item-text="speed"
                  item-value="id"
                  :items="speeds.data"
                  label="Скорость"
                  clearable
                  :error-messages="errors.speed_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>

                <!-- Цена -->
                <v-text-field
                  label="Цена"
                  type="text"
                  v-model="item.price"
                  :error-messages="errors.price"
                />

                <!-- Старая цена -->
                <v-text-field
                    label="Старая цена"
                    clearable
                    type="text"
                    v-model="item.old_price"
                    :error-messages="errors.old_price"
                />

                <!-- Стоимость подключения -->
                <v-text-field
                  label="Стоимость подключения"
                  type="text"
                  v-model="item.podkl_price"
                  :error-messages="errors.podkl_price"
                />

                <!-- Пакет каналов -->
                <v-autocomplete
                  v-model="item.channels_package_id"
                  item-text="name"
                  item-value="id"
                  :items="channels_packages.data"
                  label="Пакет каналов"
                  clearable
                  :error-messages="errors.channels_package_id"
                  :loading="input_loading"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"/>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>

                <!-- Кол-во каналов(устарело) -->
                <v-text-field
                  type="text"
                  v-model="item.sum_channels"
                  :error-messages="errors.sum_channels"
                >
                  <template v-slot:label>
                    <span>Кол-во каналов <small>(устарело)</small></span>
                  </template>
                </v-text-field>
              </v-col>

              <!-- 2ой столбец -->
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <!-- Моб. интернет -->
                <v-text-field
                  label="Моб. интернет"
                  type="text"
                  v-model="item.mobile"
                  :error-messages="errors.mobile"
                />

                <!-- Акция -->
                <v-autocomplete
                  v-model="item.action_id"
                  item-text="name"
                  item-value="id"
                  :items="actions.data"
                  label="Акция"
                  clearable
                  :error-messages="errors.action_id"
                  :loading="input_loading"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>

                <!-- Опция -->
                <v-autocomplete
                  v-model="item.option_id"
                  item-text="name"
                  item-value="id"
                  :items="options.data"
                  label="Опция"
                  clearable
                  :error-messages="errors.option_id"
                  :loading="input_loading"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>

                <!-- Опции -->
                <v-autocomplete
                  v-model="item.options_ids"
                  item-text="name"
                  item-value="id"
                  :items="options.data"
                  label="Опции"
                  clearable
                  multiple
                  chips
                  :error-messages="errors.options_ids"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"/>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

                <!-- Технология -->
                <v-autocomplete
                  v-model="item.tech_id"
                  item-text="name"
                  item-value="id"
                  :items="techs.data"
                  label="Технология"
                  clearable
                  :error-messages="errors.tech_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>

                <!-- Скрытый -->
                <v-checkbox
                  label="Скрытый"
                  v-model="item.hidden"
                  :error-messages="errors.hidden"
                />

                <!-- Приоритет -->
                <v-text-field
                  label="Приоритет"
                  type="text"
                  v-model="item.priority"
                  :error-messages="errors.priority"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data () {
        return {
            disabled: false,
            loading: false,
            errors: false,
            region_ids: [],
            input_loading: false,
            locality_loading: false,
            mode: true
        }
    },

    async mounted () {
        this.disabledAndLoading();

        await this.getTarif(this.$route.params.id);
        await this.getRegions();
        await this.getChannelsPackages({ provider_id: this.tarif.data.provider.id, per_page: 1000 });
        await this.getActions({ provider_id: this.tarif.data.provider.id });
        await this.getOptions({ provider_id: this.tarif.data.provider.id });
        await this.getSpeeds();
        await this.getTechs();

        this.nonDisabledAndLoading();
    },

    computed: {
        ...mapGetters({
            dialog: 'dialog/DIALOG',
            tarif_localities: 'tarif_localities/LOCALITIES',
            channels_packages: 'channels_packages/CHANNELS_PACKAGES',
            speeds: 'speeds/SPEEDS',
            actions: 'actions/ACTIONS',
            options: 'options/OPTIONS',
            techs: 'techs/TECHS',
            regions: 'regions/REGIONS',
            localities: 'localities/LOCALITIES',
            tarif: 'tarifs/TARIF'
        }),

        item: function () {
            var item = this.dialog.item;

            if (item) {
                return {
                    id: item.id,
                    speed_id: item.speed ? item.speed.id : null,
                    price: item.price,
                    old_price: item.old_price,
                    podkl_price: item.podkl_price,
                    sum_channels: item.sum_channels,
                    mobile: item.mobile,
                    channels_package_id: item.channels_package ? item.channels_package.id : null,
                    action_id: item.action ? item.action.id : null,
                    option_id: item.option ? item.option.id : null,
                    options_ids: item.options ? item.options.map(a => a.option_id) : null,
                    tech_id: item.tech ? item.tech.id : null,
                    hidden: item.hidden ? item.hidden : null,
                    priority: item.priority ? item.priority : null
                }
            } else {
                return {
                    locality_ids: null,
                    speed_id: '',
                    price: '',
                    old_price: '',
                    podkl_price: '',
                    sum_channels: '',
                    mobile: '',
                    channels_package_id: '',
                    action_id: '',
                    option_id: '',
                    options_ids: [],
                    tech_id: '',
                    hidden: '',
                    priority: ''
                }
            }
        },
    },

    watch: {
        region_ids(val) {
            this.clearLocalities();
            this.item.locality_ids = null;
            this.$refs.locality_autocomplete.internalValue = null;

            if (val.length) {
                this.locality_loading = true;
                this.getLocalities({region_ids: val})
                    .finally(() => this.locality_loading = false);
            }
        }
    },

    methods: {
        ...mapActions({
            unsetDialog: 'dialog/SET_DIALOG_FALSE',
            disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
            nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
            getTarif: 'tarifs/GET_TARIF',
            clearTarif: 'tarifs/CLEAR_TARIF',
            getTarifLocalities: 'tarif_localities/GET_LOCALITIES',
            storeTarifLocality: 'tarif_localities/STORE_LOCALITY',
            updateTarifLocality: 'tarif_localities/UPDATE_LOCALITY',
            setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
            getSpeeds: 'speeds/GET_ALL_SPEEDS',
            getActions: 'actions/GET_ACTIONS_ON_PROVIDER',
            clearActions: 'actions/CLEAR_ACTIONS',
            getOptions: 'options/GET_OPTIONS_ON_PROVIDER',
            clearOptions: 'options/CLEAR_OPTIONS',
            getTechs: 'techs/GET_ALL_TECHS',
            getChannelsPackages: 'channels_packages/GET_CHANNELS_PACKAGES',
            clearChannelsPackages: 'channels_packages/CLEAR_CHANNELS_PACKAGES',
            getRegions: 'regions/GET_ALL_REGIONS',
            clearRegions: 'regions/CLEAR_REGIONS',
            getLocalities: 'localities/GET_ALL_LOCALITIES',
            clearLocalities: 'localities/CLEAR_LOCALITIES'
        }),

        closeDialog () {
            this.unsetDialog();
            this.errors = false;
        },

        clearItem () {
            this.item.region_ids = null;
            this.item.locality_ids = null;
            this.item.speed_id = null;
            this.item.price = null;
            this.item.old_price = null;
            this.item.podkl_price = null;
            this.item.sum_channels = null;
            this.item.mobile = null;
            this.item.channels_package_id = null;
            this.item.action_id = null;
            this.item.option_id = null;
            this.item.tech_id = null;
            this.item.hidden = null;
            this.item.priority = null;
            this.region_ids = [];
            this.item.options_ids = [];
        },

        successActions (snackbar) {
            this.setSnackbar(snackbar);
            this.closeDialog();

            this.errors = false;

            this.disabledAndLoading();

            let params = {
                    tarif_id: this.$route.params.id,
                    page: this.tarif_localities.meta.current_page,
                    per_page: this.tarif_localities.meta.per_page,
                };

            this.getTarifLocalities(params)
                .then(() => {
                    this.nonDisabledAndLoading();
                });
        },

        submitForm () {
            this.disabled = this.loading = true;

            if (this.region_ids.length) this.item.region_ids = this.region_ids;
            if (this.item.channels_package_id === undefined) this.item.channels_package_id = null;
            if (this.item.speed_id === undefined) this.item.speed_id = null;
            if (this.item.action_id === undefined) this.item.action_id = null;
            if (this.item.option_id === undefined) this.item.option_id = null;
            if (this.item.tech_id === undefined) this.item.tech_id = null;
            if (!this.item.hidden) this.item.hidden = null;
            if (!this.item.old_price) this.item.old_price = null;
            if (!this.dialog.item) this.item.mode = this.mode;

            this.item["tarif_id"] = this.$route.params.id;

            if (this.dialog.item) {
                this.updateTarifLocality(this.item)
                    .then(() => {
                        this.successActions({message: "Населённый пункт изменен"});
                    })
                    .catch(e => {
                        if (e.response) {
                            this.errors = e.response.data.errors;
                        } else {
                            this.setSnackbar({message: "Ошибка сервера", color: "red"});
                        }
                    })
                    .finally(() => {
                        this.disabled = this.loading = false;
                    });

                this.disabled = this.loading = false;
            } else {
                this.storeTarifLocality(this.item)
                    .then(() => {
                        this.clearItem();
                        this.successActions({message: "Населённый пункт добавлен"});
                    })
                    .catch(e => {
                        if (e.response) {
                            this.errors = e.response.data.errors;
                        } else {
                            this.setSnackbar({message: "Ошибка сервера", color: "red"});
                        }
                    })
                    .finally(() => {
                        this.disabled = this.loading = false;
                    });
            }
        }
    }
}
</script>
